import React, {useEffect, useState} from "react";
import {IOrder, IOrderInfo} from "../../../interfaces/IOrderInfo";
import {TripService} from "../../../services/TripService";
import {NewTripComponent} from "./newTripComponent";
import {OrdersTableComponent} from "./ordersTableComponent";
import {useAppSelector} from "../../../redux/hooks";
import "./style.scss";


export function TripPage() {

    const authState = useAppSelector((state) => state.auth);

    const tripService = TripService();

    const [tripBuilderModalOpened, setTripBuilderModalOpened] = useState<boolean>(false);

    const [orders, setOrders] = useState<IOrder[]>([]);
    const [ordersPending, setOrdersPending] = useState(false);
    const [isOrdersNotFound, setIsOrdersNotFound] = useState(false);

    const onCreateOrderHandler = () => {
        setTripBuilderModalOpened(true);
    }

    const loadOrders = async () => {
        setOrdersPending(true);
        setOrders([]);
        const response = await tripService.getOrderList(authState.currentClientCode);
        if (response.length > 0) {
            setOrders(response);
            setIsOrdersNotFound(false);
        } else {
            setIsOrdersNotFound(true);
        }
        setOrdersPending(false);
    }

    useEffect(() => {
        loadOrders();
    }, [authState.currentClientCode]);


    return (
        <>

            <OrdersTableComponent
                orders={orders}
                isLoading={ordersPending}
                isSearchResultNotFound={isOrdersNotFound}
                createOrderHandler={onCreateOrderHandler}
                searchOrderHandler={loadOrders}
            />

            {
                tripBuilderModalOpened && (
                    <NewTripComponent
                        tripBuilderModalOpened={tripBuilderModalOpened}
                        setTripBuilderModalOpened={setTripBuilderModalOpened}
                        refreshOrderListHandler={loadOrders}
                    />
                )
            }

        </>
    );
}
