import { useCookies } from "react-cookie";
import { useAppSelector } from "../redux/hooks";
import axios, {CancelToken} from "axios";
import { ACCESS_TOKEN } from "../constants/FieldsConstants";
import {TripApi} from "../api/TripApi";
import {IBookFlightRequestNew, IBookTrainRequestNew} from "../Requests/IBookFlightRequest";
import {IFlightRoute} from "../pages/cabinetPages/tripPage/multiSearchTicketsModal/multiSearchFlightsComponent";


export function TripService() {

  const authState = useAppSelector((state) => state.auth);
  const [cookies] = useCookies([ACCESS_TOKEN]);

  const tripApi = TripApi();


  const getProviders = async (clientCore: string) => {
    try {
      const response = await tripApi.getProviders(clientCore);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  const getFlightList = async (origin: string, direction: string, date: string, clientCore: string | null, connectionId: string | null) => {
    try {
      const response = await tripApi.getFlights(origin, direction, date, clientCore, connectionId);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  const getFlightList2 = async (routes: IFlightRoute[], providers: string[], clientCore: string | null, ct: CancelToken = axios.CancelToken.source().token) => {
    try {
      const response = await tripApi.getFlights2(routes, providers, clientCore, ct);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  const flightsBook = async (request: IBookFlightRequestNew) => {
    try {
      const response = await tripApi.postFlightsBook(request);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  const flightsIssue = async (orderId: string, pnrs: string[]) => {
    try {
      const response = await tripApi.issueOrder(orderId, pnrs);
      return response.data;
    } catch (error) {
      return null;
    }
  }


  const getPaxesList = async (clientCore: string) => {
    try {
      const response = await tripApi.getPaxes(clientCore);
      return response.data;
    } catch (error) {
      return [];
    }
  }


  const getTrainList = async (origin: string, direction: string, date: string, clientCore: string | null, connectionId: string | null) => {
    try {
      const response = await tripApi.getTrains(origin, direction, date, clientCore, connectionId);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  const getTrainList2 = async (routes: { id: string, origin: string, direction: string, date: string, notes?: string }[], clientCore: string | null, ct: CancelToken = axios.CancelToken.source().token) => {
    try {
      const response = await tripApi.getTrains2(routes, clientCore, ct);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  const trainsBook = async (request: IBookTrainRequestNew) => {
    try {
      const response = await tripApi.postTrainsBook(request);
      return response.data;
    } catch (error) {
      return null;
    }
  }


  const getOrderList = async (clientCore: string) => {
    try {
      const response = await tripApi.getOrders(clientCore);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  const getOrder = async (orderNo: string) => {
    try {
      const response = await tripApi.getOrder(orderNo);
      return response.data;
    } catch (error) {
      return null;
    }
  }

  const isOrderIssued = async (clientCore: string, orderNo: string) => {
    try {
      const response = await tripApi.isOrderIssued(clientCore, orderNo);
      return response.data == true;
    } catch (error) {
      return false;
    }
  }

  const getOrderBookingDetails = async (orderNo: string) => {
    try {
      const response = await tripApi.getOrderBookingDetails(orderNo);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  const getOrderIssueDetails = async (clientCode: string, orderNo: string) => {
    try {
      const response = await tripApi.getOrderIssueDetails(clientCode, orderNo);
      return response.data;
    } catch (error) {
      return null;
    }
  }


  return {
    getProviders,
    getFlightList,
    getFlightList2,
    getTrainList,
    getTrainList2,
    flightsBook,
    flightsIssue,
    trainsBook,
    getPaxesList,
    getOrderList,
    getOrder,
    isOrderIssued,
    getOrderBookingDetails,
    getOrderIssueDetails
  };
}
