import {ITravelVariant} from "../interfaces/orderInterfaces/ITravelVariant";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {IAviaVariant} from "../interfaces/orderInterfaces/AviaOrderInterfaces";
import {IDirectionRequest} from "../interfaces/requestsInterfaces/IDirectionRequest";
import {IPax} from "../interfaces/orderInterfaces/IPax";
import {IFilterOptions} from "../interfaces/orderInterfaces/IFilterOptions";
import {TicketType} from "../enums/TicketType";
import {ITrainVariant} from "../interfaces/ITrainVariant";
import {ITripResult} from "../interfaces/orderInterfaces/ITripResult";

interface OrderState {
    isOneWay: boolean,
    travelVariants: ITravelVariant[]
    filteredVariants: ITravelVariant[];
    favoriteVariantIds: number[];
    favoriteVariants: ITravelVariant[]
    aviaVariants: IAviaVariant[];
    trainVariants: ITrainVariant[];
    filterOptions: IFilterOptions;
    orderedMultiRoad: IDirectionRequest[];
    orderedRoundRoute: IDirectionRequest[];
    paxes: IPax[];
    tripResults: ITripResult[]
}

const initialState: OrderState = {

    filterOptions: {
        airlines: new Array<string>(),
        trains: new Array<string>(),
        transfers: new Array<number>(),
        directions: new Array<string>(),
        ticketTypes: new Array<TicketType>()
    },
    orderedRoundRoute: [{
        townFrom: "",
        townTo: "",
        departDate: "",
        clientCode: "",
        notes: ""
    }, {
        townFrom: "",
        townTo: "",
        departDate: "",
        clientCode: "",
        notes: ""
    }],
    travelVariants: [],
    orderedMultiRoad: [{
        townFrom: "",
        townTo: "",
        departDate: "",
        clientCode: "",
        notes: ""
    }],
    isOneWay: true,
    filteredVariants: new Array<ITravelVariant>(),
    aviaVariants: [],
    trainVariants: [],
    paxes: [],
    tripResults: [],
    favoriteVariants: [],
    favoriteVariantIds: []
}

export const orderSlice = createSlice(
    {
        name: "order",
        initialState,
        reducers: {
            setIsOneWay: (state, action: PayloadAction<boolean>) => {
                state.isOneWay = action.payload;
            },
            setTravelVariants: (state, action: PayloadAction<ITravelVariant[]>) => {
                state.travelVariants = action.payload;
            },
            setFilteredVariants: (state, action: PayloadAction<ITravelVariant[]>) => {
                state.filteredVariants = action.payload;
            },
            setFilterOptions: (state, action: PayloadAction<IFilterOptions>) => {
                state.filterOptions = action.payload;
            },
            setAviaVariants: (state, action: PayloadAction<IAviaVariant[]>) => {
                state.aviaVariants = action.payload;
            },
            setTrainVariants: (state, action: PayloadAction<ITrainVariant[]>) => {
                state.trainVariants = action.payload;
            },
            setOrderedMultiRoute: (state, action: PayloadAction<IDirectionRequest[]>) => {
                state.orderedMultiRoad = action.payload;
            },
            setOrderedRoundRoute: (state, action: PayloadAction<IDirectionRequest[]>) => {
                state.orderedRoundRoute = action.payload;
            },
            setPaxes: (state, action: PayloadAction<IPax[]>) => {
                state.paxes = action.payload;
            },
            setTripResults: (state, action: PayloadAction<ITripResult[]>) => {
                state.tripResults = action.payload;
            },
            setFavoriteVariants: (state, action: PayloadAction<ITravelVariant[]>) => {
                state.favoriteVariants = action.payload;
            },
            setFavoriteVariantIds: (state, action: PayloadAction<number[]>) => {
                state.favoriteVariantIds = action.payload;
            }
        }
    }
)

interface UpdateDir {
    index: number
    direction: IDirectionRequest
}

export const {
    setTravelVariants,
    setFilteredVariants,
    setFilterOptions,
    setTrainVariants,
    setAviaVariants,
    setOrderedRoundRoute,
    setOrderedMultiRoute,
    setPaxes,
    setTripResults,
    setFavoriteVariants,
    setFavoriteVariantIds
} = orderSlice.actions;

export const ordersState = (state: RootState): OrderState => {

    return {
        isOneWay: state.order.isOneWay,
        orderedMultiRoad: state.order.orderedMultiRoad,
        orderedRoundRoute: state.order.orderedRoundRoute,
        travelVariants: state.order.filteredVariants,
        filteredVariants: state.order.filteredVariants,
        filterOptions: state.order.filterOptions,
        aviaVariants: state.order.aviaVariants,
        trainVariants: state.order.trainVariants,
        paxes: state.order.paxes,
        tripResults: state.order.tripResults,
        favoriteVariants: state.order.favoriteVariants,
        favoriteVariantIds:state.order.favoriteVariantIds
    }

}

export default orderSlice.reducer;