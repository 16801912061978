import "./style.scss";
import {useAppSelector} from "../../../redux/hooks";
import {MdButton} from "../../../components/md-button";
import {
    COMMON_BUTTON_STYLE,
} from "../../../constants/componentsContants";
import {useState} from "react";
import {InputTextComponent} from "../../commonComponents/inputTextComponent";
import {
    ACCESS_TOKEN,
    CODE_FIELD, DATE_OF_BURN,
    EMAIL_FIELD,
    FIRST_NAME_FIELD, GENDER_FIELD,
    LAST_NAME_FIELD,
    LOGIN_FIELD,
    PATRONYMIC_FIELD, POSITION_FIELD
} from "../../../constants/FieldsConstants";
import {useCookies} from "react-cookie";
import {useTranslation} from "react-i18next";
import {InputDateComponent} from "../../commonComponents/inputDateComponent";
import {ISignupSupportUserRequest} from "../../../interfaces/support/ISignupSupportUserRequest";
import {ISupportUserResponse} from "../../../interfaces/support/ISupportUserResponse";
import {SupportApi} from "../../../api/SupportApi";
import {SignupSupportUserValidator} from "../../../validators/SignupSupportUserValidator";
import {SearchIcon} from "../../../icons/SearchIcon";
import {LoadingComponent} from "../../commonComponents/loadingComponent";
import {EmailValidator} from "../../../validators/EmailValidator";
import {ErrorMessage} from "../../commonComponents/ErrorComponents/errorMessage/ErrorMessage";
import {ErrorText} from "../../../enums/ErrorText";
import {DataConvertService} from "../../../services/DataConvertService";

enum SupportPageMode {
    GetOne,
    GetAll,
    AddNewSupport
}

export function SupportPage() {

    //external resources
    const {t} = useTranslation();
    const authState = useAppSelector(state => state.auth);
    const {addSupportUser, getTtsUser} = SupportApi();
    const [cookies] = useCookies([ACCESS_TOKEN]);
    const signupValidator = new SignupSupportUserValidator();
    const emailValidator = new EmailValidator();
    const {getError} = DataConvertService();

    //internal resoursec
    const [showForm, setShowForm] = useState(false);
    const [errorMsg, setErrormsg] = useState<Record<string, string>>({})
    //const [searchErr, setSearchErr] = useState("")
    //const [clientCodeForSearch, setClientCodeForSearch] = useState(authState.currentClientCode);
    const [gender, setGender] = useState('none')

    //hooks search from tts
    const [emailForTts, setEmailForTts] = useState("");
    const [emailErrTts, setEmailErrTts] = useState("");
    const [ttsSupportUser, setTtsSupportUser] = useState<ISupportUserResponse>();
    const [isSearchingTtsUser, setIsSearchingTtsUser] = useState(false);

    const [supportUserForm, setSupportUserForm] = useState<ISignupSupportUserRequest>({
        code: "",
        dateOfBurn: "",
        email: "",
        firstName: "",
        gender: false,
        lastName: "",
        login: "",
        patronymic: "",
        position: ""
    })

    //const [supportUsers, setAllSupportUser] = useState<ISupportUserResponse[]>([])
    // const [pageMode, setPageMode] = useState<SupportPageMode>(SupportPageMode.GetOne);

    //disabled 08.10.2024
    // const deleteSupportUserHandler = () => {
    //
    //     //add  delete  and confirm in modal
    //     let confirmResult = window.confirm("");
    // }
    // const getAllSupportUsersHandler = async () => {
    //
    //     const result = await getTtsUser("n.nakonechnikova@turne-trans.ru")
    //     console.log(result)
    // }

    const getTtsUserByEmailHandler = async () => {
        setIsSearchingTtsUser(true)
        setTtsSupportUser(undefined)
        // setTimeout(() => {
        // }, 5000)

        const validateResult = emailValidator.validate({email: emailForTts})
        if (validateResult.email !== undefined) {
            setEmailErrTts(getError(t("commonText.email"),ErrorText.InvalidEmail)??"");
            setIsSearchingTtsUser(false)
            return;
        }

        const result = await getTtsUser(emailForTts);

        setIsSearchingTtsUser(false);

        if (result === null) {
            console.log("is null");
            setEmailErrTts("User not found")
            return;
        }
        if (result === undefined) {
            console.log("isundefined");
            setEmailErrTts("Server Unaveilable")
            return;
        }

        setTtsSupportUser(result);

        setSupportUserForm({
            code: result.code,
            dateOfBurn: (new Date(result.dateOfBurn)).toISOString(),
            email: result.email,
            firstName: result.firstName,
            gender: result.gender,
            lastName: result.lastName,
            login: result.login,
            patronymic: result.patronymic,
            position: result.position
        })
        setGender(result.gender ? "male" : "female");
    }
    //move to service

    const addNewSupportUser = async () => {

        let validationResult = signupValidator.validate(supportUserForm);
        let err: Record<string, string> = {};
        setErrormsg(err);

        //Update error object
        if (validationResult.code !== undefined) Object.assign(err, {"code": getError(t("commonText.firstName"), validationResult.code as string)})
        if (validationResult.email !== undefined) Object.assign(err, {"email": getError(t("commonText.firstName"), validationResult.email as string)})
        if (validationResult.login !== undefined) Object.assign(err, {"login": getError(t("commonText.firstName"), validationResult.login as string)})
        if (validationResult.firstName !== undefined) Object.assign(err, {"firstName": getError(t("commonText.firstName"), validationResult.firstName as string)})
        if (validationResult.lastName !== undefined) Object.assign(err, {"lastName": getError(t("commonText.lastName"), validationResult.lastName as string)})
        if (validationResult.position !== undefined) Object.assign(err, {"position": getError(t("commonText.lastName"), validationResult.position as string)})

        if (gender === "none") Object.assign(err, {"gender": getError(t("supportPage.gender"), ErrorText.NeedSelect)})

        if (Object.getOwnPropertyNames(err).length > 0) {
            const errors = Object.getOwnPropertyNames(err);
            setErrormsg(err);
            return;
        }
        setErrormsg(err);

        if (gender !== "none") {
            updateSupportUserHandler({"gender": gender})
        }

        await addSupportUser(supportUserForm, cookies[ACCESS_TOKEN])
    }


    //supportUser
    const updateSupportUserHandler = (value: { [key: string]: string }) => {
        let user = Object.assign({}, supportUserForm)

        const propName = Object.getOwnPropertyNames(value)[0]
        console.log(propName)

        switch (propName) {
            case LOGIN_FIELD: {
                user.login = value[propName];
                break;
            }
            case EMAIL_FIELD: {
                user.email = value[propName];
                break;
            }
            case CODE_FIELD: {
                user.code = value[propName];
                break;
            }

            case FIRST_NAME_FIELD: {
                user.firstName = value[propName];
                break;
            }
            case LAST_NAME_FIELD: {
                user.lastName = value[propName];
                break;
            }
            case PATRONYMIC_FIELD: {
                user.patronymic = value[propName];
                break;
            }
            //using only before add new support
            case GENDER_FIELD: {
                // if(value[propName] ==="none")  break;
                user.gender = value[propName] === "male";
                break;
            }
            case POSITION_FIELD: {
                user.position = value[propName];
                break;
            }
            case DATE_OF_BURN: {
                user.dateOfBurn = value[propName];
                break;
            }

            default: {
                break;
            }

        }
        console.log(`after case ${user.gender}`)
        setSupportUserForm(user);
    }


    return (

        <div className="support-page-container">

            <div className="support-page-row-item">
                <div className="support-info-block">
                    <div typeof="label">{t("supportPage.yourName")}</div>
                    <div typeof="info">{authState.fullName}</div>
                </div>
                <div className="support-vertical-border"></div>

                <div className="support-info-block">
                    <div typeof="label">{t("supportPage.yourEmail")}</div>
                    <div typeof="info">{authState.email ?? "Not data"}</div>
                </div>
                <div className="support-vertical-border"></div>

                <div className="support-info-block">
                    <div typeof="label">{t("supportPage.yourRole")}</div>
                    <div typeof="info">{authState.userRole}</div>
                </div>
                <div className="support-vertical-border"></div>


                <div style={{marginLeft: "auto"}} className="d-flex flex-row gap-2 align-self-center">

                    {/*ModeSelector disabled  08/10/2024 */}
                    {/*<CustomCheckboxButton isChecked={pageMode === SupportPageMode.GetOne} onChange={() => {*/}
                    {/*    setPageMode(SupportPageMode.GetOne)*/}
                    {/*}} label={"Mode: Get one"}/>*/}
                    {/*<CustomCheckboxButton isChecked={pageMode === SupportPageMode.GetAll} onChange={() => {*/}
                    {/*    setPageMode(SupportPageMode.GetAll)*/}
                    {/*}} label={"Mode: Get All"}/>*/}
                    {/*<CustomCheckboxButton isChecked={pageMode === SupportPageMode.AddNewSupport} onChange={() => {*/}
                    {/*    setPageMode(SupportPageMode.AddNewSupport)*/}
                    {/*}} label={"Mode: Add new"}/>*/}

                    <MdButton style={COMMON_BUTTON_STYLE}
                              children={showForm ?
                                  <p style={{color: "white", width: "250px"}}>{t("supportPage.hideForm")}</p> :
                                  <p style={{color: "white", width: "250px"}}>{t("supportPage.addNewUser")}</p>}
                              onClick={() => setShowForm(!showForm)}
                    />

                    {/* Disabled 08.10.2024*/}
                    {/*<CustomCheckboxButton isChecked={showForm} onChange={() => {*/}
                    {/*    setShowForm(!showForm)*/}
                    {/*}} label={showForm ? t("supportPage.hideForm") : t("supportPage.addNewUser")}/>*/}
                </div>

                {/*<div className="support-page-button"><MdButton style={COMMON_BUTTON_STYLE}*/}
                {/*                                               children={<p>Update your info</p>}/></div>*/}

                {/*<div className="support-page-button">*/}
                {/*    <MdButton style={COMMON_BUTTON_STYLE}*/}
                {/*              onClick={() => setShowForm(!showForm)}*/}
                {/*              children={<p>Add support user</p>}/>*/}
                {/*</div>*/}

                {/*<div className="support-page-button"><MdButton style={DANGER_BUTTON_STYLE}*/}
                {/*                                               onClick={() => {*/}
                {/*                                                   deleteSupportUserHandler()*/}
                {/*                                               }}*/}

                {/*                                               children={<p style={{color: "white"}}>Delete</p>}/>*/}
                {/*</div>*/}

            </div>


            {/* Select Mode and disabled 08.10.2024*/}
            {/*<div className="support-page-row-item">*/}


            {/*    {(pageMode === MODE_GET_ONE) &&*/}
            {/*        <InputTextComponent value={""} setValue={(value: string) => {*/}
            {/*        }} placeholder={"email"}/>}*/}


            {/*    {(pageMode === MODE_GET_ONE || pageMode === MODE_GET_ALL) &&*/}
            {/*        <InputTextComponent value={authState.currentClientCode}*/}
            {/*                            setValue={(value: string) => {*/}
            {/*                            }} placeholder={"ClientCode"}/>}*/}

            {/*    {pageMode === MODE_GET_ONE && <MdButton style={COMMON_BUTTON_STYLE}*/}
            {/*                                            onClick={() => getAllSupportUsersHandler()}*/}
            {/*                                            children={<p style={{color: "white"}}>Get support user</p>}/>}*/}

            {/*    {pageMode === MODE_GET_ALL &&*/}


            {/*        <MdButton style={COMMON_BUTTON_STYLE}*/}
            {/*                  onClick={() => getAllSupportUsersHandler()}*/}
            {/*                  children={<p style={{color: "white"}}>Get All support users</p>}/>*/}


            {/*    }*/}

            {/*</div>*/}


            {/*{searchErr}*/}

            {/*Search user from Tts*/}
            {showForm && <>
                <div className="support-page-row-item">
                    <div className="align-self-center">{t("supportPage.searchTtsUser")}</div>
                    <InputTextComponent value={emailForTts} setValue={(value: string) => {
                        setEmailErrTts("");
                        setTtsSupportUser(undefined)
                        setEmailForTts(value);

                    }}
                                        placeholder={"email"} runActionByEnter={getTtsUserByEmailHandler}/>
                    <div typeof="button">
                        <MdButton style={COMMON_BUTTON_STYLE} children={<p>Search</p>} icon={<SearchIcon/>}
                                  onClick={() => getTtsUserByEmailHandler()}/></div>

                    {isSearchingTtsUser && <LoadingComponent message={"Поиск пользвателя"}/>}
                    {emailErrTts && <> &nbsp;&nbsp;<ErrorMessage error={emailErrTts}/></>}
                    {ttsSupportUser && <p style={{
                        alignSelf: "center",
                        fontSize: "1rem"
                    }}>{t("supportPage.searchTtsUserResult", {email: emailForTts})}</p>}
                </div>

                {/*Add support user form*/}
                <div className="support-user-form-container ">

                    {/*Support user code*/}
                    <div className="support-form-row-item">
                        <div typeof="support-form-label"><p>{t("supportPage.code") + ":"}&nbsp;</p></div>
                        <div typeof="support-form-input"><InputTextComponent value={supportUserForm?.code ?? ""}
                            //title={}
                                                                             placeholder={t("supportPage.code")}
                                                                             setValue={(value) => {
                                                                                 updateSupportUserHandler({"code": value})
                                                                             }}
                        />
                        </div>
                    </div>

                    {/*Support user login*/}
                    <div className="support-form-row-item">
                        <div typeof="support-form-label"><p>{t("supportPage.login") + ":"}&nbsp;</p></div>
                        <div typeof="support-form-input"><InputTextComponent value={supportUserForm?.login ?? ""}
                            //title={}
                                                                             placeholder={t("supportPage.login")}
                                                                             setValue={(value) => {
                                                                                 updateSupportUserHandler({"login": value})
                                                                             }}

                        />
                        </div>
                    </div>
                    {errorMsg[LOGIN_FIELD] &&
                        <div className="support-form-row-item"><ErrorMessage error={errorMsg[LOGIN_FIELD]}/></div>}

                    {/*Support user email*/}
                    <div className="support-form-row-item">
                        <div typeof="support-form-label"><p>{t("commonText.email") + ":"}&nbsp;</p></div>
                        <div typeof="support-form-input"><InputTextComponent value={supportUserForm?.email ?? ""}
                            //title={}
                                                                             placeholder={t("commonText.email")}
                                                                             setValue={(value) => {
                                                                                 updateSupportUserHandler({"email": value})
                                                                             }}

                        />
                        </div>
                    </div>
                    {errorMsg[EMAIL_FIELD] &&
                        <div className="support-form-row-item"><ErrorMessage error={errorMsg[EMAIL_FIELD]}/></div>}

                    {/*Support user firstName*/}
                    <div className="support-form-row-item">
                        <div typeof="support-form-label"><p>{t("commonText.firstName") + ":"}&nbsp;</p></div>
                        <div typeof="support-form-input"><InputTextComponent value={supportUserForm?.firstName ?? ""}
                            //title={}
                                                                             placeholder={t("commonText.firstName")}
                                                                             setValue={(value) => {
                                                                                 updateSupportUserHandler({"firstName": value})
                                                                             }}

                        />
                        </div>
                    </div>
                    {errorMsg[FIRST_NAME_FIELD] &&
                        <div className="support-form-row-item"><ErrorMessage error={errorMsg[FIRST_NAME_FIELD]}/></div>}

                    {/*Support user lastName*/}
                    <div className="support-form-row-item">
                        <div typeof="support-form-label"><p>{t("commonText.lastName")}&nbsp;</p></div>
                        <div typeof="support-form-input"><InputTextComponent value={supportUserForm?.lastName ?? ""}
                                                                             placeholder={t("commonText.lastName")}
                                                                             setValue={(value) => {
                                                                                 updateSupportUserHandler({"lastName": value})
                                                                             }}

                        />
                        </div>
                    </div>
                    {errorMsg[LAST_NAME_FIELD] &&
                        <div className="support-form-row-item"><ErrorMessage error={errorMsg[LAST_NAME_FIELD]}/></div>}


                    {/*Support user patronymic*/}
                    <div className="support-form-row-item">
                        <div typeof="support-form-label"><p>{t("commonText.patronymic")}&nbsp;</p></div>
                        <div typeof="support-form-input"><InputTextComponent value={supportUserForm?.patronymic ?? ""}
                                                                             title={"login"}
                                                                             placeholder={t("commonText.patronymic")}
                                                                             setValue={(value) => {
                                                                                 updateSupportUserHandler({"patronymic": value})
                                                                             }}

                        />
                        </div>
                    </div>

                    {errorMsg[PATRONYMIC_FIELD] &&
                        <div className="support-form-row-item"><ErrorMessage error={errorMsg[PATRONYMIC_FIELD]}/></div>}

                    {/*Support user gender*/}
                    <div className="support-form-row-item">
                        <div typeof="support-form-label"><p>{t("supportPage.gender")}&nbsp;</p></div>
                        <div typeof="support-form-input">
                            <select name="cars" id="cars"
                                    value={gender}
                                    onChange={(e) => {
                                        setGender(e.target.value)
                                    }}>
                                <option value="none"></option>
                                <option value="male">{t("supportPage.male")}</option>
                                <option value="female">{t("supportPage.female")}</option>
                            </select>
                        </div>
                    </div>
                    {errorMsg[GENDER_FIELD] &&
                        <div className="support-form-row-item"><ErrorMessage error={errorMsg[GENDER_FIELD]}/></div>}

                    {/*Support user position*/}
                    <div className="support-form-row-item">
                        <div typeof="support-form-label"><p>{t("supportPage.position")}&nbsp;</p></div>
                        <div typeof="support-form-input"><InputTextComponent value={supportUserForm?.position ?? ""}
                                                                             placeholder={t("supportPage.position")}
                                                                             setValue={(value) => {
                                                                                 updateSupportUserHandler({"position": value})
                                                                             }}

                        />
                        </div>
                    </div>

                    {errorMsg[POSITION_FIELD] &&
                        <div className="support-form-row-item"><ErrorMessage error={errorMsg[POSITION_FIELD]}/></div>}

                    {/*Support user date of birth*/}
                    <div className="support-form-row-item">
                        <div typeof="support-form-label"><p>{t("supportPage.dateOfBirth")}&nbsp;</p></div>
                        <div typeof="support-form-input"><InputDateComponent
                            //inputDateISO={supportUserForm?.dateOfBurn}
                            inputDateISO={supportUserForm.dateOfBurn}
                            setDateHandler={(dateIso: string) => {
                                let date = new Date(dateIso);
                                console.log(`Support dateIso: ${dateIso}; date: ${date}`)
                                updateSupportUserHandler({"date": dateIso})
                            }}/>
                        </div>
                    </div>

                    {/*<div className="support-form-row-item">*/}
                    {/*    {errorMsg && <p>{errorMsg}</p>}*/}
                    {/*</div>*/}


                    <div className="support-form-row-item"
                         style={{
                             borderTop: "1px solid gray",
                             backgroundColor: "lightgray",
                             borderRadius: "10px"
                         }}
                    >
                        <div typeof="button">
                            <MdButton style={COMMON_BUTTON_STYLE}
                                      children={<p>{t("supportPage.addNewUser")}</p>}
                                      onClick={() => {
                                          addNewSupportUser()
                                      }}
                            />
                        </div>
                    </div>


                </div>
            </>
            }
        </div>
    )
}