export const BEARER = "Bearer ";
//Base routes
export const BASE_URL = process.env.REACT_APP_BASE_ROUTE;

//ToDo Update Rurtes
export const BASE_ROUTE_AUTH_SERVICE = process.env.REACT_APP_BASE_ROUTE_AUTH_SERVICE;
export const BASE_ROUTE_GATEWAY_SERVICE = process.env.REACT_APP_GATEWAY_ROUTE;
export const  BASE_ROUTE_INTEROP_SERVICE = process.env.REACT_APP_INTEROP_BASE_ROUTE;

//BASE_ROUTE_AUTH_SERVICE, BASE_ROUTE_GATEWAY_SERVICE,

// Routes for search Travel variants
export const ROUTE_FIND_AVIA_VARIANTS = "/api/v1/webauth/Order/searchFlights";
export const ROUTE_FIND_TRAIN_VARIANTS = "/api/v1/webauth/Order/searchTrains";
export const ROUTE_FIND_TRAIN_INTEROP = "/api/v1/trains/search";
// Routes for orders
export const ROUTE_BOOK_FLIGHT = "/api/Order/bookFlights"
export const ROUTE_ISSUE_FLIGHT = "/api/Order/issueFlight"

export const ROUTE_BOOK_TRAIN_INTEROP = "/api/v1/trains/book";
//export const ROUTE_GET_PAXES = "/api/Order/getPaxes";
export const ROUTE_GET_PASSENGERS = "http://localhost:49301/api/v1/flights/getpaxes";


//export const BASE_URL = process.env.REACT_APP_BASE_ROUTE;
//export const BASE_URL = "https://lk.turne-trans.ru";

// Hubs Routes
export const ROUTE_REPORTS_HUB  = "/api/v1/Edm/edmHub";
export const ROUTE_CHAT_HUB               = "/chatHub";


// WebAuth Routes (Auth)
export const ROUTE_LOGIN                  = "/gateway/api/v1/webauth/auth/login";
export const ROUTE_RECOVERY_PASSWORD      = "/gateway/api/v1/webauth/auth/recovery";
export const ROUTE_CHANGE_PASSWORD        = "/gateway/api/v1/webauth/auth/changePassword";
export const ROUTE_REFRESH_TOKEN          = "/gateway/api/v1/webauth/auth/refresh-token";
export const ROUTE_REVOKE_TOKEN           = "/gateway/api/v1/webauth/auth/revoke-token";
export const ROUTE_LOGOUT                 = "/gateway/api/v1/webauth/auth/logout";
export const ROUTE_GET_USER_ACTIVE_STATE  = "/gateway/api/v1/webauth/auth/isActiveUser";
// WebAuth Routes (Orders)
export const ROUTE_GET_ORDERS                 = "/gateway/api/v1/webauth/order/getClientOrders";
export const ROUTE_GET_ORDER_BY_ID             = "/gateway/api/v1/webauth/order/getOrder";

// Interop Routes (Flights)
export const ROUTE_GET_PROVIDERS  = "/gateway/api/v1/interop/flights/getProviders"
export const ROUTE_GET_FLIGHTS    = "/gateway/api/v1/interop/flights/search";
export const ROUTE_GET_PAXES     = "/gateway/api/v1/interop/pax/getpaxes";
export const ROUTE_BOOK_FLIGHTS   = "/gateway/api/v1/interop/flights/book";
export const ROUTE_ISSUE_ORDER    = "/gateway/api/v1/interop/flights/issue";
// Interop Routes (Order)
export const ROUTE_IS_ORDER_ISSUED            = "/gateway/api/v1/interop/order/hasIssuedBso";
export const ROUTE_GET_ORDER_BOOKING_DETAILS  = "/gateway/api/v1/interop/order/getOrderBookingInfo";
export const ROUTE_GET_ORDER_ISSUE_DETAILS    = "/gateway/api/v1/interop/order/getOrderIssueDetails";

// Interop Routes (Trains)
export const ROUTE_GET_TRAINS   = "/gateway/api/v1/interop/trains/search";
export const ROUTE_BOOK_TRAINS  = "/gateway/api/v1/interop/trains/book";
// Interop Routes (Companies)
export const ROUTE_SEARCH_COMPANIES_BY_NAME        = "/gateway/api/v1/interop/companies/searchCompaniesByName"
export const ROUTE_GET_CLIENT_INFO_BY_CLIENT_CODE  = "/gateway/api/v1/interop/companies/getCompanyInfo";
export const ROUTE_GET_CLIENT_INFO_BY_NAME         = "/gateway/api/v1/interop/companies/searchCompaniesByName";
// Interop Routes (Documents)
export const ROUTE_GET_ACCOUNTANT_DOCS               = "/gateway/api/v1/interop/docs/getAccountantDocuments"
export const ROUTE_GET_CUSTOMER_AIR_COMPANIES        = "/gateway/api/v1/interop/docs/getCustomerAirCompanies";
export const ROUTE_GET_CUSTOMER_FLIGHTS              = "/gateway/api/v1/interop/docs/getCustomerFlights";
export const ROUTE_GET_CUSTOMER_TICKET_ORDERS        = "/gateway/api/v1/interop/docs/getCustomerTicketOrders";
export const ROUTE_GET_CUSTOMER_ADDITIONAL_SERVICES  = "/gateway/api/v1/interop/docs/getCustomerAdditionalServices";
export const ROUTE_GET_STATEMENT_INCOMES             = "/gateway/api/v1/interop/docs/getStatementIncomes";
export const ROUTE_GET_STATEMENT_OPEN_BALANCE        = "/gateway/api/v1/interop/docs/getStatementOpenBalance";
export const ROUTE_GET_STATEMENT_CLOSE_BALANCE       = "/gateway/api/v1/interop/docs/getStatementCloseBalance";
export const ROUTE_GET_STATEMENT_SALES               = "/gateway/api/v1/interop/docs/getStatementSales";
export const ROUTE_GET_ALLOWED_PERSONS               = "/gateway/api/v1/interop/docs/getAllowedPerson";
export const ROUTE_GET_CLIENTS_DETAILS               = "/gateway/api/v1/interop/docs/getDeptInfo";
export const ROUTE_GET_CLIENTS_STRUCTURE             = "/gateway/api/v1/interop/docs/getSalesStructure";
export const ROUTE_GET_CLIENTS_SALES                 = "/gateway/api/v1/interop/docs/getClientSales";


// EDM Routes (Documents)
export const ROUTE_GET_EDM_UPLOADS       = "/gateway/api/v1/interop/edm/docs/uploads";
export const ROUTE_GENERATE_EDM_REPORTS  = "/gateway/api/v1/interop/edm/docs/send";


// TTS Sync Routes (Users)
export const ROUTE_GET_USERS                  = "/gateway/api/v1/webauth/ttsSync/getUsers";
export const ROUTE_GET_USER_ROLES             = "/gateway/api/v1/webauth/ttsSync/getUserRoles";
export const ROUTE_ADD_USER                   = "/gateway/api/v1/webauth/ttsSync/signup";
export const ROUTE_UPDATE_USER                = "/gateway/api/v1/webauth/ttsSync/updateUser";
export const ROUTE_ADD_DEFAULT_COMPANY_ROLES  = "/gateway/api/v1/webauth/ttsSync/addDefaultRoles"
// TTS Sync Routes (Companies)
export const ROUTE_IS_COMPANY_EXIST     = "/gateway/api/v1/webauth/ttsSync/isCompanyExists"
export const ROUTE_GET_COMPANY_BY_CODE  = "/gateway/api/v1/webauth/ttsSync/getCompany";
export const ROUTE_ADD_COMPANY          = "/gateway/api/v1/webauth/ttsSync/addOrUpdateCompany"
export const ROUTE_IMPORT_COMPANY       = "/gateway/api/v1/webauth/ttsSync/importCompany"
export const ROUTE_GET_ALL_COMPANIES    = "/gateway/api/v1/webauth/ttsSync/getAllCompanies"


export const ROUTE_GET_REFS_CITIES    = "/gateway/api/v1/webrefs/autocomplete/searchCities"


