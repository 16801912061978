import {TicketType} from "../enums/TicketType";
import {OrderTicketApi} from "../api/OrderTicketApi";
import {ITravelVariant} from "../interfaces/orderInterfaces/ITravelVariant";
import {IAviaVariant} from "../interfaces/orderInterfaces/AviaOrderInterfaces";
import {DateService} from "./DateService";
import {IDirectionRequest} from "../interfaces/requestsInterfaces/IDirectionRequest";

import {OrderSort} from "../enums/OrderSort";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {
    setFavoriteVariants,
    setFilteredVariants,
    setFilterOptions,
    setPaxes,
    setTravelVariants,
    setTripResults,
} from "../redux/orderSlice";
import {IPax} from "../interfaces/orderInterfaces/IPax";
import {IBookFlight, IDirRequest, IPassportRequest, IPaxRequest} from "../interfaces/orderInterfaces/bookInterfaces";
import {IFilterOptions} from "../interfaces/orderInterfaces/IFilterOptions";
import {IBookTrainRequest} from "../interfaces/orderInterfaces/IBookTrainRequest";
import {ITrainVariant} from "../interfaces/ITrainVariant";
import {ITripResult} from "../interfaces/orderInterfaces/ITripResult";
import {IS_PRODUCTION} from "../constants/ServiceCostants";
import {Guid} from "guid-ts";
import {ITripOrder} from "../interfaces/orderInterfaces/ITripOrder";
import {TripApi} from "../api/TripApi";
import {IBookFlightRequestNew, IBookParams, IPaxBookParams} from "../Requests/IBookFlightRequest";

export function OrderService() {

    const authState = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch()
    const orderState = useAppSelector(state => state.order);

    const {
        searchCities,
        searchAviaFlightsExtended,
        getPaxes,
        searchTrainsInterop,
        bookTrainApi
    } = OrderTicketApi();

    const {postFlightsBook} = TripApi();
    const {getTravelTime, getDateFromTrainVariant} = DateService()

    //exported functions
    const getAllPaxes = async (clientCode: string) => {

        if (!IS_PRODUCTION) {
            clientCode = clientCode ?? "Ф0013808";
        }

        const result = await getPaxes(clientCode);

        if (result.length > 0) {
            dispatch(setPaxes(result))
            return true
        }
        return false;
    }

    const bookFlight = async (variant: ITravelVariant, selectedTariff: number, paxes: IPax[], clientCode?: string) => {

        //ToDO need to test
        if (!IS_PRODUCTION) {
            clientCode = clientCode ?? "Ф0013808";
        }

        let paxRequests = paxes.map(pax => getPaxRequest(pax));
        let dirRequest: IDirRequest[] = getDirRequests(variant, selectedTariff);

        let request: IBookFlight = {
            requested: {
                trips: [{passengers: paxRequests, dirs: dirRequest}]
            }
        }

        let bookPaxList: IPaxBookParams[] = paxes.map(pax => {
            return {
                paxCode: pax.paxCode,
                selectedPassportNo: pax.passportNum
            }
        })

        let bookParams: IBookParams = {
            fareBasises: dirRequest[0].FareBasises,
            paxes: bookPaxList,
            searchResponse: orderState.tripResults.find(tr => (
                tr.direction.townTo === variant.direction?.townTo &&
                tr.direction.townFrom === variant.direction.townFrom
            ))?.requested
        }


        let newBookRequest: IBookFlightRequestNew = {
            clientCode: authState.currentClientCode,
            parameters: [bookParams],
            supportUserCode: authState.supportUserCode,
            userId: authState.userId,
        }


        const res = await postFlightsBook(newBookRequest)

        console.log(`service ${res.status} data: ${res.data}`)
        return false;
    }

    const bookTrain = async (variant: ITravelVariant, selectedTariff: number, paxes: IPax[], clientCode?: string) => {

        //ToDO need to test
        if (!IS_PRODUCTION) {
            clientCode = clientCode ?? "Ф0013808";
        }

        const tripResponse = findTripResponse(variant)
        //check trip response
        if (tripResponse === undefined || tripResponse.variants === undefined || tripResponse.requested === undefined) return false;

        const trainVariant = variant.travelVariant as ITrainVariant

        let request: IBookTrainRequest = {
            clientCode: clientCode ?? "",
            searchResponse: {
                requested: tripResponse!.requested,
                variants: [variant.travelVariant as ITrainVariant]
            },
            fareBasises: [trainVariant.categoryPrice[selectedTariff].fareBasis],
            paxCodes: paxes.map(pax => pax.paxCode)
        }

        return await bookTrainApi([request])
    }

    //main function for search avia/tra in/hotels
    const searchTravelVariants = async (directions: IDirectionRequest[],
                                        needSearchAvia: boolean,
                                        needSearchTrain: boolean,
    ) => {
        let promises = new Array<Promise<ITripResult>>();

        dispatch(setTripResults([]));

        directions.forEach(dir => {
            if (dir.townTo !== undefined && dir.townFrom !== undefined && dir.departDate !== undefined) {


                if (needSearchAvia) {

                    const promise = new Promise<ITripResult>(async (resolve, reject) => {

                        const response = await searchAviaFlightsExtended(dir);

                        let tripResult: ITripResult = {
                            direction: dir,
                            requested: response.data.requested,
                            requestType: TicketType.AviaTicket,
                            variants: response.data.variants
                        }

                        if (response.status === 200) {
                            return resolve(tripResult)
                        } else return reject(response.status)
                    })
                    promises.push(promise);

                }
                if (needSearchTrain) {

                    const promise = new Promise<ITripResult>(async (resolve, reject) => {

                        const response = await searchTrainsInterop(dir.departDate, dir.townFrom, dir.townTo, "Ф0019608")
                        let tripResult: ITripResult = {
                            direction: dir,
                            requested: response.data.requested,
                            requestType: TicketType.TrainTicket,
                            variants: response.data.variants
                        }
                        console.log("TripResult", tripResult);
                        if (response.status === 200) {
                            return resolve(tripResult)
                        } else return reject(response.status)
                    })
                    promises.push(promise);
                }
            }
        })

        //Run All request
        let travelVariantResult = new Array<ITravelVariant>();
        let tripResults = new Array<ITripResult>()

        await Promise.allSettled(promises).then(results => results.forEach(x => {
                if (x.status === "fulfilled") {
                    tripResults.push(x.value)
                } else if (x.status === "rejected") {
                    console.warn("status rejected")
                }
            })
        );

        //Save Search result
        dispatch(setTripResults(tripResults));
        let startId = 0;
        tripResults.forEach(r => {
            r.variants.forEach(variant => {
                    travelVariantResult.push(toTravelVariant(r.requestType, r.direction, variant, startId))
                    startId++;
                }
            )
        })

        //complete id
        // travelVariantResult.forEach((variant, index) => variant.id = index);
        return travelVariantResult;
    }

    const searchCityNamesByPartName = async (partName: string): Promise<string[]> => {
        const result = await searchCities(partName);
        return result.map(item => item.city)
    }

    const clearPrevioslySearchResult = () => {
        dispatch(setFavoriteVariants([]));
        dispatch(setTripResults([]));
        dispatch(setFilterOptions(getEmtyFilterData()));
        dispatch(setFilteredVariants([]));
        dispatch(setTravelVariants([]));
    }

    const getFiltersData = (travelVariants: ITravelVariant[]) => {
        let filterData: IFilterOptions = getEmtyFilterData();

        // Add filter by type train or flight
        if (travelVariants.some(variant => variant.ticketType === TicketType.AviaTicket)) {
            filterData.ticketTypes.push(TicketType.AviaTicket);
        }
        if (travelVariants.some(variant => variant.ticketType === TicketType.TrainTicket)) {
            filterData.ticketTypes.push(TicketType.TrainTicket);
        }

        travelVariants.forEach((variant) => {

            // ToDo add directions (after added train - move to other place, because identical for train searchig
            if (variant.direction !== undefined) {
                let currentDirection = variant.direction.townFrom + "-" + variant.direction.townTo;
                const checkExistDirection = filterData.directions.find(dir => dir === currentDirection);
                if (checkExistDirection === undefined) {
                    filterData.directions.push(currentDirection)
                }
            }

            if (variant.ticketType === TicketType.AviaTicket) {
                // console.log(`TravelVariant ${index}`)
                const isExistCurrentTransfer = filterData.transfers.find(t => t === variant.transfersCount)
                //console.log(`TravelVariant/ isExistCurrentTransfer ${isExistCurrentTransfer}`)
                if (isExistCurrentTransfer === undefined) filterData.transfers.push(variant.transfersCount);

                variant.travelCompanyOrVehicle.forEach(company => {
                    const isIncludeCompany = filterData.airlines.find(airline => airline === company)
                    if (isIncludeCompany === undefined) filterData.airlines.push(company)
                })
            }
            if (variant.ticketType === TicketType.TrainTicket) {
                const isExistCurrentTrain = filterData.trains.find(train => train === variant.travelCompanyOrVehicle[0])
                if (isExistCurrentTrain === undefined) filterData.trains.push(variant.travelCompanyOrVehicle[0])
            }
        })

        return filterData;
    }

    const toTravelVariant = (ticketType: TicketType, direction: IDirectionRequest, variant: IAviaVariant | ITrainVariant, id?: number): ITravelVariant => {

        console.log(`totravel variant id ${id}`)
        try {
            if (ticketType === TicketType.AviaTicket && variant !== undefined) {
                let ticket = variant as IAviaVariant
                return aviaVariantToTravelVariant(ticket, direction, id)
            }

            //! need to implement
            if (ticketType === TicketType.TrainTicket && variant !== undefined) {
                let ticket = variant as ITrainVariant
                return trainVariantToTravelVariant(ticket, direction, id)
            }
            return initializeTravelVariant(id);
        } catch (err) {
            console.warn(err)
            return initializeTravelVariant(id);
        }
    }

    const applyAllFilters = (trips: ITravelVariant[], opt: IFilterOptions) => {

        return trips.filter(trip => {

            let result = true;

            //apply ticketType filters
            if (!opt.ticketTypes.some(ticketType => ticketType === trip.ticketType)) {
                result = false;
                return result;
            }

            //apply directions filters
            if (!opt.directions.some(rf => rf === trip.direction?.townFrom + "-" + trip.direction?.townTo)) {
                result = false;
                return result;
            }

            //apply airlines filters
            let countAirlinesIncluded = 0;
            trip.travelCompanyOrVehicle.forEach(company => {
                if (trip.ticketType === TicketType.TrainTicket || opt.airlines.some(air => air === company)) countAirlinesIncluded++
            })
            //Info: update next if for other various types of matches, current one from  many(ticket has at least one airline from the filters)
            if (countAirlinesIncluded === 0) {
                result = false;
                return result;
            }

            //applyTrain filters
            if (trip.ticketType === TicketType.TrainTicket && !opt.trains.some(tr => tr === trip.travelCompanyOrVehicle[0])) {
                result = false;
                return result;
            }

            // apply transfers filters
            if (!opt.transfers.some(tf => tf === trip.transfersCount)) {
                result = false;
                return result;
            }

            return result;
        });
    }

    //Ok
    const sortByParameter = (sortOptions: number, unsortedVariants: ITravelVariant[]) => {

        if ((OrderSort.MinPrice | OrderSort.SortByAsc) === sortOptions) {
            return unsortedVariants.sort((a, b) => a.minPrice - b.minPrice);
        }

        if (sortOptions === (OrderSort.MinPrice | OrderSort.SortByDesc)) {
            return unsortedVariants.sort((a, b) => b.minPrice - a.minPrice);
        }

        if (sortOptions === (OrderSort.MaxPrice | OrderSort.SortByAsc)) {
            return unsortedVariants.sort((a, b) => a.maxPrice - b.maxPrice)
        }

        if (sortOptions === (OrderSort.MaxPrice | OrderSort.SortByDesc)) {
            return unsortedVariants.sort((a, b) => b.maxPrice - a.maxPrice)
        }

        if ((OrderSort.SortByAsc | OrderSort.SortByDepartureDate) === sortOptions) {
            return unsortedVariants.sort((a, b) => {
                    if (a.departureUtcDate !== undefined && b.departureUtcDate !== undefined) {
                        return (new Date(a.departureUtcDate)).getTime() - (new Date(b.departureUtcDate)).getTime();
                    }
                    return 0;
                }
            )
        }

        if ((OrderSort.SortByDesc | OrderSort.SortByDepartureDate) === sortOptions) {
            return unsortedVariants.sort((a, b) => {
                    if (a.departureUtcDate !== undefined && b.departureUtcDate !== undefined) {
                        return (new Date(b.departureUtcDate)).getTime() - (new Date(a.departureUtcDate)).getTime();
                    }
                    return 0;
                }
            )
        }

        if ((OrderSort.SortByAsc | OrderSort.SortByArrivedDate) === sortOptions) {
            return unsortedVariants.sort((a, b) => {
                    if (a.arrivedUtcDate !== undefined && b.arrivedUtcDate !== undefined) {
                        return (new Date(a.arrivedUtcDate)).getTime() - (new Date(b.arrivedUtcDate)).getTime();
                    }
                    return 0;
                }
            )
        }

        if ((OrderSort.SortByDesc | OrderSort.SortByArrivedDate) === sortOptions) {
            return unsortedVariants.sort((a, b) => {
                    if (a.arrivedUtcDate !== undefined && b.arrivedUtcDate !== undefined) {
                        return (new Date(b.arrivedUtcDate)).getTime() - (new Date(a.arrivedUtcDate)).getTime();
                    }
                    return 0;
                }
            )
        }

        return unsortedVariants
    }

    const createTripOrder = (trip: ITravelVariant, id?: number, paxes?: IPax[]): ITripOrder => {
        if (id === undefined) {
            id = Guid.newGuid().toNumber()
        }
        return {id: id, travelVariant: trip, selectedTariff: -1, paxes: [], status: "None"}
    }

    const bookTrips = async (trips: ITripOrder[]) => {
        let promises = new Array<Promise<ITripOrder>>();

        trips.forEach(trip => {

            const promise = new Promise<ITripOrder>(async (resolve, reject) => {

                let response: boolean;
                if (trip.travelVariant.ticketType === TicketType.AviaTicket) {
                    response = await bookFlight(trip.travelVariant, trip.selectedTariff, trip.paxes);
                } else {
                    response = await bookTrain(trip.travelVariant, trip.selectedTariff, trip.paxes);
                }

                if (response) {
                    trip.status = "Оk"
                    return resolve(trip)
                } else {
                    trip.status = "Fail"
                    return reject(trip)
                }
            })
            promises.push(promise);
        })

        //Run All request

        let tripResults = new Array<ITripOrder>()

        await Promise.allSettled(promises).then(results => results.forEach(x => {
                if (x.status === "fulfilled") {
                    tripResults.push(x.value)
                } else if (x.status === "rejected") {
                    console.warn("status rejected")
                }
            })
        );
    }

    //? Inner functions:
    function getDirRequests(trip: ITravelVariant, selectedTariff: number) {

        let dirRequests = new Array<IDirRequest>();

        if (trip.ticketType === TicketType.AviaTicket) {
            let aviaVariant = trip.travelVariant as IAviaVariant;

            const flights = aviaVariant.rejsInfo;
            const lastFlightIndex = flights.length - 1;

            const activeTariff = flights[lastFlightIndex].tarifs[selectedTariff];

            const depDate = new Date(trip.departureDate)
            let request: IDirRequest = {
                townFrom: flights[0].cityDep.split(" ")[0],
                townTo: flights[lastFlightIndex].cityArr.split(" ")[0],
                departDate: flights[0].dateDep,
                departTime: flights[0].dateDep,
                // departTime: new Date(1, 0, 1, depDate.getHours(), depDate.getMinutes()).toISOString(),
                //departDate: new Date(depDate.getFullYear(), depDate.getMonth(), depDate.getDate()).toISOString(),
                FareBasises: flights[lastFlightIndex].tarifs[selectedTariff].fareBasis,
                TripWay: {isAvia: true},
            }
            dirRequests.push(request)
        }
        return dirRequests;
    }

    function findTripResponse(travelVariant: ITravelVariant) {

        return orderState.tripResults.find(tr => {
            if ((tr.requestType === travelVariant.ticketType
                && tr.direction.townTo === travelVariant.direction?.townTo
                && tr.direction.townFrom === travelVariant.direction.townFrom
                && tr.direction.departDate === travelVariant.direction.departDate
            )
            ) return true;
            return false;
        })
    }

    function getPaxRequest(pax: IPax) {

        const countryCode = "RU"
        let currentPassport: IPassportRequest = {
            dob: pax.passportInfo.dob,
            expiry: pax.passportInfo.expiry,
            gender: pax.passportInfo.gender,
            isForeign: false,
            isInter: false,
            number: pax.passportInfo.number,
            series: pax.passportInfo.series,
        }

        const fioAsArr = pax.fio.split(" ")

        let paxRequest: IPaxRequest = {
            CountryCode: countryCode,
            PassportsInfo: [currentPassport],
            fio: pax.fio,
            first: fioAsArr[1],
            firstLatin: pax.first,
            last: fioAsArr[0],
            lastLatin: pax.last,
            middle: fioAsArr[2]
        }
        return paxRequest
    }

    function aviaVariantToTravelVariant(ticket: IAviaVariant, directionRequest: IDirectionRequest, id?: number): ITravelVariant {
        let travelVariant = initializeTravelVariant(id)
        travelVariant.travelVariant = ticket;
        travelVariant.ticketType = TicketType.AviaTicket;
        travelVariant.direction = directionRequest;

        if (ticket !== undefined && ticket.rejsInfo !== undefined) {
            const rejs = ticket.rejsInfo[ticket.rejsInfo.length - 1]
            const departureDate = new Date(ticket.rejsInfo[0].dateDep);
            const arrivedDate = new Date(rejs.dateArr);
            travelVariant.travelTime = getTravelTime(departureDate, arrivedDate);

            // let travelVariant = initializeTravelVariant()
            //departure Data
            travelVariant.departureTime = (departureDate?.getHours() < 10 ? "0" + departureDate.getHours() : departureDate.getHours())
                + ":"
                + (departureDate.getMinutes() < 10 ? "0" + departureDate.getMinutes() : departureDate.getMinutes());
            //travelVariant.departureDate = departureDate.getDate()+"-"+departureDate.getMonth()+"-"+departureDate.getFullYear()
            travelVariant.departureDate = departureDate.toDateString();
            travelVariant.departureCity = ticket.rejsInfo[0].cityDep;
            travelVariant.departurePlaceCode = ticket.rejsInfo[0].airCodeDep;
            travelVariant.departureUtcDate = departureDate;

            //arrivedData
            travelVariant.arrivedDate = arrivedDate.toDateString();
            travelVariant.arriveTime = (arrivedDate.getHours() < 10 ? "0" + arrivedDate.getHours() : arrivedDate.getHours())
                + ":"
                + (arrivedDate.getMinutes() < 10 ? "0" + arrivedDate.getMinutes() : arrivedDate.getMinutes());
            travelVariant.arrivedUtcDate = arrivedDate;
            travelVariant.arriveCity = rejs.cityArr;
            travelVariant.arrivePlaceCode = rejs.airCodeArr;
            travelVariant.minPrice = rejs.tarifs[0]?.price;
            travelVariant.maxPrice = rejs.tarifs[0]?.price;
            travelVariant.tariffCount = rejs.tarifs.length;
            //travelVariant.travelCompanyName = (ticket.rejsInfo[0].airCompanyName.split('-'))[0]
            travelVariant.vehicleName = ticket.rejsInfo[0].flightNumber
            travelVariant.estimated = ticket.rejsInfo[0].estimated
            travelVariant.transfersCount = ticket.rejsInfo.length - 1;
            ticket.rejsInfo.forEach(rejs => {
                travelVariant.travelCompanyOrVehicle.push((rejs.airCompanyName.split("-"))[0])
            })
            rejs.tarifs.forEach(tariff => {
                if (tariff.price < travelVariant.minPrice) travelVariant.minPrice = tariff.price;
                if (tariff.price > travelVariant.maxPrice) travelVariant.maxPrice = tariff.price;
            })
        }
        return travelVariant;
    }

    function trainVariantToTravelVariant(variant: ITrainVariant, directionRequest: IDirectionRequest, id?: number) {
        let travelVariant = initializeTravelVariant(id)
        travelVariant.travelVariant = variant;
        travelVariant.ticketType = TicketType.TrainTicket;
        travelVariant.direction = directionRequest;
        //travelVariant.minPrice = 100
        let firstCategory = variant.categoryPrice[0];
        //let lastCategory = variant.categoryPrice[variant.categoryPrice.length-1];

        travelVariant.travelCompanyOrVehicle = [variant.trainNum + (variant.trainTitle.length > 0 ? "/" + variant.trainTitle : "")];
        travelVariant.minPrice = firstCategory.price;
        travelVariant.maxPrice = firstCategory.price;
        travelVariant.travelTime = variant.travelTime;
        travelVariant.tariffCount = variant.categoryPrice.length

        variant.categoryPrice.forEach(c => {
            if (travelVariant.minPrice > c.price) {
                travelVariant.minPrice = c.price
            }
            if (travelVariant.maxPrice < c.price) {
                travelVariant.maxPrice = c.price
            }
        })

        //departure
        travelVariant.departureTime = variant.departureShortOn;
        travelVariant.departureDate = variant.departureAt;
        travelVariant.departureUtcDate = getDateFromTrainVariant(travelVariant.departureDate, travelVariant.departureTime)

        // travelVariant.departureDate = getDateFromTrainVariant(variant.departureAt,variant.departureShortOn);
        // travelVariant.departurePlaceCode = variant.codeFrom;
        travelVariant.departureCity = `${variant.cityFrom} (${variant.stationFrom})`;

        //arrived
        travelVariant.arriveTime = variant.arriveAtn;
        travelVariant.arrivedDate = variant.arriveShortOn;
        travelVariant.arrivedUtcDate = getDateFromTrainVariant(variant.arriveShortOn, variant.arriveAtn);
        // travelVariant.arrivedUtcDate= getDateFromTrainVariant(travelVariant.arrivedDate,travelVariant.arriveTime);
        // travelVariant.arrivedDate = getDateFromTrainVariant(variant.arriveShortOn,variant.arriveAtn);
        // travelVariant.arrivePlaceCode = variant.codeTo;
        travelVariant.arriveCity = `${variant.cityTo} (${variant.stationTo})`;// variant.cityTo;
        return travelVariant
    }

    function initializeTravelVariant(id?: number): ITravelVariant {
        if (id === undefined) {
            id = Guid.newGuid().toNumber()
        }
        return {
            id: id,
            direction: {townFrom: "", townTo: "", departDate: "", clientCode: "", notes: ""},
            ticketType: TicketType.None,
            activeTariff: -1,
            variantPrice: 0,
            minPrice: 0,
            maxPrice: 0,
            tariffCount: 0,
            travelCompanyOrVehicle: [],
            transfersCount: 0,
            vehicleName: "",
            estimated: "",

            departureTime: "0",
            departureDate: "",
            departureUtcDate: undefined,
            departureCity: "0",
            departurePlaceCode: "",

            arriveTime: "",
            arrivedDate: "",
            arrivedUtcDate: undefined,
            arriveCity: "",
            arrivePlaceCode: "",

            travelTime: "",
            isSelected: false
        }
    }

    function getEmtyFilterData() {
        let filterData: IFilterOptions = {
            airlines: new Array<string>(),
            trains: new Array<string>(),
            transfers: new Array<number>(),
            directions: [],
            ticketTypes: new Array<TicketType>()
        }
        return filterData;
    }

    return {
        searchCityNamesByPartName,
        toTravelVariant,
        searchTravelVariants,
        sortByParameter,
        getAllPaxes,
        bookFlight,
        applyAllFilters,
        getFiltersData,
        bookTrain,
        createTripOrder,
    };
}
