import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {CompanyApi} from "../api/CompanyApi";
import {IAllowedPerson} from "../interfaces/companyInfoInterfaces/IAllowedPerson";
import {IClientStructure} from "../interfaces/companyInfoInterfaces/IClientStructure";
import {ICLientSale} from '../interfaces/companyInfoInterfaces/IClientSale';
import {
    IClientDetailsRequest,
    IClientStructureRequest,
    ICompanyInfoRequest
} from "../interfaces/requestsInterfaces/reportRequests";
import {ICompany, ICompanyAgreement} from "../interfaces/companyInfoInterfaces/ICompanyInfo";
import {IClientDetail} from '../interfaces/companyInfoInterfaces/IClientDetail';
import {PERIOD_LAST_MONTH} from '../constants/ServiceCostants';
import {setIsActivatedAcc} from "./authSlice";

interface CompanyInfoState {
    isDataReceived:boolean;
    code: string;
    fullName: string;
    gkCode: string;
    gkName: string;
    inn: string;
    vatUse: boolean;
    agreements: ICompanyAgreement[];
    agreementNo: string;
    agreementFrom: string;
    agreementTill: string;
    address: string;
    postalAddress: string;
    deptSum: number;
    deptType: string;
    allwedPersons: IAllowedPerson[]
    clientStructureSales: IClientStructure[]
    clientStructureExchange: IClientStructure[]
    clientStructureRefound: IClientStructure[],
    clientSales: ICLientSale[],
    startCompanyInfoDate: string;
    endCompanyInfoDate: string;
    companyStatisticPeriod: string;
}

const initialState: CompanyInfoState = {
    isDataReceived:false,
    code: "",
    fullName: "",
    gkCode: "",
    gkName: "",
    inn: "",
    vatUse: false,
    agreements: [],
    agreementNo: "",
    agreementFrom: "",
    agreementTill: "",
    address: "",
    postalAddress: "",
    deptSum: 0,
    deptType: "",
    allwedPersons: [],
    clientStructureSales: [],
    clientStructureExchange: [],
    clientStructureRefound: [],
    clientSales: [],
    startCompanyInfoDate: "",
    endCompanyInfoDate: "",
    companyStatisticPeriod: PERIOD_LAST_MONTH
};

const {getClientDetails, searchClient, searchAllowedPersons, getClientStructure, getClientsSales} = CompanyApi();

export const getClientDetailsThunk = createAsyncThunk(
    "companyInfo/getCompanyInfo",
    async (request: IClientDetailsRequest, thunkAPI) => {
        const clientDetail = await getClientDetails(request);
        if (clientDetail == null) {
            const newResponce: IClientDetail = {
                fullname: "NotData",
                gkName: "NoGkCompany",
                deptSum: 0,
                deptType: "No data"
            }
            return newResponce
        }
        return clientDetail;
    }
);

export const searchClientsThunk = createAsyncThunk(
    "companyInfo/getClientDetail",
    async (request: ICompanyInfoRequest, thunkAPI) => {
        const response = await searchClient(request);
        return response;
    }
);

export const searchAllowedPepsonsThunk = createAsyncThunk("companyInfo/searchAllowedPepsons",
    async (request: ICompanyInfoRequest, thunkAPI) => {
        const response = await searchAllowedPersons(request)
        return response.length > 0 ? response : [];
    }
);

//need to update responce to remove copy/paste
export const getClientStructureSalesThunk = createAsyncThunk("companyInfo/getClientStructureSales",
    async (request: IClientStructureRequest, thunkAPI) => {
        const response = await getClientStructure(request)

        return response.length > 0 ? response : [];
    }
);

export const getClientStructureExchangeThunk = createAsyncThunk("companyInfo/getClientStructureExchange",
    async (request: IClientStructureRequest, thunkAPI) => {
        const response = await getClientStructure(request)
        return response.length > 0 ? response : [];
    }
);

export const getClientStructureRefoundThunk = createAsyncThunk("companyInfo/getClientStructureRefound",
    async (request: IClientStructureRequest, thunkAPI) => {
        const response = await getClientStructure(request)
        return response.length > 0 ? response : [];
    }
);
export const getClientsSalesThunk = createAsyncThunk("companyInfo/getClientsSales", async (request: ICompanyInfoRequest, thunkApi) => {
    const response = await getClientsSales(request);
    return response.length > 0 ? response : [];
})

export const companyInfoSlice = createSlice({
    name: "companyInfo",
    initialState,
    reducers: {
        setCode: (state, action: PayloadAction<string>) => {
            state.code = action.payload;
        },
        setFullName: (state, action: PayloadAction<string>) => {
            state.fullName = action.payload;
        },
        setGkKode: (state, action: PayloadAction<string>) => {
            state.gkCode = action.payload;
        },
        setGkName: (state, action: PayloadAction<string>) => {
            state.gkName = action.payload;
        },
        setInn: (state, action: PayloadAction<string>) => {
            state.inn = action.payload;
        },
        setVatUse: (state, action: PayloadAction<boolean>) => {
            state.vatUse = action.payload;
        },
        setAgreements: (state, action: PayloadAction<ICompanyAgreement[]>) => {
            state.agreements = action.payload;
        },
        setAgreementNo: (state, action: PayloadAction<string>) => {
            state.agreementNo = action.payload;
        },
        setAgreementFrom: (state, action: PayloadAction<string>) => {
            state.agreementFrom = action.payload;
        },
        setAgreementTill: (state, action: PayloadAction<string>) => {
            state.agreementTill = action.payload;
        },
        setAddress: (state, action: PayloadAction<string>) => {
            state.address = action.payload;
        },
        setPostalAddress: (state, action: PayloadAction<string>) => {
            state.postalAddress = action.payload;
        },
        setAllowedPerson: (state, action: PayloadAction<IAllowedPerson[]>) => {
            state.allwedPersons = action.payload
        },
        setClientStructureSales: (state, action: PayloadAction<IClientStructure[]>) => {
            state.clientStructureSales = action.payload
        },
        setClientStructureExchange: (state, action: PayloadAction<IClientStructure[]>) => {
            state.clientStructureExchange = action.payload
        },
        setClientStructureRefound: (state, action: PayloadAction<IClientStructure[]>) => {
            state.clientStructureRefound = action.payload
        },
        setClientSales: (state, action: PayloadAction<ICLientSale[]>) => {
            state.clientSales = action.payload
        },
        setStartCompanyInfoDate: (state, action: PayloadAction<string>) => {
            state.startCompanyInfoDate = action.payload
        },
        setEndCompanyInfoDate: (state, action: PayloadAction<string>) => {
            state.endCompanyInfoDate = action.payload
        },
        setCompanyStatisticPeriod: (state, action: PayloadAction<string>) => {
            state.companyStatisticPeriod = action.payload
        },
        setIsDataReceived:(state,action:PayloadAction<boolean>)=>{
            state.isDataReceived = action.payload
        },
        clearCompanyInfo: (state) => {
            state.isDataReceived =false;
            state.code = "";
            state.fullName = "";
            state.gkCode = "";
            state.gkName = "";
            state.inn = "";
            state.vatUse = false;
            state.agreements = [];
            state.agreementNo = "";
            state.agreementFrom = "";
            state.agreementTill = "";
            state.address = "";
            state.postalAddress = "";
            state.deptSum = 0;
            state.deptType = "";
            state.allwedPersons = [];
            state.clientStructureSales = [];
            state.clientStructureExchange = [];
            state.clientStructureRefound = [];
            state.clientSales = [];
            state.startCompanyInfoDate = "";
            state.endCompanyInfoDate = "";
            state.companyStatisticPeriod = PERIOD_LAST_MONTH
        }

    },
    extraReducers: (builder) => {
        builder
            // Get client details
            .addCase(
                getClientDetailsThunk.fulfilled,
                (state, action: PayloadAction<IClientDetail>) => {
                    state.deptSum = action.payload.deptSum;
                    state.deptType = action.payload.deptType;
                }
            )
            .addCase(getClientDetailsThunk.pending, (state, action) => {
            })
            .addCase(
                searchClientsThunk.fulfilled,
                (state, action: PayloadAction<ICompany>) => {
                    state.code = action.payload !== undefined ? action.payload.code : "";
                    state.fullName = action.payload !== undefined ? action.payload.fullName : "";
                    state.gkCode = action.payload !== undefined ? action.payload.gkCode : "";
                    state.gkName = action.payload !== undefined ? action.payload.gkName : "";
                    state.inn = action.payload !== undefined ? action.payload.inn : "";
                    state.agreementNo = action.payload !== undefined ? action.payload.agreements[0]?.code : "";
                    state.agreementFrom = action.payload !== undefined ? action.payload.agreements[0]?.dateFrom : "";
                    state.agreementTill = action.payload !== undefined ? action.payload.agreements[0]?.dateTill : "";
                    state.address = action.payload !== undefined ? action.payload.address : "";
                    state.postalAddress = action.payload !== undefined ? action.payload.postalAddress : "";
                }
            )
            .addCase(
                searchAllowedPepsonsThunk.fulfilled, (state, action: PayloadAction<IAllowedPerson[]>) => {
                    state.allwedPersons = action.payload
                })
            .addCase(
                getClientStructureSalesThunk.fulfilled, (state, action: PayloadAction<IClientStructure[]>) => {
                    state.clientStructureSales = action.payload
                })
            .addCase(getClientStructureExchangeThunk.fulfilled, (state, action: PayloadAction<IClientStructure[]>) => {
                state.clientStructureExchange = action.payload
            })
            .addCase(getClientStructureRefoundThunk.fulfilled, (state, action: PayloadAction<IClientStructure[]>) => {
                state.clientStructureRefound = action.payload
            })
            .addCase(getClientsSalesThunk.fulfilled, (state, action: PayloadAction<ICLientSale[]>) => {
                state.clientSales = action.payload
            })
            .addCase(
                getClientStructureSalesThunk.pending, (state, action) => {
                }
            )
    },
});

export const {
    setCode,
    setFullName,
    setGkKode,
    setGkName,
    setInn,
    setVatUse,
    setAgreements,
    setAgreementNo,
    setAgreementFrom,
    setAgreementTill,
    setAddress,
    setPostalAddress,
    setClientStructureSales,
    setClientStructureExchange,
    setClientStructureRefound,
    setStartCompanyInfoDate,
    setEndCompanyInfoDate,
    setCompanyStatisticPeriod,
    clearCompanyInfo,
    setIsDataReceived
} = companyInfoSlice.actions;

export const companyInfoState = (state: RootState): CompanyInfoState => {
    return {
        isDataReceived:state.companyInfo.isDataReceived,
        code: state.companyInfo.code,
        fullName: state.companyInfo.fullName,
        gkCode: state.companyInfo.gkCode,
        gkName: state.companyInfo.gkName,
        inn: state.companyInfo.inn,
        vatUse: state.companyInfo.vatUse,
        agreementNo: state.companyInfo.agreementNo,
        agreementFrom: state.companyInfo.agreementFrom,
        agreementTill: state.companyInfo.agreementTill,
        agreements: state.companyInfo.agreements,
        address: state.companyInfo.address,
        postalAddress: state.companyInfo.postalAddress,
        deptSum: state.companyInfo.deptSum,
        deptType: state.companyInfo.deptType,
        allwedPersons: state.companyInfo.allwedPersons,
        clientStructureSales: state.companyInfo.clientStructureSales,
        clientStructureExchange: state.companyInfo.clientStructureExchange,
        clientStructureRefound: state.companyInfo.clientStructureRefound,
        clientSales: state.companyInfo.clientSales,
        startCompanyInfoDate: state.companyInfo.startCompanyInfoDate,
        endCompanyInfoDate: state.companyInfo.endCompanyInfoDate,
        companyStatisticPeriod: state.companyInfo.companyStatisticPeriod
    };
};

export default companyInfoSlice.reducer;
