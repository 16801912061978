import {useCookies} from "react-cookie";
import {AxiosError} from "axios";
import {axiosInstance} from "../api/AxiosInstance";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {JwtPayload, jwtDecode} from "jwt-decode";
import {ValidationErrors} from "fluentvalidation-ts";
import {
    ROUTE_ADD_USER,
} from "../constants/routeConstants/ApiRouteConstants";
import {
    setAccessToken,
    setCurrentClientCode,
    setEmail,
    setGkClientCodes,
    setGkCode,
    setIsUserValid,
    setFullName,
    setFirstName,
    setLastName,
    setPatronymic,
    setUserRoles,
    setUserRole,
    setExpiredAt,
    setGkCompanies,
    setUserId,
    setIsSupportUser,
    setSupportUserCode,
    setIsActivatedAcc,
    setCurrentCompany,
} from "../redux/authSlice";
import {resetReports} from "../redux/reportsSlice";
import {AuthApi} from "../api/AuthApi";
import {ISignupRequests} from "../Requests/ISignupRequest";
import {IRefreshTokenRequest} from "../Requests/IRefreshTokenRequest";
import {ACCESS_TOKEN} from "../constants/FieldsConstants";
import {IChangePasswordRequest} from "../Requests/IChangePasswordRequest";
import {ILoginRequest} from "../Requests/ILoginRequest";
import {ITokenClaims} from "../interfaces/AuthInterfaces";
import {IGetUserRolesRequest} from "../Requests/IGetUserRolesRequest";
import {DataConvertService} from "./DataConvertService";
import {CompanyService} from "./CompanyService";
import {SignupRequestValidator} from "../validators/SignupRequestValidator";
import {ICompany} from "../interfaces/companyInfoInterfaces/ICompanyInfo";
import {useNavigate} from "react-router-dom";


export function AuthService() {

    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);

    const authState = useAppSelector(root => root.auth);

    const dispatch = useAppDispatch();

    const authApi = AuthApi();

    const companyService = CompanyService();

    const {objHasAnyPropertyValue} = DataConvertService();

    const signupValidator = new SignupRequestValidator();
    const navigate = useNavigate();


    /**
     * Method for login
     * @param {ILoginRequest} data - data for login
     * @return {Promise<ILoginResponse|null>} True in case of successful login. False otherwise.
     */
    const login = async (data: ILoginRequest): Promise<boolean> => {
        try {

            const response = await authApi.login(data);

            if (response != null) {

                const tokenClaims = getTokenClaims(response.jwtToken);

                const firstName = tokenClaims.firstName ?? "";
                const lastName = tokenClaims.lastName ?? "";
                const patronymic = tokenClaims.patronymic ?? "";
                const fullName = [firstName, lastName, patronymic].join(' ');

                dispatch(setUserId(tokenClaims.id));
                dispatch(setEmail(tokenClaims.email ?? ""));
                dispatch(setFullName(fullName));
                dispatch(setFirstName(tokenClaims.firstName ?? ""));
                dispatch(setLastName(tokenClaims.lastName ?? ""));
                dispatch(setPatronymic(tokenClaims.patronymic ?? ""));
                dispatch(setIsSupportUser((tokenClaims.isSupportUser ?? "false").toLowerCase() === "true"));
                dispatch(setSupportUserCode(tokenClaims.supportUserCode ?? ""));
                dispatch(setAccessToken(response.jwtToken));
                dispatch(setExpiredAt(tokenClaims.exp ?? 0));
                dispatch(setIsActivatedAcc(tokenClaims.isActivatedAcc.toLowerCase()==="true"))

                if (tokenClaims.gkClientsCodes !== undefined) {
                    dispatch(setGkClientCodes(tokenClaims.gkClientsCodes));
                }

                //check clients code
                const defaultClientCode = Array.isArray(tokenClaims.gkClientsCodes) ? tokenClaims.gkClientsCodes[0] : "";

                dispatch(setCurrentClientCode(defaultClientCode));

                dispatch(setUserRoles(tokenClaims.roles));

                let userRole = tokenClaims.roles[defaultClientCode]?.find((x, i) => i == 0) ?? "";

                dispatch(setUserRole(userRole));

                //TODO: need update Auth api; HardCode!!!
                dispatch(setIsUserValid(true));

                dispatch(setGkCode(tokenClaims.gkCode ?? ""))

                setCookie(ACCESS_TOKEN, response.jwtToken, {
                    //secure: true,
                    //sameSite: "strict",
                    //httpOnly: true,
                });

                dispatch(resetReports());

                // TODO: improve (needs active company, but without many background queries)
                await companyService.getClientInfo(tokenClaims.gkClientsCodes ?? [], defaultClientCode, response.jwtToken);

                //await companyService.getClientInfoInBackground(tokenClaims.gkClientsCodes ?? [], defaultClientCode, response.jwtToken);

                return true;
            }
            // redirect("/lk/cabinet");
        } catch (ex: unknown) {
            const err = ex as AxiosError;
            dispatch(setIsUserValid(false));
        }
        return false;
    };

    /**
     * Method for password recovery
     * @param {string} email - user email address
     * @return {Promise<boolean>} true if password recovery was successful, false otherwise.
     */
    const recoveryPassword = async (email: string): Promise<boolean> => {
        try {
            const response = await authApi.recoveryPassword(email);
            return response === true;
        }
        catch (ex: unknown) { }
        return false;
    };

    /**
     * Method for change password
     * @param {IChangePasswordRequest} data - data for changing password
     * @return {Promise<boolean>} true if password was changed successful, false otherwise.
     */
    const changePassword = async (data: IChangePasswordRequest): Promise<boolean> => {
        try {
            const response = await authApi.changePassword(data);
            return response != null;
        }
        catch (ex: unknown) { }
        return false;
    };

    /**
     * Method for token refresh
     * @param {IRefreshTokenRequest} data - data for token refresh
     * @return {Promise<boolean>} True if token was refreshed successful, False otherwise.
     */
    const refreshToken = async (data: IRefreshTokenRequest): Promise<boolean> => {
        try {
            const response = await authApi.refreshToken(data);

            if (response != null) {
                const token = getTokenClaims(response.jwtToken);

                dispatch(setAccessToken(response.jwtToken));
                dispatch(setExpiredAt(token.exp ?? 0));

                setCookie(ACCESS_TOKEN, response.jwtToken, {
                    //secure: true,
                    //sameSite: "strict",
                    //httpOnly: true,
                });

                return true;
            }
        }
        catch (ex: unknown) { }
        return false;
    }

    /**
     * Method for revoke token
     * @return {Promise<boolean>} true if token was revoked successful, false otherwise.
     */
    const revokeToken = async (): Promise<boolean> => {
        try {
            const response = await authApi.revokeToken(authState.accessToken);
            if (response) {
                return true;
            }
        }
        catch (ex: unknown) { }
        return false;
    }

    /**
     * Method for logout
     */
    const logout = async (): Promise<void> => {
        try {
            await authApi.logout(authState.accessToken);
        }
        catch (ex: unknown) { }

        dispatch(setIsActivatedAcc(false));
        dispatch(setUserId(""));
        dispatch(setEmail(""));
        dispatch(setFullName(""));
        dispatch(setFirstName(""));
        dispatch(setLastName(""));
        dispatch(setPatronymic(""));
        dispatch(setIsSupportUser(false));
        dispatch(setSupportUserCode(""));
        dispatch(setAccessToken(""));
        dispatch(setExpiredAt(0));
        dispatch(setGkCompanies([]));
        dispatch(setGkClientCodes([]));
        dispatch(setCurrentClientCode(""));
        dispatch(setUserRoles({}));
        dispatch(setUserRole(""));
        dispatch(setIsUserValid(false));
        dispatch(setCurrentCompany({} as ICompany));

        removeCookie(ACCESS_TOKEN, {path: '/'});

        dispatch(resetReports());
        navigate("/sign-in");
    };

    /**
     * Method for obtaining user activity status
     * @param {string} email - user email address
     * @param {string} token - access token
     * @return {Promise<boolean>} true if user is active, false otherwise.
     */
    const isUserActive = async (email: string, token: string): Promise<boolean> => {
        try {
            const response = await authApi.isUserActive(email, token);
            if (response) {
                return true;
            }
        }
        catch (ex: unknown) { }
        return false;
    }

    const getTokenClaims = (token: string): ITokenClaims => {

        const tokenPayload = jwtDecode<JwtPayload>(token);
        const tokenClaims =
            Object.fromEntries(Object.entries(tokenPayload).map(([k, v], i) => {
                let cleanKey = k.substring(k.lastIndexOf("/") + 1);
                return [cleanKey, cleanKey === "role" ? JSON.parse(v) : v];
            }));
        const claimNames =  Object.getOwnPropertyNames(tokenClaims);
        console.log(`Get tokenClaims  names ${claimNames}`);

        const roleDictionary = tokenClaims.role;
        const clientCodes = roleDictionary != null ? Object.keys(roleDictionary) : [];

        return {
            id: (tokenPayload as any).id,
            iss: tokenPayload.iss,
            sub: tokenPayload.sub,
            aud: tokenPayload.aud,
            exp: tokenPayload.exp,
            nbf: tokenPayload.nbf,
            iat: (tokenPayload.iat ?? 0).toString(),
            jti: tokenPayload.jti,
            email: tokenClaims["email"],
            firstName: tokenClaims["User FirstName"],
            lastName: tokenClaims["User LastName"],
            isActivatedAcc:tokenClaims["Activate account"],
            patronymic: tokenClaims["User Patronymic"],
            isSupportUser: tokenClaims["IsSupportUser"],
            supportUserCode: tokenClaims["SupportUserCode"],
            roles: roleDictionary,
            gkClientsCodes: clientCodes,
            gkCode: tokenClaims.GkCode,
        }
    }

    const getUserCompanyRoles = async (email: string, token: string) => {
        const request: IGetUserRolesRequest = {
            email: email,
            token: token
        };
        return await authApi.getUserRoles(request);
    }

    // Signup function for export
    const signupUser = async (request: ISignupRequests) => {
        try {
            const response = await axiosInstance.post<ISignupRequests>(
                process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ADD_USER,
                request
            );

            if (response.status === 200) {
                return true;
            }

        } catch (error) {

        }
        return false;
    };

    const validateSignupRequest = (
        validateErrorHandler: (
            value: ValidationErrors<ISignupRequests> | undefined
        ) => void,
        passwordErrorHandler: (value: string) => void,
        request: ISignupRequests
    ): boolean => {
        clearSignupValidationError(validateErrorHandler);
        const validationResult = signupValidator.validate({
            email: request.email,
            password: request.password,
            passwordConfirm: request.passwordConfirm,
            firstName: request.firstName,
            lastName: request.lastName,
            patronymic: request.patronymic,
            position: request.position,
            shortCompanyName: request.shortCompanyName,
            fullCompanyName: request.fullCompanyName,
            companyOwner: request.companyOwner,
            legalAddress: request.legalAddress,
            mailingAddress: request.mailingAddress,
            deliveryAddress: request.deliveryAddress,
            mainContract: request.mainContract,
            inn: request.inn,
            hasInnForm: request.hasInnForm,
            ogrn: request.ogrn,
            hasOgrnForm: request.hasOgrnForm,
        });

        //Check fluentvalidator errors
        if (objHasAnyPropertyValue(validationResult)) {
            validateErrorHandler(validationResult);
            return false;
        }

        return true;
    };

    //own function
    const clearSignupValidationError = (
        hanler: (value: ValidationErrors<ISignupRequests> | undefined) => void
    ) => {
        hanler({
            email: "",
            password: "",
            passwordConfirm: "",
            firstName: "",
            lastName: "",
            patronymic: "",
            position: "",
            shortCompanyName: "",
            fullCompanyName: "",
            companyOwner: "",
            legalAddress: "",
            mailingAddress: "",
            deliveryAddress: "",
            mainContract: "",
            inn: "",
            hasInnForm: "",
            ogrn: "",
            hasOgrnForm: "",
        });
    };

    return {
        login,
        recoveryPassword,
        changePassword,
        refreshToken,
        logout,
        isUserActive,

        signupUser,
        validateSignupRequest,
        getUserCompanyRoles,
    };
}
