import {useState} from "react";
import "../../../scss/extends.scss";
import "./style.scss";

type InputTextWithPopoverComponentProps = {
    title?: string;
    value: string;
    placeholder: string;
    popoverItems: string[];
    setValue: (value: string) => void;
    minSearchLength?:number
    isSearching?:boolean
    runItemsSearch: (partInputValue: string) => void;
    clearPopoverItemsResult: () => void;
};

export function InputTextWithPopoverComponent(props: InputTextWithPopoverComponentProps) {


    const [popOverItems, setPopOverItems] = useState<string[]>(props.popoverItems);

    const inputHandler = (value: string) => {
        console.log("Input With popOver run search")
        props.setValue(value);
        if (value.length === 3 && props.popoverItems.length === 0) {
            console.log("popover start search")
            props.runItemsSearch(value);
        }
        if (value.length === 1 && props.popoverItems.length > 0) {
            props.clearPopoverItemsResult();
        }
        if (value.length >= 3) {
            setPopOverItems(
                props.popoverItems.filter((city) => city.toLowerCase().includes(value.toLowerCase()))
            );
        }
    };
    const selectHandler = (itemValue: string) => {
        props.setValue(itemValue);
    };

    return (
        <div className="dropdown-input-text-container">

            {/*<label className="sr-only align-self-center">{props.title}asdf </label>*/}

            <div className="dropdown-input-text-item">
                <input
                    id={"popover-trigger-focus" + props.title}
                    type="text"
                    style={{border: "none"}}
                    className="form-control common-input "
                    placeholder={props.placeholder}
                    autoComplete="off"
                    value={props.value}
                    onChange={(e) => {
                        inputHandler(e.target.value);
                    }}
                />
                {props.popoverItems &&
                    <div className="dropdown-input-text-container-content"
                         style={{display: props.popoverItems.length > 0 ? "" : "none"}}
                    >
                        {/*{props.popoverItems.map((item, index) => (<li key={index} onClick={() => selectHandler(item)}*/}
                        {/*>{item}</li>))}*/}

                        {popOverItems.map((item, index) => (<li key={index} onClick={() => selectHandler(item)}
                        >{item}</li>))}

                    </div>}
                {props.value !== "" ? <div className="clear-input" onClick={() => {
                    props.setValue("")
                }}>&#x2715;</div> : <div className="clear-input">&nbsp;&nbsp;&nbsp;</div>}
            </div>

        </div>
    );
}
