import {RefsApi} from "../api/RefsApi";


export function RefsService() {

  const refsApi = RefsApi();

  const getCities = async (text: string) => {
    try {
      const response = await refsApi.getCities(text);
      return response?.data;
    } catch (error) {
      return [];
    }
  }

  return {
    getCities
  };
}
