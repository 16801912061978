import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    ModalContext,
    SEARCH_TICKETS_MODAL_ID,
} from "../../../commonComponents/modal/ModalContext";
import {IAviaTariffInfo, IAviaVariant} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {ITrainTariffInfo, ITrainVariant} from "../../../../interfaces/ITrainVariant";
import {MdModal} from "../../../../components/md-modal";
import {MultiSearchFlightsComponent} from "./multiSearchFlightsComponent";
import "./style.scss"
import {ICompany} from "../../../../interfaces/companyInfoInterfaces/ICompanyInfo";


interface IMultiSearchTicketsModal {
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>,
    selectedCompany: ICompany | undefined,
    submitHandler: (flight: IAviaVariant, tariff: IAviaTariffInfo, searchResponse: any) => void;
    submitHandler2: (train: ITrainVariant, tariff: ITrainTariffInfo, searchResponse: any) => void;
}


export function MultiSearchTicketsModal(props: IMultiSearchTicketsModal) {

    const {
        opened,
        setOpened,
        selectedCompany,
        submitHandler,
        submitHandler2
    } = props;

    const {t} = useTranslation();

    const {isOpen, open, close} = useContext(ModalContext);

    //const authState = useAppSelector((state) => state.auth);

    const [activeTab, setActiveTab] = useState(0);


    const onModalOpen = () => {
        if(!opened) {
            setOpened(true);
        }
        resetForm();
    }

    const onModalClose = () => {
        if(opened) {
            setOpened(false);
        }
        resetForm();
    }


    const selectFlight = (flight: IAviaVariant, tariff: IAviaTariffInfo, searchResponse: any) => {
        submitHandler(flight, tariff, searchResponse);
        setOpened(false);
    }


    const selectTrain = (train: ITrainVariant, tariff: ITrainTariffInfo, searchResponse: any) => {
        submitHandler2(train, tariff, searchResponse);
        setOpened(false);
    }


    const resetForm = () => { }


    useEffect(() => {
        if(isOpen(SEARCH_TICKETS_MODAL_ID) && !opened) {
            close(SEARCH_TICKETS_MODAL_ID);
        }
        else if(!isOpen(SEARCH_TICKETS_MODAL_ID) && opened) {
            open(SEARCH_TICKETS_MODAL_ID);
        }
    }, [opened]);


    useEffect(() => { }, []);


    return (
        <MdModal
            id={SEARCH_TICKETS_MODAL_ID}
            title={t("bookingPage.ticketSearch")}
            openHandler={onModalOpen}
            closeHandler={onModalClose}
            closeOnClickOutside={false}>

            <div className="search-tickets-form">
                <MultiSearchFlightsComponent
                    selectedCompany={selectedCompany}
                    selectFlightHandler={selectFlight}
                />
            </div>

            {/*<MdTabs*/}
            {/*    activeIndex={activeTab}*/}
            {/*    onTabChange={(index) => setActiveTab(index)}*/}
            {/*    tabs={[*/}
            {/*        {*/}
            {/*            header: (*/}
            {/*                <span className="tab-header">*/}
            {/*                    Авиа*/}
            {/*                </span>*/}
            {/*            ),*/}
            {/*            content: (*/}
            {/*                <MultiSearchFlightsComponent*/}
            {/*                    selectedCompany={selectedCompany}*/}
            {/*                    selectFlightHandler={selectFlight}*/}
            {/*                />*/}
            {/*            )*/}
            {/*        },*/}
            {/*        {*/}
            {/*            header: (*/}
            {/*                <span className="tab-header">*/}
            {/*                    ЖД*/}
            {/*                </span>*/}
            {/*            ),*/}
            {/*            content: (*/}
            {/*                <MultiSearchTrainsComponent*/}
            {/*                    selectedCompany={selectedCompany}*/}
            {/*                    selectTrainHandler={selectTrain}*/}
            {/*                />*/}
            {/*            )*/}
            {/*        },*/}
            {/*    ]}*/}
            {/*    style={{*/}
            {/*        "root": {*/}
            {/*            minHeight: "600px",*/}
            {/*            minWidth: "400px",*/}
            {/*            padding: "0px 20px 20px 20px"*/}
            {/*        }*/}
            {/*    }}*/}
            {/*/>*/}

        </MdModal>
    );
}