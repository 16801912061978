import React, {CSSProperties, SetStateAction, useEffect, useState} from "react";
import {ArrowsUpDownIcon} from "../../icons/ArrowsUpDownIcon";
import {Utils} from "../../utils/utils";
import './style.scss'


interface IMdSelectProps {
    style?: { [selector: string]: CSSProperties };
    isEditable?: boolean;
    text?: string;
    setText?: (text: string) => void;// React.Dispatch<SetStateAction<string>>;
    itemSource: Array<any>;
    displayMemberPath?: string;
    itemTemplate?: (data: any) => React.ReactNode;
    selectedItem?: any;
    setSelectedItem?: React.Dispatch<SetStateAction<any>>;
    disabled?: boolean;
    onTextChange?: (value: any) => void;
    onChange?: (value: any) => void;
    onFocus?: () => void;
}

export function MdSelect(props: IMdSelectProps) {
    let root: HTMLElement | null = null;


    const {
        setText,
        itemSource,
        selectedItem,
        setSelectedItem,
        itemTemplate,
        displayMemberPath,
        onTextChange,
        onChange,
        onFocus,
        disabled,
    } = props;

    const style = props.style ?? {};
    const isEditable = props.isEditable ?? false;
    const text = props.text ?? "";


    const [isFocused, setIsFocused] = useState(false);
    const [isOpened, setIsOpened] = useState(false);

    const focusHandler = (event: React.FocusEvent) => {
        setIsFocused(true);
        setIsOpened(true);
        if(onFocus != null) {
            onFocus();
        }
    }

    const blurHandler = (event: React.FocusEvent) => {
        setIsFocused(false);
        setIsOpened(false);
    }

    const toggleHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked && !isOpened) {
            setIsOpened(true);
        }
        else if(!event.target.checked && isOpened) {
            setIsOpened(false);
        }
    }

    const textChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!isOpened) {
            setIsOpened(true);
        }
        if(setText != null) {
            setText(event.target.value);
        }
        if(onTextChange != null) {
            onTextChange(event.target.value);
        }
    }

    const itemClickHandler = (event: React.MouseEvent, item: any) => {
        let value = (displayMemberPath != null ? item[displayMemberPath] : item);

        if(isEditable && setText != null) {
            setText(value);
        }
        else if(!isEditable && setSelectedItem != null) {
            setSelectedItem(item);
        }

        if(onChange != null) {
            onChange(value);
        }

        setIsOpened(false);
    }


    useEffect(() => { }, [isFocused, isOpened]);

    return (
        <div className={("md-select" + (disabled ? " --disabled" : ""))}
            style={style["root"]}>
            {
                isEditable
                ? (
                    <input className="md-select-text-field"
                        style={style["text-field"]}
                        type="text"
                        value={text}
                        onFocus={focusHandler}
                        onBlur={blurHandler}
                        onChange={textChangeHandler}
                    />
                )
                : (
                    <>
                        <input className="options-view-button"
                            type="checkbox"
                            checked={isOpened}
                            onBlur={blurHandler}
                            onChange={toggleHandler}
                        />
                        <div className="toggle-button"
                            style={style["toggle-button"]}>
                            <div className="selected-value">
                                {
                                    selectedItem != null
                                        ? (
                                            (() => {
                                                if (itemTemplate != null) {
                                                    return itemTemplate(selectedItem)
                                                } else if (displayMemberPath != null) {
                                                    return selectedItem[displayMemberPath]
                                                } else {
                                                    return selectedItem
                                                }
                                            })()
                                        )
                                        : (
                                            "Выберите значение"
                                        )
                                }
                            </div>
                            <div className="toggle-button-icon"
                                style={style["toggle-button-icon"]}>
                                <ArrowsUpDownIcon

                                />
                            </div>
                        </div>
                    </>
                )
            }

            <div className={"options" + (isOpened ? " --opened" : "") + (itemSource.length == 0 ? " --empty" : "")}>
                {
                    itemSource.map((item, itemIndex) => {
                        return (
                            <div key={itemIndex}
                                 className={"option" + (
                             selectedItem != null
                                 ? (Utils.stringHash(JSON.stringify(selectedItem)) == Utils.stringHash(JSON.stringify(item))
                                     ? " --selected"
                                     : "")
                                 : "")
                         }
                         onMouseDown={(e) => e.preventDefault()}
                         onClick={(e) => itemClickHandler(e, item)}
                            >
                                <input type="hidden" />
                                <span className="label">
                                    {
                                        (() => {
                                            if (itemTemplate != null) {
                                                return itemTemplate(item)
                                            } else if (displayMemberPath != null) {
                                                return  item[displayMemberPath]
                                            } else {
                                                return item.toString()
                                            }
                                        })()
                                    }
                                </span>
                            </div>
                    )
                    })
                }
            </div>
        </div>
    )
}