import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAppSelector} from "../../../redux/hooks";
import {t} from "i18next";
import {MdTextField} from "../../../components/md-text-field";
import {MdButton} from "../../../components/md-button";
import {ErrorMessage} from "../../commonComponents/ErrorComponents/errorMessage/ErrorMessage";
import {AuthService} from "../../../services/AuthService";
import {CircleSpinnerIcon} from "../../../icons/CircleSpinnerIcon";
import "./style.scss";


export function ChangePasswordPage() {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const authState = useAppSelector(root => root.auth);

    const params = Object.fromEntries(searchParams.entries());

    const [email, setEmail] = useState(params.email);
    const [token, setToken] = useState(params.token);

    const [checkPending, setCheckPending] = useState(true);

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("");

    const [passError, setPassError] = useState("");
    const [confirmPassError, setConfirmPassError] = useState("");

    const [submitPending, setSubmitPending] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const [submitCompleted, setSubmitCompleted] = useState(false);


    if (email === undefined || email === "" || token === undefined || token === "") {
        navigate('/404');
    }


    const authService = AuthService();


    const checkUser = async () => {
        const isUserActive = await authService.isUserActive(email, token);
        if (isUserActive) {
            if (authState.isValidUser) {
                navigate("/cabinet/profile");
            } else {
                navigate("/sign-in?email=" + email);
            }
        }
        setCheckPending(false);
    };


    const submitHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        setSubmitError(false);
        setSubmitPending(true);

        if (password.length <= 7) {
            setPassError(t("recoveryPage.pass-too-short"));
            setSubmitPending(false);
            return;
        }

        if (password !== confirmPassword) {
            setConfirmPassError(t("recoveryPage.confirm-err"));
            setSubmitPending(false);
            return;
        }

        if (password === undefined || confirmPassError === undefined) {
            setSubmitPending(false);
            return;
        }

        const result = await authService.changePassword({
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            link: token
        })

        if (result) {
            setSubmitCompleted(true);
        } else {
            setSubmitError(true);
            setSubmitPending(false);
        }
    }


    useEffect(() => {
        checkUser();
    }, []);


    return (
        <>
            {
                checkPending
                    ? (
                        <div className="user-checking-message">
                            <CircleSpinnerIcon
                                width="18px"
                                height="18px"
                                style={{
                                    "circle": {
                                        stroke: "#000000"
                                    }
                                }}
                            />
                            <div>
                                Проверка данных
                            </div>
                        </div>
                    )
                    : (
                        !submitCompleted
                            ? (
                                <form className="change-password-form">
                                    <div className="change-password-form-header">Назначение пароля</div>

                                    <div className="change-password-form-body">

                                        <div className="change-password-form-error-message">
                                            {submitError && <ErrorMessage error={t("recoveryPage.token-err")}/>}
                                        </div>

                                        <MdTextField
                                            type="password"
                                            label={t("password")}
                                            value={password}
                                            invalid={passError.length !== 0}
                                            errorMessage={passError}
                                            disabled={submitPending}
                                            onChange={(event) => {
                                                setPassError("");
                                                setPassword(event.target.value);
                                            }}
                                        />

                                        <MdTextField
                                            type="password"
                                            label={t("commonText.confirmPassword")}
                                            value={confirmPassword}
                                            invalid={confirmPassError.length !== 0}
                                            errorMessage={confirmPassError}
                                            disabled={submitPending}
                                            onChange={(event) => {
                                                setConfirmPassError("");
                                                setConfirmPassword(event.target.value);
                                            }}
                                        />

                                        <MdButton
                                            type="submit"
                                            style={{
                                                "button": {
                                                    width: "100%",
                                                    height: "40px",
                                                    fontSize: "14px",
                                                    backgroundColor: "#3E5CB8",
                                                    color: "#FFFFFF",
                                                }
                                            }}
                                            disabled={submitPending}
                                            onClick={(event) => submitHandler(event)}
                                        >

                                            {
                                                submitPending
                                                    ? (
                                                        <CircleSpinnerIcon
                                                            width="18px"
                                                            height="18px"
                                                            style={{
                                                                "circle": {
                                                                    stroke: "#FFFFFF"
                                                                }
                                                            }}
                                                        />
                                                    )
                                                    : (
                                                        "Применить"
                                                    )
                                            }
                                        </MdButton>

                                    </div>
                                </form>
                            )
                            : (
                                <div className="change-password-complete-message">
                                    <div>
                                        Назначение пароля успешно завершено.
                                    </div>
                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                margin: "auto",
                                                marginTop: "10px",
                                                padding: "0 20px",
                                                height: "40px",
                                                fontSize: "12px",
                                                backgroundColor: "#3E5CB8",
                                                color: "#FFFFFF",
                                            }
                                        }}
                                        onClick={(event) => navigate("/")}
                                    >
                                        На главную
                                    </MdButton>
                                </div>
                            )
                    )
            }
        </>
    );
}
