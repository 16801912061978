import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {useCookies} from "react-cookie";
import {ACCESS_TOKEN} from "../constants/FieldsConstants";
import {CustomerApi} from "../api/CustomerApi";
import {
    IAddCustomerEmployeesRequest, ICompanyRole, IGetCustomerEmployeeRolesRequest,
    IGetCustomerEmployeesRequest
} from "../interfaces/requestsInterfaces/reportRequests";


export function EmployeesService() {
    const {getCustomerBuhDocs,} = CustomerApi();

    const authState = useAppSelector((state) => state.auth);
    const reportsState = useAppSelector((state) => state.reports);

    const [cookies] = useCookies([ACCESS_TOKEN]);
    const dispatch = useAppDispatch();

    const {getCustomerEmployees, addCustomerEmployees, getUserRoles, updateCustomerEmployee} = CustomerApi();

    const getEmployees = async (companyCode: string) => {
        const request: IGetCustomerEmployeesRequest = {
            companyCode: companyCode,
            token: cookies[ACCESS_TOKEN]
        }
        try {
            const response = await getCustomerEmployees(
                request
            );
            return response.data ?? [];

        } catch (error) {
            return []
        }
    };

    const getRolesForEmployee = async (companyCode: string, email: string) => {
        const request: IGetCustomerEmployeeRolesRequest = {
            code: companyCode,
            email: email,
            token: cookies[ACCESS_TOKEN]
        }
        try {
            const response = await getUserRoles(
                request
            );

            if (response.status === 200) {
                return response.data;
            }

            return response.data;

        } catch (error) {
            return [];
        }
    }

    const addEmployee = async (
        firstName: string,
        lastName: string,
        patronymic: string,
        position: string,
        roles: ICompanyRole[],
        dateOfBurn: string,
        email: string,
        gender: boolean
    ) => {
        const request: IAddCustomerEmployeesRequest = {
            email: email,
            gender: gender,
            firstName: firstName,
            lastName: lastName,
            patronymic: patronymic,
            position: position,
            dateOfBurn: dateOfBurn,
            companiesRoles: roles,
            token: cookies[ACCESS_TOKEN]
        }
        try {
            const response = await addCustomerEmployees(
                request
            );
            if (response != null && response.status === 200) {
                //dispatch(setCustomerEmployees(response.data))
            }
            return response;

        } catch (error) {
            return null
        }
    };

    const updateEmployee = async (
        firstName: string,
        lastName: string,
        patronymic: string,
        position: string,
        roles: ICompanyRole[],
        dateOfBurn: string,
        email: string,
        gender: boolean
    ) => {
        const request: IAddCustomerEmployeesRequest = {
            email: email,
            gender: gender,
            firstName: firstName,
            lastName: lastName,
            patronymic: patronymic,
            position: position,
            dateOfBurn: dateOfBurn,
            companiesRoles: roles,
            token: cookies[ACCESS_TOKEN]
        }
        try {
            const response = await updateCustomerEmployee(
                request
            );
            if (response != null && response.status === 200) {
                //dispatch(setCustomerEmployees(response.data))
            }
            return response;

        } catch (error) {
            return null
        }
    };

    return {
        getEmployees,
        addEmployee,
        getRolesForEmployee,
        updateEmployee
    };
}
