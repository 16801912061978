import {ICompanyResponse} from "../../../../responces/ICompanyResponse";
import "./style.scss";
import {MdButton} from "../../../../components/md-button";
import {COMMON_BUTTON_DISABLE_STYLE, COMMON_BUTTON_STYLE} from "../../../../constants/componentsContants";
import {useAppSelector} from "../../../../redux/hooks";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {ICompany} from "../../../../interfaces/companyInfoInterfaces/ICompanyInfo";

type CompanyInfoProfileProps = {
    companies: ICompany[]
    importCompany: (company: ICompany) => void

}

export function CompanyInfoProfileComponent(props: CompanyInfoProfileProps) {

    const authState = useAppSelector(state=>state.auth);
    const [selectedItem,setSelectedItem] = useState(-1)
    const {t} = useTranslation();
    //const

    const importHandler = (index:number) =>{
        if(authState.isSupportUser) {
            props.importCompany(props.companies[index])
        }
        else{
           setSelectedItem(index);
           setTimeout(()=>{setSelectedItem(-1)},1500)
        }
    }

    return (
        <div className="company-info-container">
            <div className="company-info-row-container-header">
                <div typeof="company-name-block">
                    <div typeof="company-label"> {t("myProfilePage.companyName")}</div>
                    {/*<div typeof="company-info"> {company.fullName}</div>*/}
                </div>
                <div className="vertical-border-company-info"></div>

                <div typeof="agreement-no-block">
                    <div typeof="company-label"> {t("myProfilePage.agreement")}</div>
                    {/*<div*/}
                    {/*    typeof="company-info"> {company.agreement !== null ? company.agreement.no : "No data"}</div>*/}
                </div>
                <div className="vertical-border-company-info"></div>

                <div typeof="address-block">
                    <div typeof="company-label"> {t("myProfilePage.address")}</div>
                </div>

                <div typeof="last-elements">
                    <div className="vertical-border-company-info"></div>
                    <div typeof="action-block">
                        <div typeof="company-label"> {t("myProfilePage.import")}</div>
                    </div>
                </div>
            </div>

            {props.companies && props.companies.map((company, index) =>
                <div className="company-info-row-container" key={CompanyInfoProfileComponent.name + "-" + index}>
                    <div typeof="company-name-block">
                        {/*<div typeof="company-label"> Company name:</div>*/}
                        <div typeof="company-info"> {company.fullName}</div>
                    </div>
                    <div className="vertical-border-company-info"></div>

                    <div typeof="agreement-no-block">
                        {/*<div typeof="company-label"> Agreement:</div>*/}
                        <div
                            typeof="company-info"> {company.agreements.length > 0 ? company.agreements[0].code : "No data"}</div>
                    </div>
                    <div className="vertical-border-company-info"></div>

                    <div typeof="address-block">
                        {/*<div typeof="company-label"> Address:</div>*/}
                        <div
                            typeof="company-info"> {company.address !== null ? company.address : "No data"}</div>
                    </div>
                    {/*<div className="vertical-border-company-info"></div>*/}

                    <div typeof="last-elements">
                        <div className="vertical-border-company-info">&nbsp;</div>
                        <div typeof="action-block">
                            <div typeof="company-info">
                                <MdButton style={authState.isSupportUser?COMMON_BUTTON_STYLE:COMMON_BUTTON_DISABLE_STYLE} children={<p>Import</p>}
                                onClick={()=>importHandler(index)}/>
                            </div>
                            {selectedItem>=0 && selectedItem ===index && <div typeof="access-denied-msg">{t("myProfilePage.notEnoughRights")}</div>}
                        </div>
                    </div>

                </div>)}
        </div>)
}