import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../redux/hooks";
import {IOrder, IOrderBookingDetails, IOrderIssueDetails} from "../../../../interfaces/IOrderInfo";
import {TripService} from "../../../../services/TripService";
import {ModalContext, ORDER_DETAILS_MODAL_ID} from "../../../commonComponents/modal/ModalContext";
import {MdScrollViewer} from "../../../../components/md-scroll-viewer";
import {MdModal} from "../../../../components/md-modal";
import {MdButton} from "../../../../components/md-button";
import {MdToggleButton} from "../../../../components/md-toggle-button";
import {AirplaneIcon} from "../../../../icons/AirplaneIcon";
import {UserIconFilled} from "../../../../icons/UserIconFilled";
import "./style.scss";


interface IOrderModal {
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>,
    orderNo: string,
    submitHandler: (order: IOrder, pnrs: string[]) => Promise<boolean>;
}


export function OrderModal(props: IOrderModal) {

    const {
        opened,
        setOpened,
        orderNo,
        submitHandler,
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const tripService = TripService();

    const {isOpen, open, close} = useContext(ModalContext);


    const [order, setOrder] = useState<IOrder | null>(null);
    const [isOrderIssued, setIsOrderIssued] = useState<boolean>(false);
    const [orderBookingDetails, setOrderBookingDetails] = useState<IOrderBookingDetails[]>([]);
    const [orderIssueDetails, setOrderIssueDetails] = useState<IOrderIssueDetails | null>(null);

    const onModalOpen = () => {
        if (!opened) {
            setOpened(true);
        }
        getOrderDetails();
    }

    const onModalClose = () => {
        if (opened) {
            setOpened(false);
        }
    }


    const getOrderDetails = async () => {
        setOrder(null);
        setOrderBookingDetails([]);
        setOrderIssueDetails(null);

        const isOrderIssued = await tripService.isOrderIssued(authState.currentClientCode, orderNo);
        const orderData = await tripService.getOrder(orderNo);
        const orderBookingDetails = await tripService.getOrderBookingDetails(orderNo);
        const orderIssueDetails = await tripService.getOrderIssueDetails(authState.currentClientCode, orderNo);

        // const orderIssueDetails = {
        //     orderNo: "5247295",
        //     bsoList: [
        //         {
        //             bsoNo: "А002133795",
        //             tickets: [
        //                 {
        //                     ticketNo: "2120290543",
        //                     paxFullName: "KURYLAU IVAN",
        //                     pnr: "MS0W5C",
        //                     sumTotal: 18422,
        //                     airCompanyCode: "S7",
        //                     baggageInfo: "1PC",
        //                     flightNo: "1212",
        //                     tarifMask: "SPLOW",
        //                     classOfService: "Эконом",
        //                     departDate: "2024-11-27T13:15:00",
        //                     arriveDate: "2024-11-27T17:05:00",
        //                     departCode: "KGD",
        //                     arriveCode: "DME"
        //                 }
        //             ]
        //         }
        //     ]
        // }


        setOrder(orderData);
        setIsOrderIssued(isOrderIssued);
        setOrderBookingDetails(orderBookingDetails);
        setOrderIssueDetails(orderIssueDetails);
    }


    const trips: any[] = order?.evaParse?.trips ?? [];
    const dirs = trips.map((trip: any) => trip.dirs).flat();
    const passengers = trips.map((trip: any) => trip.passengers).flat();


    useEffect(() => {
        if (isOpen(ORDER_DETAILS_MODAL_ID) && !opened) {
            close(ORDER_DETAILS_MODAL_ID);
        } else if (!isOpen(ORDER_DETAILS_MODAL_ID) && opened) {
            open(ORDER_DETAILS_MODAL_ID);
        }
    }, [opened]);

    const recordLocator = (orderBookingDetails[0]?.aviaBooking[0]?.aviaBooking.bookingFile.reservations.reservation[0].recordLocator);

    useEffect(() => {
    }, []);


    return (
        <MdModal
            id={ORDER_DETAILS_MODAL_ID}
            title={"Заказ №" + orderNo}
            openHandler={onModalOpen}
            closeHandler={onModalClose}>
            <MdScrollViewer
                style={{
                    "root": {
                        maxHeight: "500px",
                        padding: "10px 0"
                    }
                }}
            >

                <div className="order-details">

                    {
                        !isOrderIssued && (
                            <>
                                <div className="order-header">
                                    <div className="order-header-title">
                                        {/*{*/}
                                        {/*    dirs?.map((dir: any, dirIndex: number) => {*/}
                                        {/*        return (*/}
                                        {/*            <>*/}
                                        {/*                {*/}
                                        {/*                    dirIndex > 0 && (*/}
                                        {/*                        ">"*/}
                                        {/*                    )*/}
                                        {/*                }*/}
                                        {/*                <div>*/}
                                        {/*                    {*/}
                                        {/*                        dir.townFromInfo.ita*/}
                                        {/*                    }*/}
                                        {/*                    {*/}
                                        {/*                        ">"*/}
                                        {/*                    }*/}
                                        {/*                    {*/}
                                        {/*                        dir.townToInfo.ita*/}
                                        {/*                    }*/}
                                        {/*                </div>*/}
                                        {/*            </>*/}
                                        {/*        )*/}
                                        {/*    })*/}
                                        {/*}*/}

                                        {
                                            recordLocator != null && (
                                                <MdToggleButton
                                                    buttonStyle={{
                                                        margin: 0,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "1px 10px 0 10px",
                                                        borderRadius: "4px",
                                                        minHeight: "24px",
                                                        maxHeight: "52px",
                                                        fontSize: "11px",
                                                        color: (recordLocator != null ? "#74B100" : "#FF0000"),
                                                        border: (recordLocator != null ? "1px solid #74B100" : "1px solid #FF0000"),
                                                    }}
                                                    inkStyle={{
                                                        backgroundColor: (recordLocator != null ? "#74B100" : "#FF0000"),
                                                        opacity: 0.1
                                                    }}
                                                    checked={true}
                                                >
                                                    <>
                                                            <span>
                                                                Код бронирования:
                                                            </span>
                                                        <span style={{marginLeft: "5px", fontWeight: 500}}>
                                                                {
                                                                    recordLocator ?? "Не определен"
                                                                }
                                                            </span>
                                                    </>
                                                </MdToggleButton>
                                            )
                                        }
                                    </div>
                                </div>

                                <div className="order-booking-details">
                                    {
                                        passengers.map((passenger: any, passengerIndex: number) => {
                                            return (
                                                dirs.map((dir: any, dirIndex: number) => {
                                                    const departCode = dir.townFromInfo.ita;
                                                    const departCity = dir.townFrom;
                                                    const departDate = new Date(dir.departDate);
                                                    const arriveCode = dir.townToInfo.ita;
                                                    const arriveCity = dir.townTo;
                                                    const arriveDate = new Date(dir.arriveDate);
                                                    const carrier = orderBookingDetails[0]?.aviaBooking[0]?.flightGroup?.carrier?.code ?? "";

                                                    return (
                                                        <React.Fragment key={passengerIndex}>
                                                            <div className="order-booking-details-item">
                                                                <>
                                                                    <div className="avia-ticket-info">
                                                                        <div className="avia-ticket-info-header">
                                                                            <div className="depart-info">
                                                                                <div className="city-code">
                                                                                    {departCode}
                                                                                </div>
                                                                                <div className="city-name">
                                                                                    {departCity}
                                                                                </div>
                                                                                <div className="datetime">
                                                                                    <span>
                                                                                        {departDate.getHours().toString().padStart(2, "0") + ":" + departDate.getMinutes().toString().padStart(2, "0") + ", "}
                                                                                    </span>
                                                                                    <span>
                                                                                        {departDate.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                                        {" "}
                                                                                        {departDate.getDate().toString().padStart(2, "0")}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flight-info">
                                                                                <div style={{fontSize: "12px"}}>
                                                                                    {carrier}
                                                                                </div>
                                                                                <div className="icon">
                                                                                    <span
                                                                                        style={{opacity: 0.25}}></span>
                                                                                    <span style={{opacity: 0.5}}></span>
                                                                                    <span></span>
                                                                                    <div style={{margin: "0 2px"}}>
                                                                                        <AirplaneIcon
                                                                                            width="16px"
                                                                                            height="16px"
                                                                                            style={{
                                                                                                "path": {
                                                                                                    fill: "#475AEF"
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <span></span>
                                                                                    <span style={{opacity: 0.5}}></span>
                                                                                    <span
                                                                                        style={{opacity: 0.25}}></span>
                                                                                </div>
                                                                                <div className="number">
                                                                                    {dir.cruiseNumber}
                                                                                </div>
                                                                            </div>
                                                                            <div className="arrive-info">
                                                                                <div className="city-code">
                                                                                    {arriveCode}
                                                                                </div>
                                                                                <div className="city-name">
                                                                                    {arriveCity}
                                                                                </div>
                                                                                <div className="datetime">
                                                                                    <span>
                                                                                        {arriveDate.getHours().toString().padStart(2, "0") + ":" + arriveDate.getMinutes().toString().padStart(2, "0") + ", "}
                                                                                    </span>
                                                                                    <span>
                                                                                        {arriveDate.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                                        {" "}
                                                                                        {arriveDate.getDate().toString().padStart(2, "0")}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="avia-ticket-info-content">
                                                                            <div
                                                                                className="avia-ticket-info-item">
                                                                                <span>Пассажир</span>
                                                                                <span></span>
                                                                                <span>{passenger.last + " " + passenger.first}</span>
                                                                            </div>
                                                                            <MdToggleButton
                                                                                buttonStyle={{
                                                                                    margin: "20px 10px 5px 10px",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    padding: "1px 10px 0 10px",
                                                                                    borderRadius: "4px",
                                                                                    minHeight: "24px",
                                                                                    maxHeight: "52px",
                                                                                    fontSize: "11px",
                                                                                    color: "rgb(200 200 200)",
                                                                                    border: "1px solid rgb(200 200 200)",
                                                                                }}
                                                                                inkStyle={{
                                                                                    backgroundColor: "rgb(200 200 200)",
                                                                                    opacity: 0.1
                                                                                }}
                                                                                checked={true}
                                                                            >
                                                                                Ожидает выписки
                                                                            </MdToggleButton>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })
                                            )
                                        })
                                        }
                                </div>

                                <div className="order-footer">
                                    {
                                        recordLocator != null && (
                                            <MdButton
                                                type="button"
                                                style={{
                                                    "button": {
                                                        borderWidth: 0,
                                                        backgroundColor: "#3E5CB8",
                                                        color: "#FFFFFF",
                                                        borderRadius: "6px",
                                                        height: "30px",
                                                        padding: "5px 15px"
                                                    },
                                                    "text": {
                                                        width: "auto",
                                                        flex: 0,
                                                        color: "#FFFFFF"
                                                    },
                                                    "ink": {
                                                        backgroundColor: "rgba(255,255,255,.5)"
                                                    }
                                                }}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    if (order != null) {
                                                        submitHandler(order, [recordLocator]);
                                                    }
                                                }}
                                            >
                                                Выписать
                                            </MdButton>
                                        )
                                    }
                                </div>
                            </>
                        )
                    }

                    {
                        isOrderIssued && (
                            <div className="order-issue-details">
                                {
                                    orderIssueDetails?.bsoList.map((bso, bsoIndex) => {
                                        return (
                                            <React.Fragment key={bsoIndex}>
                                                <div className="order-issue-details-item">
                                                    <a className="bso-link"
                                                       target="_blank"
                                                       href={("tts://objType=31825&docNum=" + bso.bsoNo + "#order")}>
                                                        Выписка: #{bso.bsoNo}
                                                    </a>
                                                    <div>
                                                        {
                                                            bso.tickets.map((ticket, ticketIndex) => {
                                                                const departCode = ticket.departCode;
                                                                const departCity = "???";
                                                                const departDate = new Date(ticket.departDate);
                                                                const arriveCode = ticket.arriveCode;
                                                                const arriveCity = "???";
                                                                const arriveDate = new Date(ticket.arriveDate);

                                                                return (
                                                                    <>
                                                                        <div
                                                                            className="avia-ticket-info">
                                                                            <div
                                                                                className="avia-ticket-info-header">
                                                                                <div
                                                                                    className="depart-info">
                                                                                    <div
                                                                                        className="city-code">
                                                                                        {departCode}
                                                                                    </div>
                                                                                    <div
                                                                                        className="city-name">
                                                                                        {departCity}
                                                                                    </div>
                                                                                    <div
                                                                                        className="datetime">
                                                                    <span>
                                                                        {departDate.getHours().toString().padStart(2, "0") + ":" + departDate.getMinutes().toString().padStart(2, "0") + ", "}
                                                                    </span>
                                                                                        <span>
                                                                        {departDate.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                                            {" "}
                                                                                            {departDate.getDate().toString().padStart(2, "0")}
                                                                    </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="flight-info">
                                                                                    <div
                                                                                        style={{fontSize: "12px"}}>
                                                                                        {ticket.airCompanyCode}
                                                                                    </div>
                                                                                    <div
                                                                                        className="icon">
                                                                                    <span
                                                                                        style={{opacity: 0.25}}></span>
                                                                                        <span
                                                                                            style={{opacity: 0.5}}></span>
                                                                                        <span></span>
                                                                                        <div
                                                                                            style={{margin: "0 2px"}}>
                                                                                            <AirplaneIcon
                                                                                                width="16px"
                                                                                                height="16px"
                                                                                                style={{
                                                                                                    "path": {
                                                                                                        fill: "#475AEF"
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <span></span>
                                                                                        <span
                                                                                            style={{opacity: 0.5}}></span>
                                                                                        <span
                                                                                            style={{opacity: 0.25}}></span>
                                                                                    </div>
                                                                                    <div
                                                                                        className="number">
                                                                                        {ticket.flightNo}
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="arrive-info">
                                                                                    <div
                                                                                        className="city-code">
                                                                                        {ticket.arriveCode}
                                                                                    </div>
                                                                                    <div
                                                                                        className="city-name">
                                                                                        {arriveCity}
                                                                                    </div>
                                                                                    <div
                                                                                        className="datetime">
                                                                    <span>
                                                                        {arriveDate.getHours().toString().padStart(2, "0") + ":" + arriveDate.getMinutes().toString().padStart(2, "0") + ", "}
                                                                    </span>
                                                                                        <span>
                                                                        {arriveDate.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                                            {" "}
                                                                                            {arriveDate.getDate().toString().padStart(2, "0")}
                                                                    </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="avia-ticket-info-content">
                                                                                <div
                                                                                    className="avia-ticket-info-item">
                                                                                    <span>Пассажир</span>
                                                                                    <span></span>
                                                                                    <span>{ticket.paxFullName}</span>
                                                                                </div>
                                                                                <div
                                                                                    className="avia-ticket-info-item">
                                                                                    <span>Номер билета</span>
                                                                                    <span></span>
                                                                                    <span>{ticket.ticketNo}</span>
                                                                                </div>
                                                                                <div
                                                                                    className="avia-ticket-info-item">
                                                                                    <span>Багаж</span>
                                                                                    <span></span>
                                                                                    <span>{ticket.baggageInfo}</span>
                                                                                </div>
                                                                                <div
                                                                                    className="avia-ticket-info-item">
                                                                                    <span>Тариф</span>
                                                                                    <span></span>
                                                                                    <span>{ticket.tarifMask}</span>
                                                                                </div>
                                                                                <div
                                                                                    className="avia-ticket-info-item">
                                                                                    <span>Класс</span>
                                                                                    <span></span>
                                                                                    <span>{ticket.classOfService}</span>
                                                                                </div>
                                                                                <MdToggleButton
                                                                                    buttonStyle={{
                                                                                        margin: "20px 10px 5px 10px",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        padding: "1px 10px 0 10px",
                                                                                        borderRadius: "4px",
                                                                                        minHeight: "24px",
                                                                                        maxHeight: "52px",
                                                                                        fontSize: "11px",
                                                                                        color: "#74B100",
                                                                                        border: "1px solid #74B100",
                                                                                    }}
                                                                                    inkStyle={{
                                                                                        backgroundColor: "#74B100",
                                                                                        opacity: 0.1
                                                                                    }}
                                                                                    checked={true}
                                                                                >
                                                                                    Выписан
                                                                                </MdToggleButton>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </React.Fragment>

                                        )
                                    })
                                }
                            </div>
                        )
                    }

                </div>

            </MdScrollViewer>
        </MdModal>
    );
}