import {useEffect, useState} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {setIsTokenBeingRefreshed} from "./redux/authSlice";
import {ProtectedRoute} from "./components/protectedRoute";
import MainLayout from "./layout/mainLayout";
import {HomeLayout} from "./layout/homeLayout";
import {CabinetLayout} from "./layout/cabinetLayout";
import {HomePage} from "./pages/landingPages/homePage";
import {SignInPage} from "./pages/landingPages/signInPage";
import {HomeCabinetPage} from "./pages/cabinetPages/homeCabinetPage";
import {MyProfilePage} from "./pages/cabinetPages/myProfilePage";
import {StatisticPage} from "./pages/cabinetPages/statisticPage";
import {OrderCabinetPage} from "./pages/cabinetPages/orderCabinetPage";
import {AccountantDocumentsPage} from "./pages/cabinetPages/documentsPage";
import {EmployeesPage} from "./pages/cabinetPages/employeesPage";
import {ServicesPage} from "./pages/landingPages/servicesPage";
import {TariffsPage} from "./pages/landingPages/tariffsPage";
import {ContactsPage} from "./pages/landingPages/contactsPage";
import {ChangePasswordPage} from "./pages/commonPages/changePasswordPage";
import {RecoveryPasswordPage} from "./pages/commonPages/recoveryPasswordPage";
import {NotFoundPage} from "./pages/errorPages/notFoundPage";
import {AuthService} from "./services/AuthService";
import {Environments} from "./enums/Environments";
import {TripPage} from "./pages/cabinetPages/tripPage";
import {SupportPage} from "./pages/cabinetPages/supportPage";
import {Guid} from "./utils/utils";
import "./App.scss";


export function App() {

    const dispatch = useAppDispatch();

    const authState = useAppSelector((state) => state.auth);
    const authService = AuthService();


    const [id] = useState<string>(Guid.newGuid());
    const [isFocused, setIsFocused] = useState(false);


    const refreshAccessToken = async () => {
        //if(!document.hidden && isFocused) {
        if (authState.isValidUser) {
            if (localStorage.getItem('refreshTokenRequestId') != id) {
                return;
            }
            try {
                dispatch(setIsTokenBeingRefreshed(true));
                // request token refreshing
                let refreshTokenResult = await authService.refreshToken({expiredJwtToken: authState.accessToken});
                dispatch(setIsTokenBeingRefreshed(false));
                localStorage.removeItem('refreshTokenRequestId');
                // if token refreshing was successfully complete
                if (refreshTokenResult) {
                    return true;
                } else {
                    // user logout
                    await authService.logout();
                }
            } catch (error) {
                // user logout
                await authService.logout();
            }
        }
        //}
        return false;
    }


    useEffect(() => {
        const onFocus = () => setIsFocused(true);
        const onBlur = () => setIsFocused(false);
        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        }
    }, [isFocused]);


    useEffect(() => {
        const timer = setInterval(() => {
            if (authState.isValidUser) {
                // get token expiration date
                let tokenExpirationDate = new Date(authState.expiredAt * 1000);
                // get token lifetime
                let tokenLifetime = ((tokenExpirationDate.getTime() - new Date().getTime()));
                // set time before token update
                let timeBeforeUpdate = tokenLifetime - 60 * 1000;
                // TimeUtils.getTimeDetails(timeBeforeUpdate, function (hours, minutes, seconds) {
                //     console.log('time before token update', minutes + ":" + seconds.toFixed(0).padStart(2, '0'));
                // });
                // update token
                if (timeBeforeUpdate < 0) {
                    localStorage.setItem('refreshTokenRequestId', id);
                    refreshAccessToken();
                }
            }
        }, 2500);
        return () => clearInterval(timer);
    });


    return (
        <>
            <Routes>
                <Route path="/" element={<MainLayout/>}>
                    <Route path="/" element={<HomeLayout/>}>
                        <Route index element={<HomePage/>}/>
                        <Route path="/services" element={<ServicesPage/>}/>
                        <Route path="/tariffs" element={<TariffsPage/>}/>
                        <Route path="/contacts" element={<ContactsPage/>}/>
                        <Route path="sign-in" element={<SignInPage/>}/>
                        {/*<Route path="sign-up" element={<SignUpPage/>}/>*/}
                        <Route path="changePassword" element={<ChangePasswordPage/>}/>
                        <Route path="recovery" element={<RecoveryPasswordPage/>}/>
                    </Route>
                    <Route path="/cabinet/" element={<ProtectedRoute/>}>
                        <Route path="/cabinet/" element={<CabinetLayout/>}>

                            <Route path="/cabinet/" element={<Navigate to="/cabinet/booking"/>}/>
                            <Route path="/cabinet/" element={<ProtectedRoute env={Environments.Development}/>}>
                                <Route path="/cabinet/account" element={<HomeCabinetPage/>}/>
                            </Route>

                            <Route path="/cabinet/" element={<ProtectedRoute env={Environments.Development}/>}>
                                <Route path="/cabinet/profile" element={<MyProfilePage/>}/>
                            </Route>

                            <Route
                                path="/cabinet/"
                                element={
                                    <ProtectedRoute env={Environments.Development}/>
                                }
                            >
                                <Route path="/cabinet/reports" element={<StatisticPage/>}/>
                            </Route>

                            <Route
                                path="documents"
                                element={
                                    <AccountantDocumentsPage/>
                                }
                            />

                            <Route
                                path="booking"
                                element={
                                    <TripPage/>
                                }
                            />

                            <Route
                                path="/cabinet/"
                                element={
                                    <ProtectedRoute env={Environments.Development | Environments.Staging}/>
                                }
                            >
                                <Route path="/cabinet/employees" element={<EmployeesPage/>}/>
                            </Route>

                            <Route
                                path="/cabinet/"
                                element={
                                    <ProtectedRoute env={Environments.Development}/>
                                }
                            >
                                <Route path="/cabinet/orders" element={<OrderCabinetPage/>}/>
                            </Route>

                            <Route
                                path="/cabinet/"
                                element={
                                    <ProtectedRoute env={Environments.Development | Environments.Staging}/>
                                }
                            >
                                <Route path="/cabinet/support" element={<SupportPage/>}/>
                            </Route>

                        </Route>
                    </Route>
                    <Route path='*' element={<NotFoundPage/>}/>
                    {/*<Route path='/accessDenied' element={<AccessDeniedPage/>}/>*/}
                </Route>
            </Routes>
        </>
    );
}

export default App;