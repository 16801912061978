import { axiosContext, axiosInstance} from "./AxiosInstance";
import {
    BEARER,
    ROUTE_ADD_COMPANY,
    ROUTE_GET_CLIENT_INFO_BY_CLIENT_CODE,
    ROUTE_GET_CLIENT_INFO_BY_NAME,
    ROUTE_GET_CLIENTS_DETAILS,
    ROUTE_GET_CLIENTS_STRUCTURE,
    ROUTE_GET_CLIENTS_SALES,
    ROUTE_GET_ALLOWED_PERSONS,
    ROUTE_GET_COMPANY_BY_CODE,
    ROUTE_GET_ALL_COMPANIES,
    ROUTE_SEARCH_COMPANIES_BY_NAME,
    ROUTE_IMPORT_COMPANY,
    ROUTE_IS_COMPANY_EXIST,
} from "../constants/routeConstants/ApiRouteConstants";
import {SearchClientFees} from "../responces/SearchClientFees";
import {IAllowedPerson} from "../interfaces/companyInfoInterfaces/IAllowedPerson";
import {IClientStructure} from "../interfaces/companyInfoInterfaces/IClientStructure";
import {ICLientSale} from "../interfaces/companyInfoInterfaces/IClientSale";
import {ICompany} from "../interfaces/companyInfoInterfaces/ICompanyInfo";
import {
    IClientDetailsRequest,
    IClientStructureRequest,
    ICompanyInfoByNameRequest,
    ICompanyInfoRequest,
    ICompanyRequest,
    ISearchCompanyRequest
} from '../interfaces/requestsInterfaces/reportRequests';
import {IClientDetail} from '../interfaces/companyInfoInterfaces/IClientDetail';
import {ICompanyResponse} from "../responces/ICompanyResponse";
import {IAddCompanyRequest} from "../Requests/IAddCompanyRequest";

export function CompanyApi() {

    const axiosInstanceContext = axiosContext;

    const isCompanyExists = async (code: string, token: string) => {
        const result = await axiosInstance.get<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_IS_COMPANY_EXIST,
            {
                headers: {
                    Authorization: BEARER + token,
                    Auth: 123
                },
                params: {
                    code: code
                }
            }
        );
        return result.data;
    };


    //  Route: /searchAllowedPersons get allowed persons from api
    const searchAllowedPersons = async (request: ICompanyInfoRequest) => {

        const result = await axiosInstance.get<IAllowedPerson[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ALLOWED_PERSONS, {
                headers: {
                    Authorization: BEARER + request.token,
                    Auth: 123
                },
                params: {clientCode: request.clientCode}
            }
        );

        return result.data;
    }

    // route: /searchClients (WPF)
    //ToDo need to test
    const searchClient = async (request: ICompanyInfoRequest) => {

        const result = await axiosInstance.get<ICompany>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_CLIENT_INFO_BY_CLIENT_CODE,
            {
                    headers: {
                        Authorization: BEARER + request.token,
                        Auth: 123
                    },
                    params: {
                        code: request.clientCode
                    }
                }
        );
        return result.data;
    };


    const searchClientByName = async (request: ICompanyInfoByNameRequest) => {
        const result = await axiosInstance.get<ICompany[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_CLIENT_INFO_BY_NAME,
            {
                    headers: {
                        Authorization: BEARER + request.token,
                        // 'Access-Control-Allow-Origin': '*'
                    },
                    params: {
                        name: request.nameLike
                    }
            }
        );
        return result.data;
    };

    //route: "clientDetails"
    const getClientDetails = async (request: IClientDetailsRequest) => {
        const result = await axiosInstance.get<IClientDetail>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_CLIENTS_DETAILS,
            {
                headers: {
                    Authorization: BEARER + request.token,
                    Auth: 123
                },
                params: {
                    code: request.clientCode,
                    dateStart: request.dateStart
                }
            }
        );
        return result.data;
    };

    const getClientsSales = async (request: ICompanyInfoRequest) => {
        const result = await axiosInstance.get<ICLientSale[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_CLIENTS_SALES,
            {
                headers: {
                    Authorization: BEARER + request.token,
                    Auth: 123
                },
                params: {
                    clientCode: request.clientCode,
                }
            }
        );
        return result.data;
    };

    const getClientStructure = async (request: IClientStructureRequest) => {
        const response = await axiosInstance.get<IClientStructure[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_CLIENTS_STRUCTURE,
            {
                headers: {
                    Authorization: BEARER + request.token,
                    Auth: 123
                },
                params: {
                    clientCode: request.clientCode,
                    status: request.status,
                    monthNum: request.monthNum,
                    quarter: request.quarter,
                    year: request.year,
                    dateStart: request.dateStart,
                    dateEnd: request.dateEnd
                }
            }
        )
        return response.data;
    }


    const addUserToCompany = async () => {
        const clientCode = "Ф0009867";
        const result = await axiosInstance.post<SearchClientFees>(
            "/api/company/addUser/" + clientCode,
            {
                email: "Some@email.com",
                firstName: "Mike",
                lastName: "Vasovski",
                patronymic: "Petrovich",
                position: "Sale Manageer",
                roleName: "sf",
            }
        );
        if (result.status === 200) {
            //return result.status;
        }
        return result.status;
    };


    const getAllCompanies = async (token: string) => {
        const result = await axiosInstance.get<ICompanyResponse[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ALL_COMPANIES,
            {
                headers: {
                    Authorization: BEARER + token,
                    Auth: 123
                }
            }
        );
        return result;
    }


    const importCompany = async (company: ICompany, token: string) => {
        const result = await axiosInstance.put(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_IMPORT_COMPANY,
            company,
            {
                headers: {
                    Authorization: BEARER + token,
                    Auth: 123
                }
            }
        );
        return result;
    };


    const addCompany = async (company: ICompany, token: string) => {
        const request: IAddCompanyRequest = {
            code: company.code,
            agreements: company.agreements,
            fullName: company.fullName,
            address: company.address,
            postalAddress: company.postalAddress,
            gkCode: company.gkCode,
            gkName: company.gkName,
            inn: company.inn,
            kpp: company.kpp,
            ogrn: company.ogrn,
            vatUse: company.vatUse,
            isActive: company.isActive
        };
        const result = await axiosInstance.put(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ADD_COMPANY,
            request,
            {
                headers: {
                    Authorization: BEARER + token,
                    Auth: 123
                }
            }
        );
        return result;
    };


    const getCompany = async (request: ICompanyRequest) => {
        const result = await axiosInstance.get<ICompanyResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_COMPANY_BY_CODE,
            {
                headers: {
                    Authorization: BEARER + request.token,
                    Auth: 123
                },
                params: {
                    code: request.code,
                },
            }
        );
        if (result.status === 200) {
            return result.data;
        }
    };


    const searchCompany = async (request: ISearchCompanyRequest) => {
        const result = await axiosInstance.get<ICompanyResponse[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_SEARCH_COMPANIES_BY_NAME,
            {
                headers: {
                    Authorization: BEARER + request.token,
                    Auth: 123
                },
                params: {
                    name: request.name,
                },
            }
        );
        if (result.status === 200) {
            console.log(result);
            return result.data;
        }
    };


    return {
        isCompanyExists,
        addUserToCompany,
        searchClient,
        getClientsSales,
        getClientDetails,
        searchAllowedPersons,
        getClientStructure,
        searchClientByName,
        importCompany,
        addCompany,
        getCompany,
        getAllCompanies,
        searchCompany
    };
}
