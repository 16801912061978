import {InputTextWithPopoverComponent} from "../../../../commonComponents/inputTextWithPopoverComponent";
import {MdButton} from "../../../../../components/md-button";
import {CircleSpinnerIcon} from "../../../../../icons/CircleSpinnerIcon";
import {SearchIcon} from "../../../../../icons/SearchIcon";
import {t} from "i18next";
import {IDirectionRequest} from "../../../../../interfaces/requestsInterfaces/IDirectionRequest";
import {useEffect, useRef, useState} from "react";
import {
    ARRIVED_CITY,
    DEPARTURE_CITY,
    DEPARTURE_DATE, MAX_CITIES_MULTI_ROUTE,
} from "../../../../../constants/PageConstants";
import {InputDateComponent} from "../../../../commonComponents/inputDateComponent";
import {COMMON_BUTTON_STYLE} from "../../../../../constants/componentsContants";
import {OrderService} from "../../../../../services/OrderServices";


type SearchDirectionProps = {
    directionIndex: number;
    roundDirections?: IDirectionRequest[]
    directionOffMultiRoute?: IDirectionRequest;
    hasBackwardDirection: boolean;
    backwardDirection?: IDirectionRequest;
    isOneWayMode: boolean;
    isLastDirection?: boolean;

    //func
    addEmptyDirection?: () => void;
    removeDirection?: () => void;
    updateDirections?: (index: number, direction: IDirectionRequest) => void;
    runSearch?: () => void;
}

enum CityCatalog {
    Nine,
    DepartureCities,
    ArriveCities
}

export function SearchDirectionComponent(props: SearchDirectionProps) {

    const {searchCityNamesByPartName} = OrderService()


    //testData
    //ToDo Remove??
    // const [cities, setCities] = useState<string[]>([
    //     "москва",
    //     "Санкт-Петербург",
    //     "Владивосток",
    //     "мосты",
    //     "можайск",
    //     "Самара",
    //     "Омск",
    //     "махачкала",
    // ]);

    const [departureCity, setDepartureCity] = useState(setDefaultState(DEPARTURE_CITY))
    const [arrivedCity, setArrivedCity] = useState(setDefaultState(ARRIVED_CITY))

    //Only for testing
    const [departureCitiesCatalog, setDepartureCitiesCatalog] = useState<string[]>([])
    const [arrivedCitiesCatalog, setArrivedCitiesCatalog] = useState<string[]>([])

    //Todo Use this
    const [depDateISO, setDepDateISO] = useState(
        props.directionIndex === 0
            ? (props.directionOffMultiRoute?.departDate === undefined ? new Date().toISOString() : props.directionOffMultiRoute?.departDate)
            : props.directionOffMultiRoute?.departDate);
    const [backwardDateIso, setBackwardDateIso] = useState('')

    //const [departureDateUtc, setDepartureDateUtc] = useState();
    const [returnDate, setReturnDate] = useState("")


    const updateCitiesCatalogHandler =async  (partName:string) => {
        const res = await  searchCityNamesByPartName(partName)
        return res;
    }


    const updateDepartureCitiesCatalog = async (partName:string) =>{
        const result =  await  searchCityNamesByPartName(partName)
        setDepartureCitiesCatalog(result)

    }

    const clearCityCatalog =( catalogName:CityCatalog) => {
        if(catalogName === CityCatalog.DepartureCities) setDepartureCitiesCatalog([]);
        if (catalogName === CityCatalog.ArriveCities) setArrivedCitiesCatalog([])
    }


    //ToDo check style
    function setDefaultState(paramName: string) {
        if (props.isOneWayMode) {
            switch (paramName) {
                case DEPARTURE_CITY: {
                    return props.directionOffMultiRoute?.townFrom ?? ""
                }
                case ARRIVED_CITY: {
                    return props.directionOffMultiRoute?.townTo ?? ""
                }
                case DEPARTURE_DATE: {
                    return props.directionOffMultiRoute?.departDate
                }
            }

        } else {
            switch (paramName) {
                case DEPARTURE_CITY: {
                    return props.directionOffMultiRoute?.townFrom ?? ""
                }
                case ARRIVED_CITY: {
                    return props.directionOffMultiRoute?.townTo ?? ""
                }
                case DEPARTURE_DATE: {
                    return props.directionOffMultiRoute?.departDate !== undefined
                        ? props.directionOffMultiRoute?.departDate
                        : props.directionIndex === 0
                            ? new Date().toISOString()
                            : undefined;
                }
            }
        }
        return ""
    }

    //UseEffects
    useEffect(() => {
        updateDirectionsHandler()
    }, [depDateISO]);

    //input timeout
    useEffect(() => {

        if (props.directionOffMultiRoute?.townFrom !== departureCity || props.directionOffMultiRoute?.townTo !== arrivedCity) {
            const timeoutId = setTimeout(() => {
                updateDirectionsHandler();
                console.log(`My index "${props.directionIndex}" `)
                console.log(`I can see you're not typing. I can use "${departureCity}" now!`)
            }, 1000);
            return () => clearTimeout(timeoutId);
        }

    }, [arrivedCity, departureCity]);

    const updateDirectionsHandler=()=> {
        if (props.isOneWayMode) {
        } else {
            let dateTo = depDateISO !== "" ? new Date(depDateISO!).toISOString() : "";
            if (props.updateDirections !== undefined) {
                props.updateDirections(props.directionIndex, {
                    townFrom: departureCity ?? "",
                    townTo: arrivedCity ?? "",
                    departDate: dateTo !== undefined ? dateTo : "",
                    clientCode: "",
                    notes: ""
                })
            }
        }
    }

    return (
        <>
            {/*Test*/}
            {/*{departureCitiesCatalog && departureCitiesCatalog.map(city=>(<p>{city}</p>))}*/}
            <div>
                <InputTextWithPopoverComponent
                    value={departureCity??""}
                    placeholder={t("searchDirectionComponent.from")}
                    popoverItems={departureCitiesCatalog}
                    setValue={setDepartureCity}
                    runItemsSearch={(partInputValue) => {
                      updateDepartureCitiesCatalog(partInputValue)
                    }}
                    clearPopoverItemsResult={() => {
                        clearCityCatalog(CityCatalog.DepartureCities)
                    }}
                />
            </div>
            <div style={{alignSelf: "center"}}>-</div>
            <div>
                <InputTextWithPopoverComponent
                    value={arrivedCity ?? ""}
                    placeholder={t("searchDirectionComponent.to")}
                    popoverItems={arrivedCitiesCatalog}
                    setValue={setArrivedCity}
                    runItemsSearch={(s) => {
                        console.log("testMessage")
                    }}
                    clearPopoverItemsResult={() => {
                        clearCityCatalog(CityCatalog.ArriveCities)
                    }}
                />
            </div>

            <InputDateComponent setDateHandler={setDepDateISO}
                                dateLabel={t("searchPage.departureDate")}
                                inputDateISO={depDateISO}
            />

            {/* backwardDate Oneway/RoundWay Date*/}
            {props.isOneWayMode &&
                <InputDateComponent setDateHandler={setBackwardDateIso}
                                    dateLabel={t("searchPage.backwardDate")}
                                    inputDateISO={backwardDateIso}
                />}

            {!props.isOneWayMode && props.isLastDirection &&
                <>
                    {props.directionIndex < MAX_CITIES_MULTI_ROUTE ? <MdButton
                        style={COMMON_BUTTON_STYLE}
                        children={<p style={{color: "white"}}>&#10011; Add route</p>}
                        onClick={props.addEmptyDirection}
                    /> : <></>
                    }

                    {props.directionIndex !== 0 ? <MdButton
                        style={COMMON_BUTTON_STYLE}
                        children={<p style={{color: "white"}}>&mdash; Delete route</p>}
                        onClick={props.removeDirection}
                    /> : <></>
                    }
                </>
            }
            {props.isLastDirection && <MdButton
                icon={<div style={{color: "white"}}><SearchIcon iconSize="20px"/></div>
                }
                style={COMMON_BUTTON_STYLE}
                children={t("documentPage.search")}
                onClick={props.runSearch}
            />}
        </>
    )
}