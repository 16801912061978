import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../redux/hooks";
import {IAviaTariffInfo, IAviaVariant} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {ITrainTariffInfo, ITrainVariant} from "../../../../interfaces/ITrainVariant";
import {IPassenger} from "../../../../interfaces/IPassenger";
import {IBookFlightRequestNew, IBookTrainRequestNew} from "../../../../Requests/IBookFlightRequest";
import {TripService} from "../../../../services/TripService";
import {TripBuilderModal} from "./tripBuilderModal";
import {PassengersSelectionModal} from "../passengersSelectionModal";
import {CompanySelectionModal} from "../../../commonComponents/companySelectionModal";
import {Guid, Utils} from "../../../../utils/utils";
import {MultiSearchTicketsModal} from "../multiSearchTicketsModal";
import "./style.scss"
import {ICompany} from "../../../../interfaces/companyInfoInterfaces/ICompanyInfo";



interface INewTripComponent {
    tripBuilderModalOpened: boolean;
    setTripBuilderModalOpened: React.Dispatch<React.SetStateAction<boolean>>,
    refreshOrderListHandler: () => void;
}


interface TripItemInfo {
    id: string,
    type: 'avia' | 'train',
    variant: IAviaVariant | ITrainVariant,
    tariff: IAviaTariffInfo | ITrainTariffInfo,
    searchResponse: any
}


export function NewTripComponent(props: INewTripComponent) {

    const {
        tripBuilderModalOpened,
        setTripBuilderModalOpened,
        refreshOrderListHandler
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);
    const companyState = useAppSelector((state) => state.companyInfo);

    const tripService = TripService();

    const [companySelectionModalOpened, setCompanySelectionModalOpened] = useState<boolean>(false);
    //const [selectedCompany, setSelectedCompany] = useState<ICompany | undefined>( !authState.isSupportUser ? companyState as any : undefined); // TODO: process companyState as ICompany
    const [selectedCompany, setSelectedCompany] = useState<ICompany | undefined>( companyState as any); // TODO: process companyState as ICompany

    const [searchTicketsModalOpened, setSearchTicketsModalOpened] = useState<boolean>(false);
    const [tripItemsInfo, setTripItemsInfo] = useState<TripItemInfo[]>([]);

    const [passengersSelectionModalOpened, setPassengersSelectionModalOpened] = useState<boolean>(false);
    const [passengers, setPassengers] = useState<{ passenger: IPassenger, passport: any }[]>([]);


    const openCompanySelectionModal = () => {
        setCompanySelectionModalOpened(true);
    }

    const selectCompany = (company: ICompany) => {
        if(selectedCompany?.code != company.code) {
            setPassengers([]);
        }
        setSelectedCompany(company);
    }


    const openTicketSearchModalHandler = () => {
        setSearchTicketsModalOpened(true);
    }

    const addFlightToTrip = (variant: IAviaVariant, tariff: IAviaTariffInfo, searchResponse: any) => {
        setTripItemsInfo(prev => [...prev, { id: Guid.newGuid(), type: 'avia', variant, tariff, searchResponse }]);
    }

    const addTrainToTrip = (variant: ITrainVariant, tariff: ITrainTariffInfo, searchResponse: any) => {
        setTripItemsInfo(prev => [...prev, { id: Guid.newGuid(), type: 'train', variant, tariff, searchResponse }]);
    }

    const changeTripItemTariff = (variantId: string, tariff: IAviaTariffInfo | ITrainTariffInfo) => {
        setTripItemsInfo(prev => [...prev.map(x => {
            if(x.id == variantId) {
                return {
                    id: x.id,
                    type: x.type,
                    variant: x.variant,
                    tariff: tariff,
                    searchResponse: x.searchResponse
                }
            }
            else {
                return x;
            }
        })]);
    }

    const removeItemFromTrip = (variant: IAviaVariant | ITrainVariant) => {
        setTripItemsInfo(prev => prev.filter(x => x.variant != variant));
    }


    const openPassengersSelectionModal = () => {
        setPassengersSelectionModalOpened(true);
    }

    const addPassengersToTrip = (passengers: { passenger: IPassenger, passport: any }[]) => {
        //setPassengers(prev => [...prev.filter(x => !passengers.some(y => y.id == x.id)), ...passengers]);
        setPassengers(passengers);
    }

    const removePassengersFromTrip = (passengers: { passenger: IPassenger, passport: any }[]) => {
        setPassengers(prev => prev.filter(x => !passengers.some(y => y.passenger.paxCode == x.passenger.paxCode)));
    }


    const bookOrder = async () => {

        let flights = tripItemsInfo.filter(x => x.type == 'avia');
        let trains = tripItemsInfo.filter(x => x.type == 'train');


        let bookFlightRequest: IBookFlightRequestNew = {
            userId: authState.userId,
            supportUserCode: authState.supportUserCode,
            clientCode: selectedCompany?.code ?? "",
            parameters: flights.map(flight => {
                return(
                    {
                        fareBasises: [flight.tariff.fareBasis].flat(),
                        paxes: passengers.map(p => {
                            return {
                                paxCode: p.passenger.paxCode,
                                selectedPassportNo: p.passport.number
                            }
                        }),
                        searchResponse: flight.searchResponse
                    }
                )
            })
        };

        let bookTrainRequest: IBookTrainRequestNew = {
            userId: authState.userId,
            clientCode: selectedCompany?.code ?? "",
            supportUserCode: authState.supportUserCode,
            parameters: trains.map(train => {
                return (
                    {
                        fareBasises: [train.tariff.fareBasis].flat(),
                        paxes: passengers.map(p => {
                            return {
                                paxCode: p.passenger.paxCode,
                                selectedPassportNo: p.passport.number
                            }
                        }),
                        searchResponse: train.searchResponse
                    }
                )
            })
        };

        let response1Error = false;
        if(bookFlightRequest.parameters.length > 0) {
            const response1 = await tripService.flightsBook(bookFlightRequest);
            response1Error = Utils.isEmpty(response1);
        }


        let response2Error = false;
        if(bookTrainRequest.parameters.length > 0) {
            const response2 = await tripService.trainsBook(bookTrainRequest);
            response2Error = Utils.isEmpty(response2);
        }

        if(!response1Error && !response2Error) {
            setTripBuilderModalOpened(false);
            setTripItemsInfo([]);
            setPassengers([]);
            setTripBuilderModalOpened(false);
            refreshOrderListHandler();
            return true;
        }

        return false;
    }


    useEffect(() => {

    }, []);


    return (
        <>
            <TripBuilderModal
                opened={tripBuilderModalOpened}
                setOpened={setTripBuilderModalOpened}

                openCompanySelectionModal={openCompanySelectionModal}
                selectedCompany={selectedCompany}
                setSelectedCompany={setSelectedCompany}

                openTicketSearchModalHandler={openTicketSearchModalHandler}
                tripItemsInfo={tripItemsInfo}
                removeItemFromTrip={removeItemFromTrip}

                changeTripItemTariff={changeTripItemTariff}

                openPassengersSelectionModal={openPassengersSelectionModal}
                passengers={passengers}
                setPassengers={setPassengers}
                removePassengersFromOrder={removePassengersFromTrip}

                submitHandler={bookOrder}
            />

            <CompanySelectionModal
                opened={companySelectionModalOpened}
                setOpened={setCompanySelectionModalOpened}
                submitHandler={selectCompany}
            />

            {/*<SearchTicketsModal*/}
            {/*    opened={searchTicketsModalOpened}*/}
            {/*    setOpened={setSearchTicketsModalOpened}*/}
            {/*    submitHandler={addFlightToTrip}*/}
            {/*    submitHandler2={addTrainToTrip}*/}
            {/*/>*/}

            <MultiSearchTicketsModal
                opened={searchTicketsModalOpened}
                setOpened={setSearchTicketsModalOpened}
                selectedCompany={selectedCompany}
                submitHandler={addFlightToTrip}
                submitHandler2={addTrainToTrip}
            />

            <PassengersSelectionModal
                opened={passengersSelectionModalOpened}
                setOpened={setPassengersSelectionModalOpened}
                company={selectedCompany}
                tripPassengers={passengers}
                submitHandler={addPassengersToTrip}
            />
        </>

    );
}
