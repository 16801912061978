import axios from "axios";
import {
    ROUTE_GET_REFS_CITIES,
} from "../constants/routeConstants/ApiRouteConstants";
import {IRefsCitiesResponse} from "../responces/IRefsApiResponses";


export function RefsApi() {

    const getCities = async (text: string) => {
        try {
            const result = await axios.get<Array<IRefsCitiesResponse>>(
                process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_REFS_CITIES,
                {
                    params: {
                        text: text,
                    }
                }
            );
            return result;
        }
        catch (ex: any) {
            return null;
        }
    };

    return {
        getCities
    };
}