import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CompanyService} from "../../../../services/CompanyService";
import {MdButton} from "../../../../components/md-button";
import {MdSlider} from "../../../../components/md-slider";
import {MdList} from "../../../../components/md-list";
import {MdModal} from "../../../../components/md-modal";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import {ModalContext, SELECTION_COMPANY_MODAL_ID} from "../../../commonComponents/modal/ModalContext";

import {MagnifierIcon} from "../../../../icons/MagnifierIcon";
import "./style.scss"
import {ICompany} from "../../../../interfaces/companyInfoInterfaces/ICompanyInfo";


interface ICompanyImportModal {
    title: string;
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>,
    submitHandler: (company: ICompany) => void;
}


export function CompanyImportModal(props: ICompanyImportModal) {

    const { title, opened, setOpened, submitHandler } = props;

    const localization = useTranslation();

    const companyService = CompanyService();


    const {isOpen, open, close} = useContext(ModalContext);


    const [searchPending, setSearchPending] = useState(false);
    const [searchError, setSearchError] = useState(false);

    const [companyName, setCompanyName] = useState("");
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);


    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.repeat) return;
        if (event.key === "Enter") {
            searchCompany();
        }
    }

    const onSubmit = (company: ICompany) => {
        submitHandler(company);
        close(SELECTION_COMPANY_MODAL_ID);
        resetForm();
    }


    const searchCompany = async () => {
        setCompanies([]);
        setSearchError(false);
        setSearchPending(true);
        setSelectedCompany(null);

        const response = await companyService.searchCompanyByName(companyName);

        if(response != null && response.length > 0) {
            setCompanies(response);
        }
        else {
            setSearchError(true);
        }

        setSearchPending(false);
    }

    const resetForm = () => {
        setCompanyName("");
        setCompanies([]);
        setSelectedCompany(null);
    }

    const onModalOpen = () => {
        if(!opened) {
            setOpened(true);
        }
        resetForm();
    }

    const onModalClose = () => {
        if(opened) {
            setOpened(false);
        }
        resetForm();
    }


    useEffect(() => {
        if(isOpen(SELECTION_COMPANY_MODAL_ID) && !opened) {
            close(SELECTION_COMPANY_MODAL_ID);
        }
        else if(!isOpen(SELECTION_COMPANY_MODAL_ID) && opened) {
            open(SELECTION_COMPANY_MODAL_ID);
        }
    }, [opened]);


    useEffect(() => { }, []);


    return (
        <MdModal
            id={SELECTION_COMPANY_MODAL_ID}
            title={title}
            openHandler={onModalOpen}
            closeHandler={onModalClose}>

            <div className="company-selection-dialog" style={{ maxHeight: (companies.length > 0 ? "500px": "150px")}}>

                <div className="company-selection-dialog__searchbar" style={{ transform: (searchPending || companies.length > 0 || searchError ? "translateY(0)" : "translateY(20px)" ) }}>

                    <div className="company-selection-dialog__searchbar-section">
                        <input
                            className="search-input"
                            style={{width: "180px", fontSize: "12px", outline: "none", boxShadow: "none"}}
                            type="text"
                            placeholder="Наименование компании"
                            value={companyName}
                            onChange={(e) => {
                                setCompanyName(e.target.value);
                            }}
                            onKeyDown={onKeyDown}
                        />
                        <MdButton
                            type="button"
                            disabled={searchPending}
                            icon={
                                <MagnifierIcon
                                    width="16px"
                                    height="16px"
                                    style={{
                                        "path": {
                                            fill: "#FFFFFF"
                                        }
                                    }}
                                />
                            }
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "#3E5CB8",
                                    color: "#FFFFFF",
                                    borderRadius: "4px",
                                    width: "30px",
                                    height: "30px",
                                    padding: "5px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                },
                                "icon": {},
                                "text": {},
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            onClick={searchCompany}
                        />
                    </div>

                </div>

                {
                companies.length > 0 && (
                        <>
                            <MdList
                                itemSource={companies}
                                activeItem={selectedCompany}
                                itemTemplate={(data: ICompany) => {
                                    return (
                                        <>
                                            <div className="company-details">
                                                <div className="company-details-item">
                                                    <span>Код: </span>
                                                    <span>{data.code}</span>
                                                </div>
                                                <div className="company-details-item">
                                                    <span>Наименование: </span>
                                                    <span>{data.fullName}</span>
                                                </div>
                                                <div className="company-details-item">
                                                    <span>Договор: </span>
                                                    {
                                                        data.agreements.length > 0
                                                            ? (
                                                                <span>{data.agreements[0]?.code}</span>
                                                            )
                                                            : (
                                                                <span
                                                                    style={{color: "rgb(143 12 12)"}}>Отсутствует</span>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )
                                }}
                                style={{
                                    "root": {
                                        flex: 1
                                    },
                                    "list": {
                                        padding: "10px 20px"
                                    }
                                }}
                                onItemSelect={setSelectedCompany}
                            />

                            <div className="company-selection-dialog__footer">
                                <MdButton
                                    type="submit"
                                    style={{
                                        "button": {
                                            width: "200px",
                                            borderWidth: 0,
                                            backgroundColor: "#3E5CB8",
                                            color: "#FFFFFF",
                                            borderRadius: "6px",
                                            height: "35px",
                                            padding: "5px 15px"
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    disabled={(selectedCompany == null)}
                                    onClick={() => {
                                        if(selectedCompany != null) {
                                            onSubmit(selectedCompany)
                                        }
                                    }}
                                >
                                    Выбрать
                                </MdButton>
                            </div>

                        </>

                    )
                }

                {
                    searchPending && (
                        <div className="loading-results-message">
                            <CircleSpinnerIcon
                                width="20px"
                                height="20px"
                                style={{
                                    "circle": {
                                        stroke: "#A0A1A6"
                                    }
                                }}
                            />
                            Загружаем компании..
                        </div>
                    )
                }

                {
                    searchError && (
                        <div className="no-results-message">
                            Не удалось загрузить компании с указанным наименованием
                        </div>
                    )
                }

            </div>

        </MdModal>
    );
}