import {Dispatch, SetStateAction} from "react";
import {useTranslation} from "react-i18next";
import {MdButton} from "../../../../components/md-button";
import "./style.scss";
import {InputTextComponent} from "../../../commonComponents/inputTextComponent";
import {COMMON_BUTTON_STYLE} from "../../../../constants/componentsContants";
import {SearchIcon} from "../../../../icons/SearchIcon";
import {LoadingComponent} from "../../../commonComponents/loadingComponent";

export interface CompaniesSearchProps {
    name: string;
    isSearching:boolean;
    nameHandler: Dispatch<SetStateAction<string>>
    searchHandler: () => void;
}

export function CompaniesSearchComponent(props: CompaniesSearchProps) {
    const {t} = useTranslation();
    const searchCompanies = () => {
        props.searchHandler();
    };

    return (
        <>
            <div className="company-filters-container">
                <InputTextComponent value={props.name} setValue={props.nameHandler}
                                    placeholder={t("myProfilePage.fullOrPartOfCompanyName")}
                                    label={t("myProfilePage.searchAndImport") }/>
                <div style={{alignItems: "center"}} >
                    <MdButton
                        type="button"
                        style={COMMON_BUTTON_STYLE}
                        onClick={searchCompanies}
                        children={<><SearchIcon/>{t("myProfilePage.search")}</>}
                    />
                </div>
                {props.isSearching &&<div > <LoadingComponent message={"Ищем Компании..."}/></div>}
            </div>
        </>
    );
}
