import { useState } from "react";
import { ReportsApi } from "../api/ReportsApi";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { IRequiredReports } from "../interfaces/componentInterfaces/IRequiredReports";
import { DateService } from "./DateService";
import { start } from "repl";
import { IError } from '../interfaces/dataInterfaces/IError';
import { CLIENT_CODE_NOT_FOUND_ERROR, TIME_SPAN_ERROR } from "../constants/ErrorConstants";
import { error } from "console";
import { IClientStructureRequest, ICommonReportRequest } from "../interfaces/requestsInterfaces/reportRequests";
import { DATE_FORMAT_YYYYMMDD } from "../constants/ServiceCostants";
import { getCustomerAdditionalServicesThunk, getCustomerAirCompaniesThunk, getCustomerFlightsThunk, getCustomerTicketOrdersThunk, getReportClientStructureExchangeThunk, getReportClientStructureRefoundThunk, getReportClientStructureSalesThunk, getStatementCloseBalanceThunk, getStatementIncomesThunk, getStatementOpenBalanceThunk, getStatementSalesThunk } from "../redux/reportsSlice";
import { useCookies } from "react-cookie";
import { ACCESS_TOKEN } from "../constants/FieldsConstants";
//import { setAccessToken, } from '../redux/authSlice';

export function ReportService() {

  //services 
  const { isTimeSpanCorrect, stringToDateFromDatePicker, dateFromDatePickerToCustomFormat,dateToCustomFormat } = DateService();

  //hooks
  const [searchDateStart, setSearchDateStart] = useState("");
  const [searchDateEnd, setSearchDateEnd] = useState("");
  const [cookies] = useCookies([ACCESS_TOKEN]);
  const { getStatemetSales } = ReportsApi();
  const companyInfoState = useAppSelector((state) => state.companyInfo);
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  //token

  const findStatementSalesByDate = async (
    dateStart: string,
    dateEnd: string,
    setError: (errors: string) => void
  ) => {
    const clientCode = authState.currentClientCode;
    const gkCode = companyInfoState.gkCode;

    if (clientCode === undefined) {
      setError("ClientCode is udefined.");
    }

    //   const result = await getStatemetSales(
    //     clientCode,
    //     dateStart,
    //     dateEnd,
    //     gkCode,
    //   );
  };



  const getReportsForPeriod = async (requiredReports: IRequiredReports, startDate: string, endDate: string) => {
    let errors: IError[] = []
    // get request  parameters
    console.log(`GetReports forPeriod ${startDate}, ${endDate}`);
    //dispatch(setAccessToken(cookies[ACCESS_TOKEN]))
    const currentClientCode = authState.currentClientCode;
    const token: string = cookies[ACCESS_TOKEN] ?? "";
    // if (companyInfoState.code === undefined) errors.push({
    //   errorMessage: "Client code not found.",
    //   errorType: CLIENT_CODE_NOT_FOUND_ERROR
    // });
    if (!isTimeSpanCorrect(new Date(startDate),new Date(endDate))) errors.push({
      errorMessage: "Time span isn't correct",
      errorType: TIME_SPAN_ERROR
    });
    if (errors.length !== 0) return errors;
    const request: ICommonReportRequest = {
      clientCode: currentClientCode,
      startDate:dateToCustomFormat(new Date(startDate),DATE_FORMAT_YYYYMMDD),
      endDate:dateToCustomFormat(new Date(endDate),DATE_FORMAT_YYYYMMDD),
      //startDate: dateFromDatePickerToCustomFormat(startDate, DATE_FORMAT_YYYYMMDD),
     // endDate: dateFromDatePickerToCustomFormat(endDate, DATE_FORMAT_YYYYMMDD),
      gkCode: authState.currentGkCode ?? "",
      token: token
    }

    //Open balance and closed balance must be looked for every time.

    dispatch(getStatementOpenBalanceThunk(request));

    dispatch(getStatementCloseBalanceThunk(request));

    // Client structure sales Exchange Refound
    let clientStructureRequest: IClientStructureRequest = {
      clientCode: currentClientCode,
      status: 0,
      monthNum: 0,
      quarter: 0,
      year: 0,
      dateStart: request.startDate,
      dateEnd: request.endDate,
      token: token
    }

    clientStructureRequest.status = 1;
    dispatch(getReportClientStructureSalesThunk(clientStructureRequest));

    clientStructureRequest.status = 2;
    dispatch(getReportClientStructureExchangeThunk(clientStructureRequest));

    clientStructureRequest.status = 3;
    dispatch(getReportClientStructureRefoundThunk(clientStructureRequest));

    //statementSales
    if (requiredReports.isStatementSalesRequired) {
      dispatch(getStatementSalesThunk(request))
    }

    //statement incomes
    if (requiredReports.isStatementIncomesRequired) {
      dispatch(getStatementIncomesThunk(request))
    }

    //ToDO 
    if (requiredReports.isCustomerFlightsRequired) {
      dispatch(getCustomerFlightsThunk(request))
    }

    //ToDO
    if (requiredReports.isCustomerTicketOrdersRequired) {
      dispatch(getCustomerTicketOrdersThunk(request))
    }

    //ToDO 
    if (requiredReports.isCustomerAirCompaniesRequired) {
      dispatch(getCustomerAirCompaniesThunk(request))
    }

    //ToDO 
    if (requiredReports.isCustomerAdditionalService) {
      dispatch(getCustomerAdditionalServicesThunk(request))
    }

    return errors;
  }

  return { getReportsForPeriod }
}
