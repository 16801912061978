import React, { useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../redux/hooks";
import axios, {CancelTokenSource} from "axios";
import {IAviaTariffInfo, IAviaVariant} from "../../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {DateService} from "../../../../../services/DateService";
import {TripService} from "../../../../../services/TripService";
import {FlightComponent} from "../../flightComponent";
import {MdSelect} from "../../../../../components/md-select";
import {MdButton} from "../../../../../components/md-button";
import {MdScrollViewer} from "../../../../../components/md-scroll-viewer";
import {CircleSpinnerIcon} from "../../../../../icons/CircleSpinnerIcon";
import {SwitchIcon} from "../../../../../icons/SwitchIcon";
import {RecycleBinIcon} from "../../../../../icons/RecycleBin";
import {RefsService} from "../../../../../services/RefsService";
import {CrossIcon} from "../../../../../icons/CrossIcon";
import {Guid, Utils} from "../../../../../utils/utils";
import "./style.scss";
import {MdToggleButton} from "../../../../../components/md-toggle-button";
import {MdMenu} from "../../../../../components/md-menu";
import {PlusIcon} from "../../../../../icons/PlusIcon";
import {MinusIcon} from "../../../../../icons/MinusIcon";
import {ICompany} from "../../../../../interfaces/companyInfoInterfaces/ICompanyInfo";

export interface IFlightRoute {
    id: string;
    passengers: number;
    class: 'economy' | 'business';
    baggage: boolean;
    return: boolean;
    exchange: boolean;
    origin: string;
    direction: string;
    departDate?: string;
    returnDate?: string;
}

interface IMultiSearchFlightsComponent {
    selectFlightHandler: (flight: IAviaVariant, tariff: IAviaTariffInfo, searchResponse: any) => void;
    selectedCompany: ICompany | undefined;
}


interface IAutoCompleteItem {
    city: string;
    country: string;
    countryId: string;
}


export function MultiSearchFlightsComponent(props: IMultiSearchFlightsComponent) {

    const {
        selectFlightHandler,
        selectedCompany
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const refsService = RefsService();

    const tripService = TripService();

    const dateService = DateService();


    const clearTimerRef = useRef();


    const [flightRoutes, setFlightRoutes] =
        useState<IFlightRoute[]>([
            {
                id: Guid.newGuid(),
                passengers: 1,
                class: 'economy',
                baggage: false,
                return: false,
                exchange: false,
                origin: "",
                direction: "",
            }
        ]);

    const [autoComplete, setAutoComplete] = useState<IAutoCompleteItem[]>([]);

    const [providers, setProviders] = useState<string[]>([]);
    const [selectedProviders, setSelectedProviders] = useState<string[]>([]);

    const [errors, setErrors] = useState<string[]>([]);

    const [flights, setFlight] = useState<IAviaVariant[]>([]);
    const [searchPending, setSearchPending] = useState(false);
    const [searchError, setSearchError] = useState(false);
    const [isSearchNotFound, setIsSearchNotFound] = useState(false);
    const [searchFlightsCT, setSearchFlightsCT] = useState<CancelTokenSource|undefined>(undefined);

    const [searchResponse, setSearchResponse] = useState<any>(null);


    const setPassengers = (passengers: number, routeIndex: number) => {
        setFlightRoutes((prevArr) => {
            const result = [...prevArr];
            const route = result[routeIndex];
            result[routeIndex] = {
                id: route.id,
                passengers: passengers,
                class: route.class,
                baggage: route.baggage,
                return: route.return,
                exchange: route.exchange,
                origin: route.origin,
                direction: route.direction,
                departDate: route.departDate,
                returnDate: route.returnDate
            };
            return result;
        });
    }

    const setClass = (flightClass: 'economy' | 'business', routeIndex: number) => {
        setFlightRoutes((prevArr) => {
            const result = [...prevArr];
            const route = result[routeIndex];
            result[routeIndex] = {
                id: route.id,
                passengers: route.passengers,
                class: flightClass,
                baggage: route.baggage,
                return: route.return,
                exchange: route.exchange,
                origin: route.origin,
                direction: route.direction,
                departDate: route.departDate,
                returnDate: route.returnDate
            };
            return result;
        });
    }

    const setBaggage = (baggage: boolean, routeIndex: number) => {
        setFlightRoutes((prevArr) => {
            const result = [...prevArr];
            const route = result[routeIndex];
            result[routeIndex] = {
                id: route.id,
                passengers: route.passengers,
                class: route.class,
                baggage: baggage,
                return: route.return,
                exchange: route.exchange,
                origin: route.origin,
                direction: route.direction,
                departDate: route.departDate,
                returnDate: route.returnDate
            };
            return result;
        });
    }

    const setReturn = (ret: boolean, routeIndex: number) => {
        setFlightRoutes((prevArr) => {
            const result = [...prevArr];
            const route = result[routeIndex];
            result[routeIndex] = {
                id: route.id,
                passengers: route.passengers,
                class: route.class,
                baggage: route.baggage,
                return: ret,
                exchange: route.exchange,
                origin: route.origin,
                direction: route.direction,
                departDate: route.departDate,
                returnDate: route.returnDate
            };
            return result;
        });
    }

    const setExchange = (exchange: boolean, routeIndex: number) => {
        setFlightRoutes((prevArr) => {
            const result = [...prevArr];
            const route = result[routeIndex];
            result[routeIndex] = {
                id: route.id,
                passengers: route.passengers,
                class: route.class,
                baggage: route.baggage,
                return: route.return,
                exchange: exchange,
                origin: route.origin,
                direction: route.direction,
                departDate: route.departDate,
                returnDate: route.returnDate
            };
            return result;
        });
    }

    const setOrigin = (origin: string, routeIndex: number) => {
        setFlightRoutes((prevArr) => {
            const result = [...prevArr];
            const route = result[routeIndex];
            result[routeIndex] = {
                id: route.id,
                passengers: route.passengers,
                class: route.class,
                baggage: route.baggage,
                return: route.return,
                exchange: route.exchange,
                origin: origin,
                direction: route.direction,
                departDate: route.departDate,
                returnDate: route.returnDate
            };
            return result;
        });
    }

    const switchCities = (routeIndex: number) => {
        setFlightRoutes((prevArr) => {
            const result = [...prevArr];
            const route = result[routeIndex];
            result[routeIndex] = {
                id: route.id,
                passengers: route.passengers,
                class: route.class,
                baggage: route.baggage,
                return: route.return,
                exchange: route.exchange,
                origin: route.direction,
                direction: route.origin,
                departDate: route.departDate,
                returnDate: route.returnDate
            };
            return result;
        });
    }

    const setDirection = (direction: string, routeIndex: number) => {
        setFlightRoutes((prevArr) => {
            const result = [...prevArr];
            const route = result[routeIndex];
            result[routeIndex] = {
                id: route.id,
                passengers: route.passengers,
                class: route.class,
                baggage: route.baggage,
                return: route.return,
                exchange: route.exchange,
                origin: route.origin,
                direction: direction,
                departDate: route.departDate,
                returnDate: route.returnDate
            };
            return result;
        });
    }

    const setDepartDate = (date: string | undefined, routeIndex: number) => {
        setFlightRoutes((prevArr) => {
            const result = [...prevArr];
            const route = result[routeIndex];
            result[routeIndex] = {
                id: route.id,
                passengers: route.passengers,
                class: route.class,
                baggage: route.baggage,
                return: route.return,
                exchange: route.exchange,
                origin: route.origin,
                direction: route.direction,
                departDate: date,
                returnDate: (route.returnDate != undefined && date != undefined && new Date(route.returnDate) < new Date(date) ? undefined : route.returnDate)
            };
            return result;
        });
    }

    const setReturnDate = (date: string | undefined, routeIndex: number) => {
        setFlightRoutes((prevArr) => {
            const result = [...prevArr];
            const route = result[routeIndex];
            result[routeIndex] = {
                id: route.id,
                passengers: route.passengers,
                class: route.class,
                baggage: route.baggage,
                return: route.return,
                exchange: route.exchange,
                origin: route.origin,
                direction: route.direction,
                departDate: route.departDate,
                returnDate: date
            };
            return result;
        });
    }

    const addRoute = () => {
        setFlightRoutes(prev => [...prev, {
            id: Guid.newGuid(),
            passengers: 1,
            class: 'economy',
            baggage: false,
            return: false,
            exchange: false,
            origin: "",
            direction: "",
            departDate: undefined,
            returnDate: undefined
        }]);
    }

    const removeRoute = (routeIndex: number) => {
        setFlightRoutes(prev => [...prev.filter((x, i) => i != routeIndex)]);
    }


    const delay = (fn: Function, ms: number) => {
        clearTimeout(clearTimerRef.current);
        clearTimerRef.current = setTimeout(fn, ms) as any;
    }

    const originFocusHandler = async (routeIndex: number) => {
        setAutoComplete([]);
        const originText = flightRoutes[routeIndex].origin;
        if(Utils.isEmpty(originText)) {
            return;
        }
        //delay(async () => {
            const autoCompleteItems = await refsService.getCities(originText);
            if (autoCompleteItems != null && !Utils.isEmpty(autoCompleteItems)) {
                setAutoComplete(autoCompleteItems);
            } else {
                setAutoComplete([]);
            }
        //}, 500);
    }
    const originTextChangeHandler = async (text: string, routeIndex: number) => {
        //delay(async () => {
            if(Utils.isEmpty(text)) {
                setAutoComplete([]);
                return;
            }
            const autoCompleteItems = await refsService.getCities(text);
            if (autoCompleteItems != null && !Utils.isEmpty(autoCompleteItems)) {
                setAutoComplete(autoCompleteItems);
            } else {
                setAutoComplete([]);
            }
        //}, 500);
    }

    const directionFocusHandler = async (routeIndex: number) => {
        setAutoComplete([]);
        const directionText = flightRoutes[routeIndex].direction;
        if(Utils.isEmpty(directionText)) {
            return;
        }
        //delay(async () => {
            const autoCompleteItems = await refsService.getCities(directionText);
            if (autoCompleteItems != null && !Utils.isEmpty(autoCompleteItems)) {
                setAutoComplete(autoCompleteItems);
            } else {
                setAutoComplete([]);
            }
        //}, 500);
    }
    const directionTextChangeHandler = async (text: string, routeIndex: number) => {
        //delay(async () => {
            if(Utils.isEmpty(text)) {
                setAutoComplete([]);
                return;
            }
            const autoCompleteItems = await refsService.getCities(text);
            if(autoCompleteItems != null && !Utils.isEmpty(autoCompleteItems)) {
                setAutoComplete(autoCompleteItems);
            }
            else {
                setAutoComplete([]);
            }
        //}, 500);
    }


    const getProviders = async () => {
        //setProvidersReceivePending(true);
        //setIsProvidersNotFound(false);
        setProviders([]);
        const response = await tripService.getProviders(selectedCompany?.code ?? "");
        const providers = response ?? [];
        if (providers.length > 0) {
            setProviders(providers);
        } else {
            //setIsProvidersNotFound(true);
        }
        //setProvidersReceivePending(false);
    };

    const searchFlights = async () => {
        setSearchPending(true);
        setIsSearchNotFound(false);
        setSearchError(false);
        setFlight([]);

        if (searchFlightsCT) {
            searchFlightsCT.cancel();
        }

        let cts = axios.CancelToken.source();
        setSearchFlightsCT(cts);

        const response = await tripService.getFlightList2(
            flightRoutes.map(x => {
                if(x.returnDate != null) {
                    return [
                        {
                            id: x.id,
                            passengers: x.passengers,
                            class: x.class,
                            baggage: x.baggage,
                            return: x.return,
                            exchange: x.exchange,
                            origin: x.origin,
                            direction: x.direction,
                            departDate: x.departDate ?? "",
                            notes: ""
                        },
                        {
                            id: x.id,
                            passengers: x.passengers,
                            class: x.class,
                            baggage: x.baggage,
                            return: x.return,
                            exchange: x.exchange,
                            origin: x.direction,
                            direction: x.origin,
                            departDate: x.returnDate ?? "",
                            notes: ""
                        }
                    ]
                }
                else {
                    return {
                        id: x.id,
                        passengers: x.passengers,
                        class: x.class,
                        baggage: x.baggage,
                        return: x.return,
                        exchange: x.exchange,
                        origin: x.origin,
                        direction: x.direction,
                        departDate: x.departDate ?? "",
                        notes: ""
                    }
                }
            }).flat(),
            selectedProviders,
            selectedCompany?.code ?? "",
            cts.token
        );

        setSearchFlightsCT(undefined);

        setSearchResponse(response);

        const variants = response?.variants ?? [];

        if (variants.length > 0) {
            setFlight(variants.sort(function(a,b){
                return new Date(a.rejsInfo[0].dateDep).getTime() - new Date(b.rejsInfo[0].dateDep).getTime();
            }));
        } else {
            setIsSearchNotFound(true);
            setSearchError(true);
        }

        setSearchPending(false);
    };

    const cancelSearchFlights = () => {
        if (searchFlightsCT) {
            searchFlightsCT.cancel();
        }
    }


    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.repeat) return;
        if (event.key === "Enter") {
            searchFlights();
        }
    }


    const selectFlight = (flight: IAviaVariant, tariff: IAviaTariffInfo) => {
        selectFlightHandler(flight, tariff, searchResponse);
        setSearchPending(false);
        setIsSearchNotFound(false);
        setFlight([]);
    }


    useEffect(() => {
        getProviders();
    }, [selectedCompany]);


    useEffect(() => {
        const errorMessages = [];

        const wrongReturnDate = flightRoutes.find((x) => x.returnDate != null && x.departDate != null && new Date(x.returnDate).getTime() < new Date(x.departDate).getTime());
        if(wrongReturnDate != null) {
            errorMessages.push(t("errorMsg.inputDateError"));
        }

        setErrors(errorMessages);
    }, [flightRoutes]);


    return (
            <div className="search-flight-form">

                <MdScrollViewer
                    style={{
                        "root": {
                            flex: 1
                        }
                    }}
                >

                    <div className="search-flight-form-header"
                         style={{
                             marginBottom: "20px",
                             transform: (searchPending || flights.length > 0 || searchError ? "translateY(0)" : "translateY(0px)")
                         }}>

                        {/*<div className="search-flight-form-error-messages">*/}
                        {/*    <div className="search-flight-form-error-messages-title">
                        {/*    </div>*/}
                        {/*    <div className="search-flight-form-error-messages-message">*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="provider-list">
                            {
                                authState.isSupportUser && (
                                    providers.map((provider, providerIndex) => {
                                        const isSelected = selectedProviders.includes(provider);
                                        return (
                                            <React.Fragment key={"provider" + Guid.newGuid()}>
                                                <MdToggleButton
                                                    buttonStyle={{
                                                        minInlineSize: "fit-content",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "2px 5px 0 8px",
                                                        borderRadius: "4px",
                                                        minWidth: "40px",
                                                        minHeight: "24px",
                                                        maxHeight: "52px",
                                                        fontSize: "11px",
                                                        color: (isSelected ? "#74B100" : "#C1C1C1"),
                                                        border: (isSelected ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                    }}
                                                    inkStyle={{
                                                        backgroundColor: (isSelected ? "#74B100" : "#C1C1C1"),
                                                        opacity: 0.1
                                                    }}
                                                    checked={isSelected}
                                                    onClick={() => {
                                                        if (isSelected) {
                                                            setSelectedProviders(prev => [...prev.filter(x => x != provider)]);
                                                        } else {
                                                            setSelectedProviders(prev => [...prev, provider]);
                                                        }
                                                    }}
                                                >
                                                    {
                                                        provider
                                                    }
                                                </MdToggleButton>
                                            </React.Fragment>
                                        )
                                    })
                                )
                            }
                        </div>

                        <div className="flight-routes">
                            {
                                flightRoutes.map((route, routeIndex) => {
                                    return (
                                        <React.Fragment key={routeIndex}>
                                            <div className="flight-routes-item">

                                                <div className="flight-route-tools">
                                                    {
                                                        flightRoutes.length > 1 && (
                                                            <MdButton
                                                                icon={
                                                                    <RecycleBinIcon
                                                                        width="14px"
                                                                        height="14px"
                                                                        style={{
                                                                            "path": {
                                                                                fill: "#721c24"
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                style={{
                                                                    "button": {
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        borderWidth: 0,
                                                                        backgroundColor: "#FFFFFF",
                                                                        color: "rgb(0 0 0)",
                                                                        borderRadius: "6px",
                                                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                        marginRight: "0px",
                                                                        width: "28px",
                                                                        height: "28px",
                                                                        padding: 0
                                                                    },
                                                                    "ink": {
                                                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                    },
                                                                    "icon": {
                                                                        marginLeft: 0,
                                                                        marginRight: 0,
                                                                    }
                                                                }}
                                                                onClick={() => removeRoute(routeIndex)}
                                                            />
                                                        )
                                                    }
                                                </div>

                                                <div className="flight-route-text-field">
                                                    <div
                                                        className={("flight-route-text-field-label" + (route.origin.length > 0 ? " --displaced" : ""))}>
                                                        {t("commonText.from")}
                                                    </div>
                                                    <MdSelect
                                                        isEditable={true}
                                                        text={route.origin}
                                                        setText={(value) => setOrigin(value, routeIndex)}
                                                        onFocus={() => originFocusHandler(routeIndex)}
                                                        itemSource={autoComplete.filter(x => x.city.toLowerCase().includes(route.origin.toLowerCase()))}
                                                        displayMemberPath={"city"}
                                                        itemTemplate={(item: any) => {
                                                            return (
                                                                <div className="flight-route-city-item">
                                                                    <div className="flight-route-city-item-flag">
                                                                        <span className={("flag-icon flag-icon-" + item.countryId.toLowerCase())}></span>
                                                                    </div>
                                                                    <div className="flight-route-city-item-text">
                                                                        {item.city}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }}
                                                        onTextChange={(value) => originTextChangeHandler(value, routeIndex)}
                                                        disabled={searchPending}
                                                        style={{
                                                            "root": {
                                                                width: "170px",
                                                                margin: 0,
                                                                border: "none"
                                                            },
                                                            "text-field": {
                                                                padding: "6px 10px"
                                                            },
                                                            "toggle-button-icon": {
                                                                display: "none"
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className="flight-route-tools">
                                                    <MdButton
                                                        style={{
                                                            "button": {
                                                                transform: "rotate(-90deg)",
                                                                borderWidth: 0,
                                                                backgroundColor: "transparent",
                                                                color: "rgb(0 0 0)",
                                                                borderRadius: "6px",
                                                                height: "30px",
                                                                padding: "5px"
                                                            },
                                                            "icon": {
                                                                marginRight: "5px",
                                                            },
                                                            "ink": {
                                                                backgroundColor: "rgba(255,255,255,.5)"
                                                            }
                                                        }}
                                                        tabIndex={-1}
                                                        disabled={searchPending}
                                                        onClick={() => switchCities(routeIndex)}
                                                    >
                                                        <SwitchIcon
                                                            width="16px"
                                                            height="16px"
                                                        />
                                                    </MdButton>
                                                </div>

                                                <div className="flight-route-text-field">
                                                    <div
                                                        className={("flight-route-text-field-label" + (route.direction.length > 0 ? " --displaced" : ""))}>
                                                        {t("commonText.to")}
                                                    </div>
                                                    <MdSelect
                                                        isEditable={true}
                                                        text={route.direction}
                                                        setText={(value) => setDirection(value, routeIndex)}
                                                        onFocus={() => directionFocusHandler(routeIndex)}
                                                        itemSource={autoComplete.filter(x => x.city.toLowerCase().includes(route.direction.toLowerCase()))}
                                                        displayMemberPath={"city"}
                                                        itemTemplate={(item: any) => {
                                                            return (
                                                                <div className="flight-route-city-item">
                                                                    <div className="flight-route-city-item-flag">
                                                                        <span className={("flag-icon flag-icon-" + item.countryId.toLowerCase())}></span>
                                                                    </div>
                                                                    <div className="flight-route-city-item-text">
                                                                        {item.city}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }}
                                                        onTextChange={(value) => directionTextChangeHandler(value, routeIndex)}
                                                        disabled={searchPending}
                                                        style={{
                                                            "root": {
                                                                width: "170px",
                                                                margin: 0,
                                                                border: "none"
                                                            },
                                                            "text-field": {
                                                                padding: "6px 10px"
                                                            },
                                                            "toggle-button-icon": {
                                                                display: "none"
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div
                                                    className={
                                                        ("flight-route-date-field")
                                                        + (route.returnDate != null && route.departDate != null && new Date(route.departDate).getTime() > new Date(route.returnDate).getTime() ? " --invalidated" : "")
                                                    }>
                                                    <div
                                                        className={("flight-route-date-field-label" + (route.departDate != null ? " --displaced" : ""))}>
                                                        {t("bookingPage.departDate")}
                                                    </div>
                                                    <input
                                                        className={
                                                            (
                                                                "flight-route-date-field-input"
                                                                + (route.departDate == null ? " --hidden" : "")
                                                            )
                                                        }
                                                        type="datetime-local"
                                                        min={dateService.dateToStringForDatePicker(new Date()) + "T00:00:00"}
                                                        value={route.departDate ?? ""}
                                                        onKeyDown={onKeyDown}
                                                        //onFocus={(e) => (e.target as any).showPicker()}
                                                        onChange={(e) => {
                                                            setDepartDate(e.target.value, routeIndex);
                                                        }}
                                                        disabled={searchPending}
                                                        style={{
                                                            width: "135px"
                                                        }}
                                                    />
                                                    {
                                                        route.departDate != null && (
                                                            <MdButton
                                                                icon={
                                                                    <CrossIcon
                                                                        width="14px"
                                                                        height="14px"
                                                                        style={{
                                                                            "path": {
                                                                                fill: "#000000"
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                style={{
                                                                    "button": {
                                                                        position: "absolute",
                                                                        top: "50%",
                                                                        right: "10px",
                                                                        transform: "translate(0, -50%)",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        borderWidth: 0,
                                                                        backgroundColor: "#FFFFFF",
                                                                        color: "rgb(0 0 0)",
                                                                        borderRadius: "6px",
                                                                        boxShadow: "none",
                                                                        marginRight: "0px",
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        padding: 0
                                                                    },
                                                                    "ink": {
                                                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                    },
                                                                    "icon": {
                                                                        marginLeft: 0,
                                                                        marginRight: 0,
                                                                    }
                                                                }}
                                                                disabled={searchPending}
                                                                onClick={() => setDepartDate(undefined, routeIndex)}
                                                            />
                                                        )
                                                    }
                                                </div>

                                                <div className={
                                                    ("flight-route-date-field")
                                                    + (route.returnDate != null && route.departDate != null && new Date(route.returnDate).getTime() < new Date(route.departDate).getTime() ? " --invalidated" : "")
                                                }>
                                                    <div
                                                        className={("flight-route-date-field-label" + (route.returnDate != null ? " --displaced" : ""))}>
                                                        {t("bookingPage.returnDate")}
                                                    </div>
                                                    <input
                                                        className={("flight-route-date-field-input" + (route.returnDate == null ? " --hidden" : ""))}
                                                        type="datetime-local"
                                                        min={(route.departDate != null ? route.departDate : dateService.dateToStringForDatePicker(new Date()) + "T00:00:00")}
                                                        value={route.returnDate ?? ""}
                                                        onKeyDown={onKeyDown}
                                                        //onFocus={(e) => (e.target as any).showPicker()}
                                                        onChange={(e) => {
                                                            setReturnDate(e.target.value, routeIndex);
                                                        }}
                                                        disabled={searchPending}
                                                        style={{
                                                            width: "135px"
                                                        }}
                                                    />
                                                    {
                                                        route.returnDate != null && (
                                                            <MdButton
                                                                icon={
                                                                    <CrossIcon
                                                                        width="14px"
                                                                        height="14px"
                                                                        style={{
                                                                            "path": {
                                                                                fill: "#000000"
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                style={{
                                                                    "button": {
                                                                        position: "absolute",
                                                                        top: "50%",
                                                                        right: "10px",
                                                                        transform: "translate(0, -50%)",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        borderWidth: 0,
                                                                        backgroundColor: "#FFFFFF",
                                                                        color: "rgb(0 0 0)",
                                                                        borderRadius: "6px",
                                                                        boxShadow: "none",
                                                                        marginRight: "0px",
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        padding: 0
                                                                    },
                                                                    "ink": {
                                                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                    },
                                                                    "icon": {
                                                                        marginLeft: 0,
                                                                        marginRight: 0,
                                                                    }
                                                                }}
                                                                disabled={searchPending}
                                                                onClick={() => setReturnDate(undefined, routeIndex)}
                                                            />
                                                        )
                                                    }
                                                </div>

                                                <div>
                                                    <MdMenu
                                                        behaviour={'none'}
                                                        header={(
                                                            <div className="flight-route-notes">
                                                                <div
                                                                    className="flight-route-note">{t("bookingPage.passengers")}: {flightRoutes[routeIndex].passengers}</div>
                                                                <div className="flight-route-note">
                                                                    {
                                                                        flightRoutes[routeIndex].class === 'economy'
                                                                            ?t("bookingPage.economyClass")
                                                                            : t("bookingPage.businessClass")
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                        disabled={searchPending}
                                                        style={{
                                                            "root": {
                                                                border: "1px solid rgb(222, 226, 230)",
                                                                borderRadius: "4px"
                                                            }
                                                        }}
                                                    >
                                                        <div className="flight-route-notes-controls">

                                                            <div className="flight-class-control">
                                                                <MdToggleButton
                                                                    buttonStyle={{
                                                                        flex: 1,
                                                                        minInlineSize: "fit-content",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "1px 10px 0 10px",
                                                                        borderRadius: "4px",
                                                                        width: "50px",
                                                                        minHeight: "24px",
                                                                        maxHeight: "52px",
                                                                        fontSize: "11px",
                                                                        color: (flightRoutes[routeIndex].class == 'economy' ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                                        border: (flightRoutes[routeIndex].class == 'economy' ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                                    }}
                                                                    inkStyle={{
                                                                        backgroundColor: (flightRoutes[routeIndex].class == 'economy' ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                                        opacity: 0.1
                                                                    }}
                                                                    checked={flightRoutes[routeIndex].class == 'economy'}
                                                                    onClick={() => {
                                                                        if (flightRoutes[routeIndex].class == 'economy') {
                                                                            setClass('business', routeIndex);
                                                                        } else {
                                                                            setClass('economy', routeIndex);
                                                                        }
                                                                    }}
                                                                >
                                                                    {t("bookingPage.economy")}
                                                                </MdToggleButton>
                                                                <MdToggleButton
                                                                    buttonStyle={{
                                                                        flex: 1,
                                                                        minInlineSize: "fit-content",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "1px 10px 0 10px",
                                                                        borderRadius: "4px",
                                                                        width: "50px",
                                                                        minHeight: "24px",
                                                                        maxHeight: "52px",
                                                                        fontSize: "11px",
                                                                        color: (flightRoutes[routeIndex].class == 'business' ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                                        border: (flightRoutes[routeIndex].class == 'business' ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                                    }}
                                                                    inkStyle={{
                                                                        backgroundColor: (flightRoutes[routeIndex].class == 'business' ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                                        opacity: 0.1
                                                                    }}
                                                                    checked={flightRoutes[routeIndex].class == 'business'}
                                                                    onClick={() => {
                                                                        if (flightRoutes[routeIndex].class == 'business') {
                                                                            setClass('economy', routeIndex);
                                                                        } else {
                                                                            setClass('business', routeIndex);
                                                                        }
                                                                    }}
                                                                >
                                                                    {t("bookingPage.business")}
                                                                </MdToggleButton>
                                                            </div>

                                                            <div className="flight-route-control">
                                                            <span
                                                                className="flight-route-control-label">{t("bookingPage.passengers")}:</span>
                                                                <MdButton
                                                                    icon={
                                                                        <MinusIcon
                                                                            width="12px"
                                                                            height="12px"
                                                                            style={{
                                                                                "path": {
                                                                                    stroke: "#878686"
                                                                                }
                                                                            }}
                                                                        />
                                                                    }
                                                                    style={{
                                                                        "button": {
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            borderWidth: 0,
                                                                            backgroundColor: "#FFFFFF",
                                                                            color: "rgb(0 0 0)",
                                                                            borderRadius: "6px",
                                                                            boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                            marginRight: "0px",
                                                                            width: "22px",
                                                                            height: "22px",
                                                                            padding: 0
                                                                        },
                                                                        "ink": {
                                                                            backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                        },
                                                                    }}
                                                                    onClick={() => {
                                                                        if (flightRoutes[routeIndex].passengers > 1) {
                                                                            setPassengers(flightRoutes[routeIndex].passengers - 1, routeIndex);
                                                                        }
                                                                    }}
                                                                />

                                                                <input
                                                                    type="number"
                                                                    min="1"
                                                                    max="6"
                                                                    value={flightRoutes[routeIndex].passengers}
                                                                    readOnly={true}
                                                                />

                                                                <MdButton
                                                                    icon={
                                                                        <PlusIcon
                                                                            width="12px"
                                                                            height="12px"
                                                                            style={{
                                                                                "path": {
                                                                                    stroke: "#878686"
                                                                                }
                                                                            }}
                                                                        />
                                                                    }
                                                                    style={{
                                                                        "button": {
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            borderWidth: 0,
                                                                            backgroundColor: "#FFFFFF",
                                                                            color: "rgb(0 0 0)",
                                                                            borderRadius: "6px",
                                                                            boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                            marginRight: "0px",
                                                                            width: "22px",
                                                                            height: "22px",
                                                                            padding: 0
                                                                        },
                                                                        "ink": {
                                                                            backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                        },
                                                                    }}
                                                                    onClick={() => {
                                                                        if (flightRoutes[routeIndex].passengers < 6) {
                                                                            setPassengers(flightRoutes[routeIndex].passengers + 1, routeIndex);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="flight-route-control">
                                                            <span
                                                                className="flight-route-control-label">{t("orderPage.baggage")}: </span>
                                                                <MdToggleButton
                                                                    buttonStyle={{
                                                                        flex: 1,
                                                                        minInlineSize: "fit-content",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "1px 10px 0 10px",
                                                                        borderRadius: "4px",
                                                                        width: "50px",
                                                                        minHeight: "24px",
                                                                        maxHeight: "52px",
                                                                        fontSize: "11px",
                                                                        color: (flightRoutes[routeIndex].baggage ? "#74B100" : "#C1C1C1"),
                                                                        border: (flightRoutes[routeIndex].baggage ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                                    }}
                                                                    inkStyle={{
                                                                        backgroundColor: (flightRoutes[routeIndex].baggage ? "#74B100" : "#C1C1C1"),
                                                                        opacity: 0.1
                                                                    }}
                                                                    checked={flightRoutes[routeIndex].baggage}
                                                                    onClick={() => {
                                                                        if (flightRoutes[routeIndex].baggage) {
                                                                            setBaggage(false, routeIndex);
                                                                        } else {
                                                                            setBaggage(true, routeIndex);
                                                                        }
                                                                    }}
                                                                >
                                                                    {
                                                                        (flightRoutes[routeIndex].baggage ?t("commonText.yes") : t("commonText.no"))
                                                                    }
                                                                </MdToggleButton>
                                                            </div>

                                                            <div className="flight-route-control">
                                                            <span
                                                                className="flight-route-control-label">{t("orderPage.refund")}: </span>
                                                                <MdToggleButton
                                                                    buttonStyle={{
                                                                        flex: 1,
                                                                        minInlineSize: "fit-content",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "1px 10px 0 10px",
                                                                        borderRadius: "4px",
                                                                        width: "50px",
                                                                        minHeight: "24px",
                                                                        maxHeight: "52px",
                                                                        fontSize: "11px",
                                                                        color: (flightRoutes[routeIndex].return ? "#74B100" : "#C1C1C1"),
                                                                        border: (flightRoutes[routeIndex].return ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                                    }}
                                                                    inkStyle={{
                                                                        backgroundColor: (flightRoutes[routeIndex].return ? "#74B100" : "#C1C1C1"),
                                                                        opacity: 0.1
                                                                    }}
                                                                    checked={flightRoutes[routeIndex].return}
                                                                    onClick={() => {
                                                                        if (flightRoutes[routeIndex].return) {
                                                                            setReturn(false, routeIndex);
                                                                        } else {
                                                                            setReturn(true, routeIndex);
                                                                        }
                                                                    }}
                                                                >
                                                                    {
                                                                        (flightRoutes[routeIndex].return ?t("commonText.yes") : t("commonText.no"))
                                                                    }
                                                                </MdToggleButton>
                                                            </div>

                                                            <div className="flight-route-control">
                                                            <span
                                                                className="flight-route-control-label">{t("orderPage.exchange")}: </span>
                                                                <MdToggleButton
                                                                    buttonStyle={{
                                                                        flex: 1,
                                                                        minInlineSize: "fit-content",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "1px 10px 0 10px",
                                                                        borderRadius: "4px",
                                                                        width: "50px",
                                                                        minHeight: "24px",
                                                                        maxHeight: "52px",
                                                                        fontSize: "11px",
                                                                        color: (flightRoutes[routeIndex].exchange ? "#74B100" : "#C1C1C1"),
                                                                        border: (flightRoutes[routeIndex].exchange ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                                    }}
                                                                    inkStyle={{
                                                                        backgroundColor: (flightRoutes[routeIndex].exchange ? "#74B100" : "#C1C1C1"),
                                                                        opacity: 0.1
                                                                    }}
                                                                    checked={flightRoutes[routeIndex].exchange}
                                                                    onClick={() => {
                                                                        if (flightRoutes[routeIndex].exchange) {
                                                                            setExchange(false, routeIndex);
                                                                        } else {
                                                                            setExchange(true, routeIndex);
                                                                        }
                                                                    }}
                                                                >
                                                                    {
                                                                        (flightRoutes[routeIndex].exchange ? t("commonText.yes") : t("commonText.no"))
                                                                    }
                                                                </MdToggleButton>
                                                            </div>

                                                        </div>
                                                    </MdMenu>
                                                </div>

                                                {/*<div className="flight-route-tools">*/}

                                                {/*</div>*/}

                                            </div>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>

                        <div className="search-flight-form-controls">
                            <div className="search-flight-form-controls-section">
                                {/*<MdButton*/}
                                {/*    type="submit"*/}
                                {/*    style={{*/}
                                {/*        "button": {*/}
                                {/*            marginLeft: "40px",*/}
                                {/*            borderWidth: 0,*/}
                                {/*            backgroundColor: "rgb(253 253 253)",*/}
                                {/*            color: "rgb(175 175 175)",*/}
                                {/*            border: "1px solid rgb(175 175 175)",*/}
                                {/*            borderStyle: "dashed",*/}
                                {/*            borderRadius: "6px",*/}
                                {/*            minWidth: "95px",*/}
                                {/*            height: "30px",*/}
                                {/*            padding: "5px 15px"*/}
                                {/*        },*/}
                                {/*        "ink": {*/}
                                {/*            backgroundColor: "rgba(255,255,255,.5)"*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    disabled={searchPending}*/}
                                {/*    onClick={() => addRoute()}*/}
                                {/*>*/}
                                {/*     {t("commonText.add")}*/}
                                {/*</MdButton>*/}
                            </div>

                            <div className="search-flight-form-controls-section">

                                <MdButton
                                    type="button"
                                    style={{
                                        "button": {
                                            borderWidth: 0,
                                            backgroundColor: (searchPending ? "#B83E50" : "#3E5CB8"),
                                            color: "#FFFFFF",
                                            borderRadius: "6px",
                                            minWidth: "95px",
                                            height: "30px",
                                            padding: "5px 15px",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        },
                                        "text": {
                                            width: "auto",
                                            flex: 0,
                                            color: "#FFFFFF"
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    disabled={flightRoutes.some(x => x.departDate == null)}
                                    onClick={() => {
                                        if (!searchPending) {
                                            searchFlights()
                                        } else {
                                            cancelSearchFlights();
                                        }
                                    }}
                                >
                                    {
                                        searchPending
                                            ? (
                                                t("bookingPage.stop")
                                            )
                                            : (
                                                t("bookingPage.search")
                                            )
                                    }
                                </MdButton>
                            </div>
                        </div>

                    </div>

                    {
                        flights.length > 0 && (
                            <div className="flights-list"
                                 style={{
                                     maxHeight: "inherit",
                                     overflow: "hidden",
                                     paddingBottom: "10px"
                                 }}>
                                {
                                    flights.map((flight, flightIndex) =>
                                        <div key={"flight" + Guid.newGuid()}>
                                            <FlightComponent
                                                flightInfo={{id: Guid.newGuid(), flight}}
                                                selectFlight={selectFlight}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }

                    {
                        searchPending && (
                            <div className="loading-results-message">
                                <CircleSpinnerIcon
                                    width="20px"
                                    height="20px"
                                    style={{
                                        "circle": {
                                            stroke: "#A0A1A6"
                                        }
                                    }}
                                />
                                {t("commonText.searching")}
                            </div>
                        )
                    }

                    {
                        searchError && (
                            <div className="no-results-message">
                                {t("bookingPage.failTicketSearch")}
                            </div>
                        )
                    }

                </MdScrollViewer>

            </div>
    );
}