import axios from "axios";
import {axiosContext, axiosInstance} from "./AxiosInstance";
import {
    BEARER,
    ROUTE_LOGIN,
    ROUTE_REFRESH_TOKEN,
    ROUTE_REVOKE_TOKEN,
    ROUTE_LOGOUT,
    ROUTE_RECOVERY_PASSWORD,
    ROUTE_CHANGE_PASSWORD,
    ROUTE_GET_USER_ACTIVE_STATE,

    ROUTE_GET_USER_ROLES, // TODO: move to another api?
} from "../constants/routeConstants/ApiRouteConstants";
import {ILoginRequest} from "../Requests/ILoginRequest";
import {IChangePasswordRequest} from "../Requests/IChangePasswordRequest";
import {IGetUserRolesRequest} from "../Requests/IGetUserRolesRequest";
import {IRefreshTokenRequest} from "../Requests/IRefreshTokenRequest";
import {ICompanyUserRoles} from "../interfaces/requestsInterfaces/reportRequests";
import {ILoginResponse} from "../responces/ILoginResponse";
import {ITokenResponse} from "../responces/ITokenResponse";
import {ISupportUser} from "../interfaces/support/ISupportUser";


export function AuthApi() {

    /**
     * Post method for login
     * @param {ILoginRequest} data - user email address
     * @return {Promise<ILoginResponse|null>} response data in case of successful login. In case of errors returns null.
     */
    async function login(data: ILoginRequest): Promise<ILoginResponse | null> {
        try {
            const response = await axiosInstance.post<ILoginResponse>(
                process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_LOGIN,
                data
            );
            return response.data;
        } catch (ex: unknown) {
        }
        return null;
    }

    /**
     * Put method for password recovery
     * @param {string} email - user email address
     * @return {Promise<boolean|null>} true if password recovery was successful, false otherwise. In case of errors returns null
     */
    async function recoveryPassword(email: string): Promise<boolean | null> {
        try {
            const response = await axios.put(
                process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_RECOVERY_PASSWORD,
                email,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (ex: unknown) {
        }
        return null;
    }

    /**
     * Put method for change password
     * @param {IChangePasswordRequest} data - data for changing password
     * @return {Promise<boolean|null>} true if password was changed successful, false otherwise. In case of errors returns null
     */
    async function changePassword(data: IChangePasswordRequest): Promise<boolean | null> {
        try {
            const response = await axiosInstance.put(
                process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_CHANGE_PASSWORD,
                data
            );
            return response.data;
        } catch (ex: unknown) {
        }
        return null;
    }

    /**
     * Post method for token refresh
     * @param {IRefreshTokenRequest} data - data for token refresh
     * @return {Promise<boolean|null>} response data in case of successful refreshing. In case of errors returns null.
     */
    async function refreshToken(data: IRefreshTokenRequest): Promise<ITokenResponse | null> {
        try {
            const response = await axiosInstance.post<ITokenResponse>(
                process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_REFRESH_TOKEN,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (ex: unknown) {
            return null;
        }
    }

    /**
     * Post method for revoke token
     * @param {string} token - access token
     * @return {Promise<boolean>} True if token was revoked successful. In case of errors returns false
     */
    async function revokeToken(token: string): Promise<boolean> {
        try {
            const response = await axiosInstance.post<any>(
                process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_REVOKE_TOKEN,
                null,
                {
                    headers: {
                        Authorization: BEARER + token, // TODO: check if necessary?
                    }
                }
            );
            if (response.status === 200) {
                return true;
            }
        } catch (ex: unknown) {
        }
        return false;
    }

    /**
     * Post method for logout
     * @param {string} token - access token
     */
    async function logout(token: string): Promise<void> {
        try {
            await axiosInstance.post<any>(
                process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_LOGOUT,
                null,
                {
                    headers: {
                        Authorization: BEARER + token,
                    }
                }
            );
        } catch (ex: unknown) {
        }
    }

    /**
     * Get method for obtaining user activity status
     * @param {string} email - user email address
     * @param {string} token - access token
     * @return {Promise<boolean|null>} true if user is active, false if not. In case of errors returns null.
     */
    const isUserActive = async (email: string, token: string): Promise<boolean | null> => {
        try {
            const result = await axiosInstance.get<boolean | null>(
                process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_USER_ACTIVE_STATE,
                {
                    params: {
                        email,
                        token
                    }
                }
            );
            return result.data;
        } catch (error) {
        }
        return null;
    };

    const getUserRoles = async (request: IGetUserRolesRequest) => {
        const result = await axiosInstance.get<ICompanyUserRoles[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_USER_ROLES,
            {
                headers: {
                    Authorization: BEARER + request.token,
                    Auth: 123
                },
                params: {
                    email: request.email,
                },
            }
        );
        return result.data;
    };



    return {
        login,
        recoveryPassword,
        changePassword,
        refreshToken,
        revokeToken,
        logout,
        isUserActive,
        getUserRoles
    };
}
