import axios, {CancelToken} from "axios";
import {
    ROUTE_GET_FLIGHTS,
    ROUTE_GET_PAXES,
    ROUTE_GET_TRAINS,
    ROUTE_BOOK_FLIGHTS,
    ROUTE_BOOK_TRAINS,
    ROUTE_GET_PROVIDERS,
    ROUTE_ISSUE_ORDER,
    ROUTE_GET_ORDERS,
    ROUTE_GET_ORDER_BY_ID,
    ROUTE_GET_ORDER_BOOKING_DETAILS,
    ROUTE_GET_ORDER_ISSUE_DETAILS, ROUTE_IS_ORDER_ISSUED,
} from "../constants/routeConstants/ApiRouteConstants";
import {FlightVariant} from "../interfaces/orderInterfaces/AviaOrderInterfaces";
import {IBookFlightRequestNew, IBookTrainRequestNew} from "../Requests/IBookFlightRequest";
import {IPassenger} from "../interfaces/IPassenger";
import {IOrder, IOrderBookingDetails, IOrderIssueDetails} from "../interfaces/IOrderInfo";
import {IBookFlightResponse, IBookTrainResponse} from "../responces/IBookFlightResponse";
import {ISearchFlightsResponse} from "../responces/ISearchFlightsResponse";
import {ISearchTrainsResponse} from "../responces/ISearchTrainsResponse";
import {useAppSelector} from "../redux/hooks";
import {IFlightRoute} from "../pages/cabinetPages/tripPage/multiSearchTicketsModal/multiSearchFlightsComponent";

export function TripApi() {

    const authState = useAppSelector(state => state.auth);


    const getProviders = async (clientCode: string) => {
        const result = await axios.get<Array<string>>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_PROVIDERS,
            {
                params: {
                    clientCode: clientCode,
                }
            }
        );
        return result;
    };

    const getFlights = async (origin: string, direction: string, date: string, clientCode: string | null, connectionId: string | null) => {
        const result = await axios.get<ISearchFlightsResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_FLIGHTS,
            {
                params: {
                    fromCity: origin,
                    toCity: direction,
                    departDate: date,
                    clientCode: clientCode,
                    //connectionId
                }
            }
        );
        return result;
    };

    const getFlights2 = async (routes: IFlightRoute[], providers: string[], clientCore: string | null, ct: CancelToken = axios.CancelToken.source().token) => {
        console.warn(routes)
        const result = await axios.post<ISearchFlightsResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_FLIGHTS,
            {
                parameters: routes.map(x => {
                    return {
                        paxCount: x.passengers,
                        rtGroupId: x.id,
                        departDate: x.departDate,
                        fromCity: x.origin,
                        toCity: x.direction,
                        isBaggageRequired: x.baggage,
                        hasReturn: x.return,
                        hasExchange: x.exchange,
                        isBusynessOnly: x.class == 'business',
                        notes: null
                    }
                }),
                providers: providers,
                clientCode: clientCore
            }, {
                cancelToken: ct
            }
        );
        return result;
    };


    const getTrains = async (origin: string, direction: string, date: string, clientCode: string | null, connectionId: string | null) => {
        const result = await axios.get<ISearchTrainsResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_TRAINS,
            {
                params: {
                    fromCity: origin,
                    toCity: direction,
                    departDate: date,
                    clientCode: clientCode,
                    //connectionId
                }
            }
        );
        return result;
    };

    const getTrains2 = async (routes: { id: string, origin: string, direction: string, date: string, notes?: string }[], clientCore: string | null, ct: CancelToken = axios.CancelToken.source().token) => {
        const result = await axios.post<ISearchTrainsResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_TRAINS,
            {
                parameters: routes.map(x => {
                    return {
                        rtGroupId: x.id,
                        departDate: x.date,
                        fromCity: x.origin,
                        toCity: x.direction,
                        notes: x.notes
                    }
                }),
                clientCode: clientCore
            }, {
                cancelToken: ct
            }
        );
        return result;
    };


    const getPaxes = async (clientCode: string) => {
        const result = await axios.get<Array<IPassenger>>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_PAXES,
            {
                params: {
                    clientCode: clientCode,
                }
            }
        );
        return result;
    };


    const postFlightsBook = async (request: IBookFlightRequestNew) => {
        const result = await axios.post<IBookFlightResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_BOOK_FLIGHTS,
            request,
            { },
        );
        return result;
    };

    const postTrainsBook = async (request: IBookTrainRequestNew) => {
        const result = await axios.post<IBookTrainResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_BOOK_TRAINS,
            request,
            { },
        );
        return result;
    };

    const issueOrder = async (orderId: string, pnrs: string[]) => {
        const result = await axios.post<any>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ISSUE_ORDER,
            pnrs,
            {
                headers: {
                    Auth: 123,
                    Authorization: authState.accessToken
                },
                params: {
                    orderId: orderId,
                }
            }
        );
        return result;
    };



    // TODO: move to Order API and Order Service
    const getOrders = async (clientCode: string) => {
        const result = await axios.get<Array<IOrder>>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDERS,
            {
                headers: {
                    Auth: 123,
                    Authorization: authState.accessToken
                },
                params: {
                    code: clientCode,
                }
            }
        );
        return result;
    };

    const getOrder = async (orderNo: string) => {
        const result = await axios.get<IOrder>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDER_BY_ID,
            {
                headers: {
                    Auth: 123,
                    Authorization: authState.accessToken
                },
                params: {
                    orderNo: orderNo,
                }
            }
        );
        return result;
    };

    const isOrderIssued = async (clientCode: string, orderNo: string) => {
        const result = await axios.get<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_IS_ORDER_ISSUED,
            {
                headers: {
                    Auth: 123,
                    Authorization: authState.accessToken
                },
                params: {
                    clientCode: clientCode,
                    orderNo: orderNo,
                }
            }
        );
        return result;
    };

    const getOrderBookingDetails = async (orderNo: string) => {
        const result = await axios.get<IOrderBookingDetails[]>( // TODO: create response model?
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDER_BOOKING_DETAILS,
            {
                headers: {
                    Auth: 123,
                    Authorization: authState.accessToken
                },
                params: {
                    orderNo: orderNo,
                }
            }
        );
        return result;
    };

    const getOrderIssueDetails = async (clientCode: string, orderNo: string) => {
        const result = await axios.get<IOrderIssueDetails>( // TODO: create response model?
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDER_ISSUE_DETAILS,
            {
                headers: {
                    Auth: 123,
                    Authorization: authState.accessToken
                },
                params: {
                    clientCode: clientCode,
                    orderNo: orderNo,
                }
            }
        );
        return result;
    };



    return {
        getProviders,
        getFlights,
        getFlights2,
        getTrains,
        getTrains2,
        getPaxes,
        postFlightsBook,
        issueOrder,
        postTrainsBook,
        getOrders,
        getOrder,
        isOrderIssued,
        getOrderBookingDetails,
        getOrderIssueDetails
    };
}